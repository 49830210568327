import { StatisticType } from "../model/enum/StatisticType";
import { getAxios } from "./axios";
import { ProjectType } from "./project";

export type LeaderboardStatistics = { [key in StatisticType]: string };

export interface DashboardWeekReportProject {
  id: string;
  name: string;
  projectType: ProjectType;
}

export interface DashboardWeekReportItem {
  id: string;
  weekReportId: string | null;
  name: string;
  projectHours: { [key: string]: number };
  travelExpenses: number;
  submitted: boolean;
  approved: boolean;
}

export interface DashboardWeekReports {
  allReportsSubmitted: boolean;
  allReportsApproved: boolean;
  projects: DashboardWeekReportProject[];
  items: DashboardWeekReportItem[];
}

export interface DashboardTimesheetsBlockData {
  customerCount: number;
  projectCount: number;
  userCount: number;

  submittedReportCount: number;

  statisticsLeaderboard: LeaderboardStatistics;
}

export interface DashboardDeclarationsBlockData {
  pendingDeclarationCount: number;
  totalExpenses: number;
}

export default class AdminDashboardAPI {
  /**
   * Get the week reports information for the dashboard
   * @param year The year to get the information for
   * @param week The week to get the information for
   * @param showLate Whether or not to show late submissions
   */
  async getWeekReportsTable(
    year: number,
    week: number
  ): Promise<DashboardWeekReports> {
    const response = await getAxios().get<DashboardWeekReports>(
      `/dashboard/admin/weekreports/${year}/${week}`
    );
    return response.data;
  }

  async getTimesheetsBlockData(
    year: number,
    week: number
  ): Promise<DashboardTimesheetsBlockData> {
    const response = await getAxios().get<DashboardTimesheetsBlockData>(
      `/dashboard/admin/timesheets/${year}/${week}`
    );
    return response.data;
  }

  async getDeclarationsBlockData(
    year: number,
    week: number
  ): Promise<DashboardDeclarationsBlockData> {
    const response = await getAxios().get<DashboardDeclarationsBlockData>(
      `/dashboard/admin/declarations/${year}/${week}`
    );
    return response.data;
  }
}
