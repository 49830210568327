import React from "react";
import { Redirect, Route } from "react-router-dom";
import { Container, createStyles, makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";

import { AdminRoutes } from "./page/AdminRoutes";
import { EmployeeRoutes } from "./page/EmployeeRoutes";
import WithAppBar from "./WithAppBar";
import { SnackbarProvider } from "./component/SnackbarProvider";
import { Account } from "./data/principal";
import { RootState } from "./redux/reducers";

const useStyles = makeStyles(() =>
  createStyles({
    appContainer: {
      marginBottom: "100px",
    },
  })
);

export default function App() {
  // TODO: manage alle alerts via Redux en gebruik deze callback om bestaande alerts weg te gooien on page change
  //const dispatch = useDispatch();
  //History.listen((location, action) => {
  // clear alert on location change
  //dispatch(AlertActions.clear());
  //});

  const classes = useStyles();

  // Note: Only import the necessary Redux values, too many of these will cause an infinite loop
  // of attempting to re-render the component
  const isLoggedIn = useSelector<RootState, boolean>(
    (state) => state.auth.isLoggedIn
  );
  const currentAccount = useSelector<RootState, Account | null>(
    (state) => state.auth.currentAccount
  );

  if (isLoggedIn && currentAccount !== null) {
    return (
      <React.Fragment>
        <WithAppBar />
        <Container maxWidth={false} className={classes.appContainer}>
          <SnackbarProvider>
            <div>
              <AdminRoutes {...currentAccount} />
              <EmployeeRoutes {...currentAccount} />
              <Route path="/" exact>
                <Redirect
                  to={
                    currentAccount?.isEmployee
                      ? currentAccount?.addressId
                        ? "/employee"
                        : "/employee/profile"
                      : "/admin"
                  }
                />
              </Route>
            </div>
          </SnackbarProvider>
        </Container>
      </React.Fragment>
    );
  } else {
    return <Redirect to="/authorize" />;
  }
}
