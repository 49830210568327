import React, { useState } from "react";
import {
  IconButton,
  InputAdornment,
  OutlinedInputProps,
  TextField,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";

interface TextInputProps {
  id: string;
  label: string;
  value: string;
  onChange?: (value: string) => void;
  onBlur?: (
    event?: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  error?: string;
  prefix?: string;
  className?: string;
  type?: string;
}

export const TextInput = (props: TextInputProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  let inputProps: Partial<OutlinedInputProps> = {};

  if (props.prefix) {
    inputProps.startAdornment = (
      <InputAdornment position={`start`}>{props.prefix}</InputAdornment>
    );
  }

  if (props.type === "password") {
    inputProps = {
      autoComplete: 'new-password',
      // form: {
      //   autocomplete: 'off',
      // },
    };

    inputProps.endAdornment = (
      <InputAdornment position="end">
        <IconButton
          aria-label="toggle password visibility"
          onClick={handleClickShowPassword}
          onMouseDown={handleMouseDownPassword}
        >
          {showPassword ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      </InputAdornment>
    );
  }

  let type = props.type;

  if (showPassword && props.type === "password") {
    type = "text";
  }

  return (
    <TextField
      name='password'
      autoComplete='off'
      id={props.id}
      label={props.label}
      variant="outlined"
      fullWidth
      type={type}
      value={props.value}
      onBlur={props.onBlur}
      error={!!props.error}
      helperText={props.error}
      disabled={!props.onChange}
      onChange={(event) =>
        props.onChange && props.onChange(event.target.value as string)
      }
      InputProps={Object.keys(inputProps).length ? inputProps : undefined}
      className={props.className}
    />
  );
};
