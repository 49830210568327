import { getWeek, format, addDays, getISOWeeksInYear } from "date-fns";
import { nl as locale } from "date-fns/locale";
import { useEffect, useState } from "react";
import moment from "moment";

export interface YearAndWeek {
  year: number;
  weekNumber: number;
}

export const getWeekForDate = (date: Date): number => getWeek(date, { locale });

export const formatDate = (date: Date | null): string => {
  return date ? format(date, "dd-MM-yyyy", { locale }) : "";
};

export const useCurrentYearAndWeek = (): YearAndWeek | null => {
  const [year, updateYear] = useState<number | null>(null);
  const [weekNumber, updateWeekNumber] = useState<number | null>(null);

  useEffect(() => {
    const now = new Date();
    const year = now.getFullYear();
    const weekNumber = getWeek(now, { locale });
    updateYear(year);
    updateWeekNumber(weekNumber);
  }, []);

  return year && weekNumber ? { year, weekNumber } : null;
};

export const weekStartAndEnd = (
  yearAndWeek: YearAndWeek | null
): [string, string] => {
  if (!yearAndWeek) {
    return ["", ""];
  }
  const day = firstDayInWeek(yearAndWeek);
  return [day, addDays(day, 6)].map(formatDate) as [string, string];
};

export const addWeeks = (
  yearAndWeek: YearAndWeek,
  count: number
): YearAndWeek => {
  const day = addDays(firstDayInWeek(yearAndWeek), 7 * count);
  return {
    year: day.getFullYear(),
    weekNumber: getWeek(day, { locale }),
  };
};

export const getWeeksInYear = (year: number) =>
  getISOWeeksInYear(new Date(year, 2, 1));

export const firstDayInWeekMoment = ({
  year,
  weekNumber,
}: YearAndWeek): moment.Moment =>
  moment
    .utc(year + "-01-04", "YYYY-MM-DD")
    .add((weekNumber - 1) * 7, "days")
    .startOf("isoWeek");

export const firstDayInWeek = ({ year, weekNumber }: YearAndWeek): Date =>
  firstDayInWeekMoment({ year, weekNumber }).toDate();
