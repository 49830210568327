/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from "react";
import { GridColumns } from "@material-ui/data-grid";
import { SnackbarContext } from "../SnackbarProvider";
import ProjectApi, { ProjectItem } from "../../data/project";
import {
  Box,
  Button,
  Typography,
  Tooltip,
  makeStyles,
} from "@material-ui/core";
import { DataGrid, GridFilterModel } from "@material-ui/data-grid";
import { CustomPopup } from "../CustomPopup";

interface ProjectsProps {
  selectProject: (id: string) => void;
  projects: ProjectItem[];
}

interface Project {
  id: string;
  projectName: string;
  projectStatus: string;
  projectCustomer: string;
}

const useStyles = makeStyles({
  root: {
    "& .MuiDataGrid-cell:focus-within": {
      outline: "none !important",
    },
  },
});

export const ProjectsComponent = ({ selectProject, projects }: ProjectsProps) => {
  const [openReactivatePopup, setOpenReactivatePopup] =
    useState<boolean>(false);
  const [openArchivePopup, setOpenArchivePopup] = useState<boolean>(false);
  const { showInfo, showError } = useContext(SnackbarContext);
  const [projectId, setProjectId] = useState<string>("");
  const classes = useStyles();

  function projectMapper(projects: ProjectItem[]): Project[] {
    return projects.map((project) => {
      const status =
        project.projectStatus !== undefined ? project.projectStatus : "";

      const newStatus = changeStatusesNames(status);

      const projectObj = {
        id: project.projectId,
        projectName: project.name,
        projectStatus: newStatus,
        projectCustomer: project.customerName,
      };
      return projectObj;
    });
  }

  useEffect(() => {
    let stringFilter = localStorage.getItem("filterProjectStatus");
    if(stringFilter === null){
      setFilterModel({
        items: [{ columnField: "projectStatus", operatorValue: "is", value: "Active" }],
      })
    }else{
      setFilterModel(JSON.parse(stringFilter))
    }
  }, [])

  function changeStatusesNames(status: string): string {
    let newStatusName: string;
    switch (status) {
      case "ACTIVE":
        newStatusName = "Active";
        break;
      case "ARCHIVED":
        newStatusName = "Archived";
        break;
      default:
        newStatusName = "";
    }
    return newStatusName;
  }

  const reactivateProject = (id: string) => {
    ProjectApi.reactivateProject(id)
      .then((res) => {
        if (res === true) {
          showInfo("Project is successfully reactivated!");
          setTimeout(function () {
            window.location.reload();
          }, 3000);
        } else {
          showError("Can't reactivate project, please try again!");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const archiveProject = (id: string) => {
    ProjectApi.archiveProject(id)
      .then((res) => {
        if (res === true) {
          showInfo("Project is successfully archived!");
          setTimeout(function () {
            window.location.reload();
          }, 3000);
        } else {
          showError("Can't archive project, please try again!");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getGridColumns = (): GridColumns => {
    const cols: GridColumns = [];

    cols.push({
      field: "projectName",
      headerName: "Name",
      flex: 1,
      filterable: true,
      renderCell: (cellValues) => {
        return (
          <div
            style={{
              color: "#757ce8",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => selectProject(cellValues.row.id)}
          >
            {cellValues.row.projectName}
          </div>
        );
      },
    });

    cols.push({
      field: "projectCustomer",
      headerName: "Customer",
      flex: 1,
      filterable: false,
      sortable: true,
    });

    cols.push({
      field: "projectStatus",
      headerName: "Status",
      flex: 1,
      sortable: true,
      type: "singleSelect",
      valueOptions: ["Active", "Archived"],
    });

    cols.push({
      field: "Action",
      flex: 1,
      filterable: false,
      renderCell: (cellValues) => {
        return (
          <div>
            {cellValues.row.projectStatus === "Archived" ? (
              <>
                <Tooltip
                  title={
                    <Typography color="inherit">
                      Click this button to reactivate this project.
                    </Typography>
                  }
                  placement="right"
                  arrow
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      setOpenReactivatePopup(true);
                      setProjectId(cellValues.row.id);
                    }}
                  >
                    Reactivate
                  </Button>
                </Tooltip>
              </>
            ) : null}

            {cellValues.row.projectStatus === "Active" ? (
              <Tooltip
                title={
                  <Typography color="inherit">
                    Click this button to archive this project.
                  </Typography>
                }
                placement="right"
                arrow
              >
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    setOpenArchivePopup(true);
                    setProjectId(cellValues.row.id);
                  }}
                >
                  Archive
                </Button>
              </Tooltip>
            ) : null}
          </div>
        );
      },
    });

    return cols;
  };

  const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
    items: [
      { columnField: "projectStatus", operatorValue: "is", value: "Active" },
    ],
  });

  const filterModelFunc = (model: GridFilterModel) => {
    setFilterModel(model);
    localStorage.setItem("filterProjectStatus", JSON.stringify(model));
  };

  const rowColumns = projectMapper(projects);
  const gridColumns = getGridColumns();

  

  return (
    <Box sx={{ height: 650, width: "100%" }}>
      <DataGrid
        autoHeight
        rows={rowColumns}
        columns={gridColumns}
        hideFooterPagination
        rowsPerPageOptions={[rowColumns.length]}
        pageSize={rowColumns.length}
        disableSelectionOnClick
        filterModel={filterModel}
        onFilterModelChange={filterModelFunc}
        className={classes.root}
      />
      <CustomPopup
        open={openReactivatePopup}
        title="Reactivate project?"
        description="Are you sure you want to reactivate this project?"
        onClose={() => {
          setOpenReactivatePopup(false);
        }}
        onDisAgree={() => {
          setOpenReactivatePopup(false);
        }}
        onAgree={() => {
          reactivateProject(projectId);
        }}
      />
      <CustomPopup
        open={openArchivePopup}
        title="Archive project?"
        description="Are you sure you want to archive this project?"
        onClose={() => {
          setOpenArchivePopup(false);
        }}
        onDisAgree={() => {
          setOpenArchivePopup(false);
        }}
        onAgree={() => {
          archiveProject(projectId);
        }}
      />
    </Box>
  );
};
