import { Button, makeStyles, TableCell, TableRow } from "@material-ui/core";
import { Delete, Save } from "@material-ui/icons";
import { useState } from "react";
import { GoogleMapsPlacefinder } from "../../../component/GoogleMapsPlacefinder";
import { TextInput } from "../../../component/TextInput";
import UserAddressApi, { UpdateUserAddress } from "../../../data/address";
import { isNotEmpty, useValidator } from "../../../hooks/validator";

const useStyles = makeStyles((theme) => ({
  addButton: {
    marginLeft: "auto",
    marginRight: "0px",
  },
  tableCell: {
    borderBottom: "none",
    padding: "5px",
    width: "auto",
  },
  tableCellSmall: {
    borderBottom: "none",
    padding: "5px",
    width: "0px", // Force as small as possible
  },
}));

export type AddressRowProps = {
  value: UpdateUserAddress;
  isEditable: boolean;
  dispatch: (action: any) => void;
};

export const AddressRow = ({
  value,
  isEditable,
  dispatch,
}: AddressRowProps) => {
  const [rowHoverIndex, setRowHoverIndex] = useState<number | null>(null);
  const [isUpdated, setUpdated] = useState(false);
  const [address, setAddress] = useState(value);

  const classes = useStyles();
  const validator = useValidator(address);

  const save = () => {
    if (!isEditable) {
      return;
    }

    if (address.id === "") {
      UserAddressApi.addUserAddress(address).then((newId) => {
        setAddress({
          ...address,
          id: newId,
        });
        setUpdated(false);
      });
    } else {
      UserAddressApi.updateUserAddress(address).then(() => {
        setUpdated(false);
      });
    }
  };

  const remove = () => {
    if (!isEditable) {
      return;
    }
    UserAddressApi.removeUserAddress(address.id).then(() => {
      setUpdated(false);
    });
  };

  const handleAddressRemoved = (id: string) => {
    console.log("Address removed: " + id);
    dispatch({ type: "addressRemoved", id });
  };
  return (
    <TableRow
      key={0}
      onMouseEnter={() => setRowHoverIndex(0)}
      onMouseLeave={() => setRowHoverIndex(null)}
    >
      <TableCell className={classes.tableCell}>
        {isEditable ? (
          <TextInput
            id="address-name-editable"
            error={validator.get("name")}
            onBlur={() =>
              validator.validate(
                "name",
                "Address must have a name!",
                isNotEmpty
              )
            }
            label="Name"
            value={address.name}
            onChange={(value) => {
              setAddress({
                ...address,
                name: value,
              });
              setUpdated(true);
            }}
          />
        ) : (
          <TextInput id="address-name" label="" value={address.name} />
        )}
      </TableCell>
      <TableCell className={classes.tableCell}>
        {isEditable ? (
          <GoogleMapsPlacefinder
            label="Address"
            value={{
              placeId: address.googleAddressId ?? "",
              description: address.googleAddress ?? "",
            }}
            sessionToken={address.googleSessionToken}
            onSelectPrediction={(value) => {
              setAddress({
                ...address,
                googleAddressId: value?.placeId,
                googleAddress: value?.description,
              });
              setUpdated(true);
            }}
            error={validator.get("googleAddressId")}
            onBlur={() =>
              validator.validate(
                "googleAddressId",
                "A location must be selected!",
                isNotEmpty
              )
            }
          />
        ) : (
          <TextInput
            id="address-google-address"
            label=""
            value={address.googleAddress ?? ""}
          />
        )}
      </TableCell>
      <TableCell className={classes.tableCellSmall}>
        {isEditable && isUpdated ? (
          <Button onClick={save} disabled={!isUpdated}>
            <Save style={rowHoverIndex === 0 ? {} : { opacity: 0 }} />
          </Button>
        ) : (
          ``
        )}
      </TableCell>
      <TableCell className={classes.tableCellSmall}>
        {isEditable && !isUpdated ? (
          <Button onClick={remove} disabled={isUpdated}>
            <Delete
              style={rowHoverIndex === 0 ? {} : { opacity: 0 }}
              onClick={() => {
                setAddress({
                  ...address,
                  id: "",
                });
                setUpdated(false);
                handleAddressRemoved(address.id);
              }}
            />
          </Button>
        ) : (
          ``
        )}
      </TableCell>
    </TableRow>
  );
};
