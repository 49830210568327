import React, {
  FunctionComponent,
  useState,
  useEffect,
  useCallback,
  useMemo,
  createContext,
} from "react";
import { Snackbar } from "@material-ui/core";
import { Alert, Color } from "@material-ui/lab";

interface ISnackbar {
  showInfo: (message: string) => void;
  showError: (message: string) => void;
}

interface Message {
  severity: Color;
  message: string;
}

export const SnackbarContext = createContext<ISnackbar>({
  showInfo: console.log,
  showError: console.error,
});

const AUTO_DISMISS = 3000;

export const SnackbarProvider: FunctionComponent<any> = ({ children }) => {
  const [messages, setMessages] = useState<Message[]>([]);

  useEffect(() => {
    if (messages.length > 0) {
      const timer = setTimeout(
        () => setMessages((message) => message.slice(0, message.length - 1)),
        AUTO_DISMISS
      );
      return () => clearTimeout(timer);
    }
  }, [messages]);

  const showInfo = useCallback(
    (message: string) =>
      setMessages((messages) => [{ severity: "info", message }, ...messages]),
    []
  );
  const showError = useCallback(
    (message: string) =>
      setMessages((messages) => [{ severity: "error", message }, ...messages]),
    []
  );

  const value = useMemo(() => ({ showInfo, showError }), [showInfo, showError]);

  return (
    <SnackbarContext.Provider value={value}>
      {children}
      {messages.map((message) => (
        <Snackbar key={message.message} open>
          <Alert style={{ whiteSpace: "pre" }} severity={message.severity}>
            {message.message}
          </Alert>
        </Snackbar>
      ))}
    </SnackbarContext.Provider>
  );
};
