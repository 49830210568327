import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import { RouterState, connectRouter } from "connected-react-router";
import { History } from "history";

import { authConfig, authReducer, AuthState } from "./authentication.reducer";

export interface RootState {
  router: RouterState;
  auth: AuthState;
}

const createRootReducer = (history: History<any>) =>
  combineReducers({
    router: connectRouter(history),
    auth: persistReducer(authConfig, authReducer),
  });
export default createRootReducer;
