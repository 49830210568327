import React from "react";
import {
  Container,
  createStyles,
  IconButton,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
} from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { RemoteDataContainer } from "../../component/RemoteDataContainer";
import { Title } from "../../component/Title";
import CustomerApi, { CustomerItem } from "../../data/customer";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      display: "flex",
      justifyContent: "space-between",
    },
    cell: {
      verticalAlign: "top",
    },
  })
);

const Customers = ({ customers, selectCustomer, }: { customers: CustomerItem[]; selectCustomer: (id: string) => void; }) => {
  const classes = useStyles();

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Project(s)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {customers.map((customer) => (
            <TableRow
              key={customer.customerId}
              hover
              onClick={() => selectCustomer(customer.customerId)}
            >
              <TableCell className={classes.cell}>{customer.name}</TableCell>
              <TableCell className={classes.cell}>
                {customer.projects?.map((project) => (
                  <div key={project.projectId}>{project.name}</div>
                ))}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const CustomerList = withRouter(({ history }: RouteComponentProps) => {
  const classes = useStyles();

  const selectCustomer = (id: string) => {
    history.push(`/admin/customers/${id}`);
  };

  return (
    <Grid container>
      <Grid container item xs={9}>
        <RemoteDataContainer
          fetch={CustomerApi.getCustomers}
          errorMessage={() => "Failed loading customers"}
          renderData={(customers) => (
            <Container>
              <div className={classes.header}>
                <Title>Customers</Title>
                <IconButton
                  id="add-customer"
                  color="primary"
                  onClick={() => selectCustomer("new")}
                >
                  <AddIcon />
                </IconButton>
              </div>
              <Customers
                customers={customers}
                selectCustomer={selectCustomer}
              />
            </Container>
          )}
        />
      </Grid>
    </Grid>
  );
});
