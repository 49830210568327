import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";

interface HttpErrorProps {
  open: boolean;
  close: () => void;
  retry: () => void;
}

export const HttpError = (props: HttpErrorProps) => (
  <div>
    <Dialog open={props.open}>
      <DialogTitle>COULD NOT CONNECT TO THE SERVER</DialogTitle>
      <DialogContent>
        Your form could not be submitted. Things should go back to normal in a
        matter of minutes. You may try to submit again, if this keeps failing
        please come back later.
      </DialogContent>
      <DialogActions>
        <Button autoFocus color="primary" onClick={props.close}>
          CANCEL
        </Button>
        <Button autoFocus color="secondary" onClick={props.retry}>
          TRY AGAIN
        </Button>
      </DialogActions>
    </Dialog>
  </div>
);
