import { LinearProgress } from "@material-ui/core";
import {
  DataGrid,
  DataGridProps,
  GridFilterModel,
  GridOverlay,
} from "@material-ui/data-grid";
import React from "react";

export type AppDataGridProps = DataGridProps & {
  useHorizontalLoadingBar?: boolean;
  setShowRevertButton: React.Dispatch<React.SetStateAction<boolean>>;
};

const LinearLoadingOverlay = () => {
  return (
    <GridOverlay>
      <div style={{ position: "absolute", top: 0, width: "100%" }}>
        <LinearProgress />
      </div>
    </GridOverlay>
  );
};

export const AppDataGrid = (props: AppDataGridProps) => {
  const {
    useHorizontalLoadingBar,
    components,
    setShowRevertButton,
    ...otherProps
  } = props;
  const [checkboxSelection, setCheckboxSelection] = React.useState(true);
  const [showCheckBox, setShowCheckBox] = React.useState("Submitted");

  const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
    items: [{ columnField: "status", operatorValue: "is", value: "Submitted" }],
  });

  // Overwrite the component (props) if any are given
  const componentsNew = components ?? {};

  if (useHorizontalLoadingBar ?? true) {
    componentsNew.LoadingOverlay = LinearLoadingOverlay;
  }

  const filterModelFunc = (model: GridFilterModel) => {
    if(!model.items.length){
      return
    }

    setFilterModel(model);
    const SubmittedWithNot =
      model.items[0].value === "Submitted" &&
      model.items[0].operatorValue === "not";
    const ApprovedWithNot =
      model.items[0].value === "Approved" &&
      model.items[0].operatorValue === "not";
    const UnsubmittedWithIs =
      model.items[0].value === "Unsubmitted" &&
      model.items[0].operatorValue === "is";
    const ApprovedWithIs =
      model.items[0].value === "Approved" &&
      model.items[0].operatorValue === "is";
    const undefinedSelection = model.items[0].value === undefined;

    if (
      SubmittedWithNot ||
      UnsubmittedWithIs ||
      ApprovedWithNot ||
      undefinedSelection
    ) {
      setShowRevertButton(false);
      setCheckboxSelection(false);
    } else if (ApprovedWithIs) {
      setShowCheckBox("Approved");
      setCheckboxSelection(true);
      setShowRevertButton(true);
    } else {
      setShowRevertButton(false);
      setShowCheckBox("Submitted");
      setCheckboxSelection(true);
    }
  };

  return (
    <DataGrid
      components={componentsNew}
      {...otherProps}
      filterModel={filterModel}
      onFilterModelChange={filterModelFunc}
      checkboxSelection={checkboxSelection}
      isRowSelectable={(params) => params.row.status === showCheckBox}
    />
  );
};
