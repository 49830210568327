import React, { useEffect, useMemo, useReducer, useState } from "react";
import {
  Badge,
  Button,
  CircularProgress,
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import WeekReportApi, {
  Status,
  BusinessTrip,
  WeekReport,
} from "../../../data/weekReport";
import { ProjectItem } from "../../../data/project";
import { initialState, reducer } from "./reducer";
import { TimesheetEntries } from "./TimesheetEntries";
import { BusinessTrips } from "./BusinessTrips";
import { useDebounce } from "../../../hooks/debounce";
import { firstDayInWeekMoment } from "../../../hooks/dates";
import {
  CloudQueue as CloudQueueIcon,
  CloudOff as CloudOffIcon,
} from "@material-ui/icons";
import { RemoteDataContainer } from "../../../component/RemoteDataContainer";
import HolidayApi, { WeekHolidayList } from "../../../data/holiday";
import UserAddressApi, {
  AddressType,
  NamedAddress,
} from "../../../data/address";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    submitButton: {
      marginRight: "40px",
    },
    buttonAndState: {
      marginTop: "15px",
    },
    saveStateText: {
      marginLeft: "5px",
    },
  })
);

const standardHoursPerDay = 8;

interface WeekReportProps {
  projects: ProjectItem[];
  contractedHours: number;
  weekReport: WeekReport;
  weekStart: string;
  year: number | null;
  weekNumber: number | null;
  showPrefill: boolean;
  disabledPrefill: boolean;
  isWeeksAhead: boolean;
  prefillFunction: () => void;
  userStartDate: string | undefined;
  userEndDate: string | undefined;
}

const WeekReportComponent = ({
  projects,
  contractedHours,
  weekReport,
  weekStart,
  year,
  weekNumber,
  showPrefill,
  disabledPrefill,
  isWeeksAhead,
  prefillFunction,
  userStartDate,
  userEndDate,
}: WeekReportProps) => {
  const [state, dispatch] = useReducer(reducer, initialState(weekReport));
  const [saving, setSaving] = useState<boolean>(false);
  const [failedSaving, setFailedSaving] = useState<boolean>(false);
  const [addresses, setAddresses] = useState<NamedAddress[]>([]);
  const [holidays, setHolidays] = useState<WeekHolidayList>({ holidays: [] });
  const [isFilledIn, setIsFilledIn] = useState<boolean>(true);
  const [showSuggested, setShowSuggested] = useState<boolean>(false);

  const debouncedReport = useDebounce(
    state.autoSave ? state.report : null,
    1000
  );

  const classes = useStyles();

  useEffect(() => {
    UserAddressApi.getAllAddressesForUser().then((res) => {
      setAddresses(res);
    });
  }, []);

  useEffect(() => {
    if (year === null || weekNumber === null) {
      return;
    }

    if (year && weekNumber) {
      let start = firstDayInWeekMoment({ year: year, weekNumber: weekNumber });
      let end = start.clone().day("Friday");
      HolidayApi.getHolidaysForWeek(start, end).then(setHolidays);
    }
  }, [year, weekNumber]);

  useEffect(() => {
    if (debouncedReport) {
      setSaving(true);
      WeekReportApi.updateWeekReport({
        weekReportId: debouncedReport.weekReportId,
        entries: debouncedReport.entries || [],
        trips: debouncedReport.trips || [],
      })
        .then(
          () => setFailedSaving(false),
          (err) => setFailedSaving(true)
        )
        .then(() => setSaving(false));
    }
  }, [debouncedReport]);

  const { gridEntries, message, trips, isDraft, isSubmitted, isApproved } =
    useMemo((): {
      gridEntries: { hours: number; error: boolean }[][];
      message: string;
      trips: BusinessTrip[];
      isDraft: boolean;
      isSubmitted: boolean;
      isApproved: boolean;
    } => {
      const isDraft = state.report.status === Status.Draft;
      const isSubmitted = state.report.status !== Status.Draft;
      const isApproved = state.report.status === Status.Approved;
      const entries = state.report.entries || [];
      const trips = state.report.trips || [];
      const totals = [...new Array(5)].map((_, dayIndex) =>
        entries
          .filter((entry) => entry.weekDay === dayIndex + 1)
          .map((entry) => entry.hours)
          .reduce((total, next) => total + next, 0)
      );
      const total = totals.reduce((total, next) => total + next, 0);
      const gridEntries = projects.map((project) =>
        [...new Array(5)].map((_, dayIndex) => {
          const hours = entries
            .filter(
              (entry) =>
                entry.projectId === project.projectId &&
                entry.weekDay === dayIndex + 1
            )
            .map((entry) => entry.hours)
            .reduce((total, next) => total + next, 0);
          return {
            hours: hours,
            error: hours !== 0 && totals[dayIndex] > standardHoursPerDay,
          };
        })
      );

      let moreThenStandardHoursInADay = totals.some(
        (h) => h > standardHoursPerDay
      );
      let message = constructWeekReportMessage(
        total,
        contractedHours,
        standardHoursPerDay,
        moreThenStandardHoursInADay
      );

      return {
        gridEntries,
        message,
        trips,
        isDraft,
        isSubmitted,
        isApproved,
      };
    }, [state, projects, contractedHours]);

  const handleEntryChange = ({
    projectId,
    weekDay,
    hours,
  }: {
    projectId: string;
    weekDay: number;
    hours: number;
  }) => {
    dispatch({ type: "entryUpdated", projectId, weekDay, hours });
  };

  const handleEntriesCleared = () => {
    dispatch({ type: "entriesCleared" });
  };

  ///returns true if there are empty "to" or "from" locations in business trips in the weekreport
  function CheckForEmptyTripFields(): boolean {
    if (debouncedReport == null || debouncedReport.trips == null) return false;
    let emptyField = false;
    debouncedReport.trips.forEach((trip) => {
      if (
        trip.addressNameFrom == null ||
        trip.addressNameFrom === "" ||
        trip.addressNameTo == null ||
        trip.addressNameTo === ""
      ) {
        emptyField = true;
      }
    });
    return emptyField;
  }

  function CheckForNaNDistance(): boolean {
    if (debouncedReport == null || debouncedReport.trips == null) return false;
    debouncedReport?.trips?.forEach((trip) => {
      if (
        trip.linkedPlaceIdFrom == null ||
        undefined ||
        "" ||
        trip.linkedPlaceIdTo == null ||
        undefined ||
        ""
      ) {
        return true;
      }
    });
    return false;
  }

  const submitWeekReport = () => {
    if (CheckForEmptyTripFields()) {
      //show alert
      setIsFilledIn(false);
      //scrolls to top to show alert
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else {
      setIsFilledIn(true);
      if (debouncedReport != null) {
        console.log(debouncedReport.trips);
      }

      if (CheckForNaNDistance()) {
        window.alert(
          "something is wrong with the location id of a business trip"
        );
        //show alert
        setIsFilledIn(false);
        //scrolls to top to show alert
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
      WeekReportApi.submitWeekReport({
        weekReportId: state.report.weekReportId,
      }).then(() => {
        dispatch({ type: "submitted" });
        setShowSuggested(true);
      });
    }
  };

  const withdrawWeekReport = () => {
    WeekReportApi.withdrawWeekReport({
      weekReportId: state.report.weekReportId,
    }).then(() => {
      dispatch({ type: "withdrawn" });
      setShowSuggested(false);
    });
  };
  const submittedAddresses = (weekReport: WeekReport) => {
    var addresses: NamedAddress[] = [];
    weekReport.trips?.map((trip, index) =>
      addresses.push(
        {
          id: "",
          name: trip.addressNameFrom ? trip.addressNameFrom : "",
          googleAddressId: trip.placeIdFrom ? trip.placeIdFrom : "",
          googleAddress: "",
          addressId: "",
          addressType: trip.linkedPlaceIdFromType
            ? trip.linkedPlaceIdFromType
            : AddressType.HOME,
        },
        {
          id: "",
          name: trip.addressNameTo ? trip.addressNameTo : "",
          googleAddressId: trip.placeIdTo ? trip.placeIdTo : "",
          googleAddress: "",
          addressId: "",
          addressType: trip.linkedPlaceIdToType
            ? trip.linkedPlaceIdToType
            : AddressType.CUSTOMER,
        }
      )
    );
    return addresses;
  };

  var expensePerKm = 0.01;
  const [dateDay, dateMonth, dateYear] = weekStart.split("-");
  const date = new Date(
    +Number(dateYear),
    Number(dateMonth) - 1,
    +Number(dateDay)
  );
  if (date.getFullYear() === 2022) {
    expensePerKm = 0.19;
  } else if (date.getFullYear() === 2023) {
    expensePerKm = 0.21;
  } else if (date.getFullYear() >= 2024) {
    expensePerKm = 0.22;
  }

  return (
    <div>
      {isFilledIn === false ? (
        <Alert severity="error">
          You have to give a "to" and "from" location for your business trip
        </Alert>
      ) : isApproved ? (
        <Alert severity="success">This week has been approved!</Alert>
      ) : isSubmitted ? (
        <Alert
          severity="info"
          action={
            <Button
              id="withdraw-button"
              color="inherit"
              size="small"
              variant="outlined"
              onClick={withdrawWeekReport}
            >
              Withdraw
            </Button>
          }
        >
          This week has been submitted and is pending approval.
        </Alert>
      ) : (
        ``
      )}
      <TimesheetEntries
        projects={projects}
        entries={gridEntries}
        message={message}
        weekStart={weekStart}
        weekReport={weekReport}
        disabledAll={isSubmitted || isApproved}
        holidays={holidays.holidays ? holidays : { holidays: [] }}
        onChange={handleEntryChange}
        onReset={handleEntriesCleared}
        showPrefill={showPrefill}
        disabledPrefill={disabledPrefill}
        prefillFunction={prefillFunction}
        showSuggested={showSuggested}
        userStartDate={userStartDate}
        userEndDate={userEndDate}
      />
      {isSubmitted || isApproved ? (
        weekReport.trips ? (
          <BusinessTrips
            addresses={submittedAddresses(weekReport)}
            trips={weekReport.trips}
            disabled={isSubmitted || isApproved}
            dispatch={dispatch}
            expensePerKm={expensePerKm}
          />
        ) : (
          <BusinessTrips
            addresses={addresses}
            trips={trips}
            disabled={isSubmitted || isApproved}
            dispatch={dispatch}
            expensePerKm={expensePerKm}
          />
        )
      ) : (
        <BusinessTrips
          addresses={addresses}
          trips={trips}
          disabled={isSubmitted || isApproved}
          dispatch={dispatch}
          expensePerKm={expensePerKm}
        />
      )}
      {isDraft && (
        <div className={classes.buttonAndState}>
          <Button
            id="weekreport-submit-button"
            className={classes.submitButton}
            variant="contained"
            color="primary"
            onClick={submitWeekReport}
          >
            Submit
          </Button>
          {saving && (
            <span>
              <Badge>
                <CircularProgress
                  style={{ maxWidth: "1em", maxHeight: "1em" }}
                />
              </Badge>
              <Badge className={classes.saveStateText}>Saving...</Badge>
            </span>
          )}
          {!saving && !failedSaving && (
            <span>
              <Badge>
                <CloudQueueIcon />
              </Badge>
              <Badge className={classes.saveStateText}>Saved</Badge>
            </span>
          )}
          {!saving && failedSaving && (
            <span>
              <Badge>
                <CloudOffIcon />
              </Badge>
              <Badge className={classes.saveStateText}>Not saved</Badge>
            </span>
          )}
        </div>
      )}
    </div>
  );
};

interface WeekReportFromProps {
  projects: ProjectItem[];
  contractedHours: number;
  getWeekReport: () => Promise<WeekReport>;
  weekStart: string;
  year: number | null;
  weekNumber: number | null;
  showPrefill: boolean;
  disabledPrefill: boolean;
  prefillFunction: () => void;
  userStartDate?: string | undefined;
  userEndDate?: string | undefined;
  isWeeksAhead: boolean;
}

export const WeekReportForm = ({
  projects,
  contractedHours,
  getWeekReport,
  weekStart,
  year,
  weekNumber,
  showPrefill,
  disabledPrefill,
  isWeeksAhead,
  prefillFunction,
  userStartDate,
  userEndDate,
}: WeekReportFromProps) => {
  return (
    <RemoteDataContainer
      fetch={getWeekReport}
      errorMessage={() => "Failed loading week report"}
      renderData={(weekReport) => (
        <WeekReportComponent
          projects={projects}
          contractedHours={contractedHours}
          weekReport={weekReport}
          weekStart={weekStart}
          year={year}
          weekNumber={weekNumber}
          showPrefill={showPrefill}
          disabledPrefill={disabledPrefill}
          isWeeksAhead={isWeeksAhead}
          prefillFunction={prefillFunction}
          userStartDate={userStartDate}
          userEndDate={userEndDate}
        />
      )}
    />
  );
};

function constructWeekReportMessage(
  total: number,
  contractedHours: number,
  standardHoursPerDay: number,
  moreThenStandardHoursInADay: boolean
): string {
  let message = ``;
  let zeroHourContract = contractedHours === 0;
  if (zeroHourContract) {
    message = `Total this week: ${total} hours.`;
  } else {
    message = `Total this week: ${total} of ${contractedHours} contracted hours.`;
  }
  if (moreThenStandardHoursInADay) {
    message =
      message +
      ` More then ${standardHoursPerDay} hours filled in for at least one day!`;
  }
  return message;
}
