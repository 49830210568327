import { Box, Grid, makeStyles, Theme } from "@material-ui/core";
import { DeclarationsBlock } from "./dashboard/DeclarationsBlock";
import { SyncStatussesBlock } from "./dashboard/SyncStatussesBlock";
import { TimesheetsBlock } from "./dashboard/TimesheetsBlock";
import { WeekReportTable } from "./dashboard/WeekReportTable";

const useStyles = makeStyles((theme: Theme) => ({
  mb: {
    marginBottom: "1.5em",
  },
}));

export const Dashboard = () => {
  const classes = useStyles();

  return (
    <Box m="3%">
      <Grid container spacing={3}>
        <Grid item sm={9}>
          <WeekReportTable></WeekReportTable>
        </Grid>
        <Grid item sm={3}>
          <div className={classes.mb}>
            <TimesheetsBlock></TimesheetsBlock>
          </div>
          <div className={classes.mb}>
            <DeclarationsBlock></DeclarationsBlock>
          </div>
          <div className={classes.mb}>
            <SyncStatussesBlock></SyncStatussesBlock>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};
