import React from "react";
import { TextField } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";

interface TextInputProps {
  label: string;
  value?: number;
  onChange?: (value: number) => void;
  onBlur?: () => void;
  error?: string;
  prefix?: string;
  className?: string;
}

export const NumberInput = (props: TextInputProps) => (
  <TextField
    label={props.label}
    variant="outlined"
    fullWidth
    value={props.value}
    onBlur={props.onBlur}
    error={!!props.error}
    helperText={props.error}
    type="number"
    disabled={!props.onChange}
    onChange={(event) =>
      props.onChange && props.onChange(event.target.value as unknown as number)
    }
    InputProps={
      props.prefix
        ? {
            startAdornment: (
              <InputAdornment position={`start`}>{props.prefix}</InputAdornment>
            ),
          }
        : undefined
    }
    className={props.className}
  />
);
