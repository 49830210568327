import { AddressUpdate } from "./address";
import { getAxios } from "./axios";

interface CustomerBase {
  name: string;
  emailAddress: string;
  invoiceEmailAddress: string | undefined;
  mailAddress: string | undefined;
}

export interface CustomerItem extends CustomerBase {
  customerId: string;
  visitingAddressId: string;
  visitingAddress: string;
  projects: CustomerProject[];
}

export interface CustomerProject {
  projectId: string;
  name: string;
}

export interface CustomerEdit extends CustomerBase, AddressUpdate {}

export interface CreateCustomer extends CustomerEdit {}

export interface UpdateCustomer extends CreateCustomer {
  customerId: string;
}

export interface DisableCustomerCommand {
  customerId: string;
}

export default class CustomerApi {
  static async getCustomers(): Promise<CustomerItem[]> {
    let response = await getAxios().get<CustomerItem[]>(`/getCustomers`);
    return response.data;
  }

  static async getCustomer(id: string): Promise<CustomerItem> {
    let response = await getAxios().get<CustomerItem>(`/customer/${id}`);
    return response.data;
  }

  static async createCustomer(command: CreateCustomer): Promise<string> {
    let response = await getAxios().post<string>(`/createCustomer`, command);
    return response.data;
  }

  static async updateCustomer(command: UpdateCustomer): Promise<string> {
    let response = await getAxios().post<string>(`/updateCustomer`, command);
    return response.data;
  }

  static async disableCustomer(command: DisableCustomerCommand): Promise<void> {
    await getAxios().post<string>(`/disableCustomer`, command);
  }
}
