import {
  CircularProgress,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { useEffect, useReducer, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";

import { useSelector } from "react-redux";
import { Account } from "../../../data/principal";
import { RootState } from "../../../redux/reducers";
import UserAddressApi, {
  AddressType,
  UpdateUserAddress,
} from "../../../data/address";
import { v4 as uuidv4 } from "uuid";
import { AddressRow } from "./AddressRow";
import { useDebounce } from "../../../hooks/debounce";

const useStyles = makeStyles((theme) => ({
  addButton: {
    marginLeft: "auto",
    marginRight: "0px",
  },
  divider: {
    marginTop: "5px",
    marginBottom: "5px",
  },
  horizontalChildren: {
    display: "flex",
    flexDirection: "row",
  },
  spinner: {
    marginLeft: "50%",
  },
  spinnerContainer: {
    position: "relative",
  },
  tableCell: {
    borderBottom: "none",
    padding: "5px",
  },
}));

const createEmptyUserAddress = (): UpdateUserAddress => {
  return {
    id: "",
    name: "",
    googleAddressId: "",
    googleAddress: "",
    googleSessionToken: uuidv4(),
    addressType: AddressType.USER,
  };
};

const initialState = (rows: UpdateUserAddress[]) => ({
  rows,
  autoSave: false,
});

interface State {
  rows: UpdateUserAddress[];
  autoSave: boolean;
}

type Action = { type: "addressRemoved"; id: string };
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "addressRemoved": {
      var addresses: UpdateUserAddress[] = state.rows;

      return {
        rows: addresses.filter(
          (address: UpdateUserAddress) => address.id !== action.id
        ),
        autoSave: true,
      };
    }
  }
};

export const Addresses = () => {
  const account = useSelector<RootState>(
    (state) => state.auth.currentAccount
  ) as Account | null;
  const classes = useStyles();
  const [loadingAddresses, setLoadingAddresses] = useState<boolean>(false);
  const [rows, setRows] = useState<UpdateUserAddress[]>([]);

  const [state, dispatch] = useReducer(reducer, initialState(rows));
  const debouncedAddresses = useDebounce(
    state.autoSave ? state.rows : null,
    1000
  );

  useEffect(() => {
    setLoadingAddresses(true);

    if (account) {
      UserAddressApi.getAllAddressesForUser()
        .then((addresses) =>
          setRows(
            addresses.map((addr) => {
              let newAddr: UpdateUserAddress = {
                id: addr.id,
                name: addr.name,
                googleAddressId: addr.googleAddressId,
                googleAddress: addr.googleAddress,
                googleSessionToken: uuidv4(),
                addressType: addr.addressType,
              };
              return newAddr;
            })
          )
        )
        .finally(() => {
          setLoadingAddresses(false);
        });
    }
    if (debouncedAddresses) {
      setRows(debouncedAddresses);
    }
  }, [account, debouncedAddresses]);

  return loadingAddresses ? (
    <div className={classes.spinnerContainer}>
      <CircularProgress disableShrink className={classes.spinner} />
    </div>
  ) : (
    <div>
      <div className={classes.horizontalChildren}>
        <Typography variant="h5">ADDRESSES</Typography>
        <IconButton
          className={classes.addButton}
          onClick={() => {
            setRows([...rows, createEmptyUserAddress()]);
          }}
        >
          <AddIcon />
        </IconButton>
      </div>
      <Divider className={classes.divider} variant="fullWidth"></Divider>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCell}>
                <Typography variant="subtitle2">Name</Typography>
              </TableCell>
              <TableCell className={classes.tableCell}>
                <Typography variant="subtitle2">Address</Typography>
              </TableCell>
              <TableCell className={classes.tableCell}></TableCell>
              <TableCell className={classes.tableCell}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length > 0
              ? rows.map((row, index) => (
                  <AddressRow
                    key={index}
                    value={row}
                    isEditable={row.addressType === AddressType.USER}
                    dispatch={dispatch}
                  ></AddressRow>
                ))
              : `There are no addresses`}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
