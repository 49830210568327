import { getAxios } from "./axios";
import { Role } from "./user";

interface Principal {
  id: string;
  name: string;
  emailAddress: string;
  addressId: string;
  roles?: Role[];
  userAccountStatus?: string
}

export interface Account {
  id: string;
  name: string;
  emailAddress: string;
  addressId: string;
  isAdmin: boolean;
  isEmployee: boolean;
  userAccountStatus?: string
}

export default class PrincipalApi {
  static async getAccount(): Promise<Account> {
    let response = await getAxios().get<Principal>(`/me`);
    const account = {
      id: response.data.id,
      name: response.data.name,
      emailAddress: response.data.emailAddress,
      addressId: response.data.addressId,
      isAdmin: response.data.roles?.includes(Role.Admin) ?? false,
      isEmployee: response.data.roles?.includes(Role.Employee) ?? false,
      userAccountStatus: response.data.userAccountStatus
    };
    return account;
  }
}
