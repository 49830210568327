export const AuthenticationConstants = {
  AUTHORIZATION_START: "auth/authorization_start",
  AUTHORIZATION_END: "auth/authorization_end",
  LOGIN: "auth/login",
  LOGOUT: "auth/logout",
  TOKEN_SET: "auth/token_set",
  TOKEN_UNSET: "auth/token_unset",
  TOKEN_REFRESH_UNSET: "auth/token_refresh_unset",
  ACCOUNT_SET: "auth/account_set",
  ACCOUNT_UNSET: "auth/account_unset",
  REFRESH_START: "auth/refresh_start",
  REFRESH_END: "auth/refresh_end",
};
