import config from "../config";

class AuthService {
  static isAuthenticated(): Boolean {
    return !!config.apiToken && AuthService.tokenIsValid(config.apiToken);
  }

  static msTillExpiration(token: string): number {
    let tokenObject = AuthService.decodeJwtToken(token);
    return tokenObject.exp * 1000 - Date.now();
  }

  static msTillRefresh(token: string): number {
    let msLeft = AuthService.msTillExpiration(token) - 1000 * 3600 * 3;
    return msLeft > 0 ? msLeft : 0;
  }

  static tokenIsValid(token: string): Boolean {
    try {
      return AuthService.msTillExpiration(token) > 0;
    } catch (error) {
      return false;
    }
  }

  static decodeJwtToken(token: string): any {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace("-", "+").replace("_", "/");
    return JSON.parse(window.atob(base64));
  }
}

export default AuthService;
