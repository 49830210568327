import React, { useState, useEffect } from "react";
import {
  AppBar,
  Icon,
  IconButton,
  Toolbar,
  Typography,
  makeStyles,
  Theme,
  createStyles,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Chip,
  Button,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import {
  Menu as MenuIcon,
  AccountCircle as AccountCircleIcon,
} from "@material-ui/icons";
import { RouteComponentProps, RouteProps, withRouter } from "react-router-dom";

import { routes as adminRoutes } from "./page/AdminRoutes";
import { routes as employeeRoutes } from "./page/EmployeeRoutes";
import DeclarationApi from "./data/declaration";
import UserApi, { Role } from "./data/user";
import { useSelector } from "react-redux";
import { RootState } from "./redux/reducers";
import { Account } from "./data/principal";
import { AuthenticationActions } from "./redux/actions";

export interface AppBarRoute {
  title?: string;
  props: RouteProps;
  isExpenses?: boolean;
}

export const path = (route: AppBarRoute): string => {
  const parts = route.props.path || "";
  return Array.isArray(parts) ? parts.join("/") : (parts as string);
};

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      display: "flex",
      marginBottom: "1em",
    },
    appBar: {
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    toolBar: {
      background: theme.palette.secondary.main,
      minHeight: "80px",
    },
    expenseChip: {
      backgroundColor: "#0081d7",
      color: "#FFFFFF",
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: "none",
    },
    title: {
      flexGrow: 1,
      height: "50px",
    },
    logo: {
      height: "50px",
      cursor: "pointer",
    },
    accountName: {
      marginRight: "0.7em",
    },
    accountType: {
      marginTop: "-0.5em",
      opacity: 0.6,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      height: "100%",
    },
    drawerPaper: {
      width: drawerWidth,
      justifyContent: "space-between",
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: "flex-end",
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
    },
    contentShift: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
  });
});

const adminRootPath = "/admin";
const employeeRootPath = "/employee";

const adminConfig = {
  routes: adminRoutes,
  rootPath: adminRootPath,
  label: "Administrator",
  switchPath: employeeRootPath,
  switchTitle: "Switch to Employee",
};

const employeeConfig = {
  routes: employeeRoutes,
  rootPath: employeeRootPath,
  label: "Employee",
  switchPath: adminRootPath,
  switchTitle: "Switch to Admin",
};

export const WithAppBar = ({ history, location }: RouteComponentProps) => {
  const dispatch = useDispatch();
  const account = useSelector<RootState>(
    (state) => state.auth.currentAccount
  ) as Account | null;

  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const [firstTimeLogin, setFirstTimeLogin] = useState<boolean>(false);
  const [numberOfDeclarationsAwaiting, setNumberOfDeclarationsAwaiting] =
    useState(0);

  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const openDropdown = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const closeDropdown = () => {
    setAnchorEl(null);
  };

  const goToProfile = (): void => {
    history.push(
      location.pathname.startsWith(adminRootPath)
        ? "/admin/profile"
        : "/employee/profile"
    );
    closeDropdown();
  };

  const logout = (): void => {
    dispatch(AuthenticationActions.logout());
  };

  location.pathname.startsWith(adminRootPath) ??
    DeclarationApi.getUnapprovedDeclarations().then((res) =>
      setNumberOfDeclarationsAwaiting(res.length)
    );

  const config = location.pathname.startsWith(adminRootPath)
    ? adminConfig
    : employeeConfig;

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    UserApi.getUser(account ? account.id : "").then((res) =>
      res.homeAddress == null && res.roles?.includes(Role.Employee)
        ? setFirstTimeLogin(true)
        : setFirstTimeLogin(false)
    ).catch(e => console.error(e));
  },[]);

  const navigateToRoot = () => {
    firstTimeLogin
      ? history.push(
          location.pathname.startsWith(adminRootPath)
            ? "/admin/profile"
            : "/employee/profile"
        )
      : history.push(config.rootPath);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar id="header" className={classes.toolBar}>
          <IconButton
            id="drawer"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
          >
            <MenuIcon style={{ fontSize: 50 }} />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            <img
              src="/quad.png"
              onClick={navigateToRoot}
              className={classes.logo}
              alt="Quad"
            />
          </Typography>
          {account && (
            <div>
              <Typography variant="subtitle1" className={classes.accountName}>
                {account.name}
              </Typography>
              {account.isAdmin && account.isEmployee && (
                <Typography variant="body2" className={classes.accountType}>
                  {config.label}
                </Typography>
              )}
            </div>
          )}
          <Button
            id="user-profile-settings"
            onClick={openDropdown}
            color="inherit"
          >
            <Icon color="inherit">
              <AccountCircleIcon />
            </Icon>
          </Button>
          <Menu
            anchorEl={anchorEl}
            getContentAnchorEl={() => anchorEl!}
            anchorReference={"anchorEl"}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={closeDropdown}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <MenuItem id="profile" onClick={goToProfile}>
              Profile
            </MenuItem>
            <MenuItem id="logout" onClick={logout}>
              Logout
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="temporary"
        anchor="left"
        open={open}
        onClose={() => handleDrawerClose()}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <List>
          {config.routes
            .filter((route) => !!route.title)
            .map((route, index) => (
              <ListItem
                id={route.title}
                button
                key={index}
                onClick={() => {
                  firstTimeLogin
                    ? history.push(
                        location.pathname.startsWith(adminRootPath)
                          ? "/admin/profile"
                          : "/employee/profile"
                      )
                    : history.push(path(route));
                  handleDrawerClose();
                }}
              >
                <ListItemText primary={route.title}></ListItemText>
                {route.isExpenses && numberOfDeclarationsAwaiting > 0 ? (
                  <Chip
                    className={classes.expenseChip}
                    label={numberOfDeclarationsAwaiting}
                    color="secondary"
                  />
                ) : (
                  ``
                )}
              </ListItem>
            ))}
        </List>
        {account?.isAdmin && account?.isEmployee && (
          <List>
            <ListItem
              id={config.switchTitle}
              button
              alignItems="flex-start"
              onClick={() => {
                firstTimeLogin
                  ? history.push(config.switchPath + "/profile")
                  : history.push(config.switchPath);
                handleDrawerClose();
              }}
            >
              <ListItemText primary={config.switchTitle} />
            </ListItem>
          </List>
        )}
      </Drawer>
    </div>
  );
};

export default withRouter(WithAppBar);
