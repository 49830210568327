import React from "react";
import {
  Container,
  createStyles,
  IconButton,
  Grid,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { RemoteDataContainer } from "../../component/RemoteDataContainer";
import { Title } from "../../component/Title";
import UserApi, { SimpleUserItem } from "../../data/user";
import UserListComponent from "../../component/User/UserListComponent";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      display: "flex",
      justifyContent: "space-between",
    },
    cell: {
      verticalAlign: "top",
    },
  })
);

const Users = ({
  selectUser,
}: {
  users: SimpleUserItem[];
  selectUser: (id: string) => void;
}) => {
  return <UserListComponent selectUser={selectUser} />;
};

export const UserList = withRouter(({ history }: RouteComponentProps) => {
  const classes = useStyles();

  const selectUser = (id: string) => {
    history.push(`/admin/users/${id}`);
  };

  return (
    <Grid container>
      <Grid container item xs={9}>
        <RemoteDataContainer
          fetch={UserApi.getUsers}
          errorMessage={() => "Failed loading users"}
          renderData={(users) => (
            <Container>
              <div className={classes.header}>
                <Title>Users</Title>
                <div>
                  <IconButton
                    id="add-user"
                    color="secondary"
                    onClick={() => selectUser("new")}
                  >
                    <AddIcon />
                  </IconButton>
                </div>
              </div>
              <Users users={users} selectUser={selectUser} />
            </Container>
          )}
        />
      </Grid>
    </Grid>
  );
});
