import React, { FunctionComponent } from "react";
import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles({
  title: {
    textTransform: "uppercase",
  },
});

export const Title: FunctionComponent<{}> = (props) => {
  const classes = useStyles();
  return (
    <Typography variant="h6" className={classes.title}>
      {props.children}
    </Typography>
  );
};
