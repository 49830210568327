import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";
import { Redirect } from "react-router-dom";
import React from "react";
import { AuthenticationActions } from "../../redux/actions";
import LoadingComponent from "../../component/Authentication/Loading";

export const AppBodyAuthCallback = () => {
  const dispatch = useDispatch();

  const isInAuthorization = useSelector<RootState, boolean>(
    (state) => state.auth.isInAuthorization
  );

  if (!isInAuthorization) {
    return <Redirect to="/authorize" />;
  }

  const params = new URLSearchParams(window.location.search);
  const oidcCode = params.get("code");
  const oidcState = params.get("state");
  if (oidcCode === null || oidcState === null) {
    return (
      <React.Fragment>
        <p>No authorization code present</p>
      </React.Fragment>
    );
  }

  dispatch(
    AuthenticationActions.loginAuthorization(
      oidcCode,
      oidcState,
      window.location.origin + "/authCallback"
    )
  );

  return <LoadingComponent />;
};
