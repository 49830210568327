import { Button, CircularProgress, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import WeekReportApi, { PrefillWeekReportResult } from "../../data/weekReport";

interface RouterMatch {
  prefillId: string;
}

export const EmailPrefillResponse = () => {
  let params = useParams<RouterMatch>();

  const [prefillResult, setPrefillResult] = useState<
    PrefillWeekReportResult | undefined
  >(undefined);

  useEffect(() => {
    if (prefillResult === undefined) {
      if (params.prefillId !== undefined && params.prefillId.length > 0) {
        WeekReportApi.prefillWeekReport(params.prefillId).then(
          (res) => {
            setPrefillResult(res);
          },
          (err) => {
            setPrefillResult({
              success: false,
              message: "Something went wrong!",
            });
          }
        );
      } else {
        setPrefillResult({
          success: false,
          message: "The prefill URL was incomplete!",
        });
      }
    }
  }, [prefillResult, params]);

  return prefillResult === undefined ? (
    <CircularProgress style={{ margin: "10%" }} />
  ) : prefillResult.success === true ? (
    <div
      style={{
        margin: "5%",
      }}
    >
      <Typography variant="h5">Thanks {prefillResult.message}!</Typography>
      <div>
        Your statement for week {prefillResult.weekNumber} was submitted
        successfully!
      </div>
      <div>
        You may safely close this window or log in case you have expense
        declarations to submit.
      </div>
      <Button
        onClick={() => {
          window.location.href = "/employee";
        }}
      >
        Log in
      </Button>
    </div>
  ) : (
    <div style={{ margin: "5%" }}>
      <Typography variant="h5">Sorry!</Typography>
      {prefillResult.message}
    </div>
  );
};
