import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";
import { Account } from "../../data/principal";
import React, { useEffect, useState } from "react";
import {
  actionAuthorizationEnd,
  actionAuthorizationStart,
  actionLogin,
  actionLogout,
  actionSetAccount,
  actionSetToken,
  AuthenticationActions,
} from "../../redux/actions";

import BlurCircular from "@material-ui/icons/BlurCircular";
import SecurityIcon from "@material-ui/icons/Security";
import { Redirect } from "react-router-dom";
import Box from "../../component/Box";
import Logger from "../../lib/Logger";
import quadClient from "../../lib/client/quadClient";
import config from "../../config";
import { Alert } from "@material-ui/lab";
import { LoadingButton } from "../../component/LoadingButton";

// const USERNAME = "brenti.bokie@quadsolutions.nl";
// const PASSWORD = "test1";
const TOKEN = "570e1462-cdd1-4723-997b-47e59975aba9test";

const logger = new Logger("LoginPage");

export const LoginPage = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>("");
  const [credentials, setCredentials] = useState({
    email: "",
    username: "",
    password: "",
  });

  const dispatch = useDispatch();

  const isLoggedIn = useSelector<RootState, boolean>(
    (state) => state.auth.isLoggedIn
  );

  const account = useSelector<RootState, Account | null>(
    (state) => state.auth.currentAccount
  );
  const refreshToken = useSelector<RootState, string | null>(
    (state) => state.auth.refreshToken
  );

  const params = new URLSearchParams(window.location.search);
  const queryToken = params.get("token");

  useEffect(() => {
    if ((!config.devAuthEnabled) && queryToken) {
      login();
    }
  }, []);

  useEffect(() => {
    if (config.devAuthEnabled || queryToken) {
      return;
    }

    // If the state claims we are able to login that means we can safely redirect back to the webroot
    if (isLoggedIn && account !== null) {
      setLoading(false);
      return;
    }

    dispatch(actionLogout());
    oauthLogin();
  }, [account, isLoggedIn, refreshToken, dispatch]);

  const oauthLogin = () => {
    // setLoading(true);

    if (refreshToken !== null) {
      // If we have a refresh token then attempt to refresh the authentication
      dispatch(AuthenticationActions.loginRefresh(refreshToken));
    } else {
      // If we don't have a refresh token we have to head to Google
      dispatch(
        AuthenticationActions.loginRedirect(
          window.location.origin + "/authCallback"
        )
      );
    }
  };

  function removeQueryParam(param: string) {
    // Get the current URL
    let url = new URL(window.location.href);

    // Get the query parameters
    let params = new URLSearchParams(url.search);

    // Remove the specified query parameter
    params.delete(param);

    // Update the URL without the reloaded query parameter
    url.search = params.toString();

    // Update the browser's address bar
    window.history.replaceState({}, document.title, url.toString());
  }

  const login = async () => {
    setLoading(true);
    setError("");
    try {
      dispatch(actionAuthorizationStart());

      let loginToken = queryToken;
      if (!loginToken && config.devAuthEnabled) {
        loginToken = TOKEN;
      }

      const { token, user } = await quadClient.login(null, null, loginToken);

      if (queryToken) {
        removeQueryParam("token");
      }

      dispatch(actionSetToken(token));
      dispatch(actionSetAccount(user));
      dispatch(actionLogin());
      dispatch(actionAuthorizationEnd());
    } catch (e) {
      dispatch(actionAuthorizationEnd());
      setLoading(false);
      setError(e.message);
      logger.error(e.message);
    }
  };

  if (isLoggedIn && !queryToken) {
    return <Redirect to={"/"} />;
  }

  return (
    <Box expand center column position={"fixed"}>
      <Box
        row
        style={{
          marginBottom: "20px",
          marginTop: "100px",
        }}
      >
        <LoadingButton
          style={{ marginRight: "15px" }}
          onClick={login}
          endIcon={<BlurCircular />}
          loading={loading}
          loadingPosition="end"
          variant="contained"
        >
          Login
        </LoadingButton>

        <LoadingButton
          onClick={oauthLogin}
          endIcon={<SecurityIcon />}
          loading={loading}
          loadingPosition="end"
          variant="contained"
        >
          OAUTH Login
        </LoadingButton>
      </Box>

      <Box
        style={{
          maxWidth: "350px",
          height: "100px",
        }}
      >
        {error && (
          <Alert variant="outlined" severity="error">
            {error}
          </Alert>
        )}
      </Box>
    </Box>
  );
};
