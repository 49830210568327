import React, { useContext, useEffect, useRef, useState } from "react";
import UserDocumentApi, {
  CreateUserDocument,
  UserDocumentDataItem,
} from "../../../data/userDocument";
import { DocumentTable, TableTypes } from "./DocumentTable";
import { DropdownWithTooltip } from "../../../component/DropdownWithTooltip";
import {
  Button,
  CircularProgress,
  createStyles,
  ListItem,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import UserApi, {
  Gender,
  SimpleUserItem as aSimpleUserItem,
  TravelOption,
  UserItem as aUserItem,
} from "../../../data/user";
import { SnackbarContext } from "../../../component/SnackbarProvider";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    documentPart: {
      marginTop: "30px",
    },
    inputFieldRow: {
      marginTop: "30px",
      marginBottom: "10px",
    },
    previewUploadOnDesktop: {
      minWidth: window.innerWidth * 0.475,
      maxWidth: window.innerWidth * 0.475,
      paddingBottom: "10px",
    },
    previewUploadOnMobile: {
      minWidth: window.innerWidth * 0.9,
      maxWidth: window.innerWidth * 0.9,
      paddingBottom: "10px",
    },
    pdfUnsupportedMessage: {
      height: "80px",
    },
    moveUpToOverlap: {
      position: "absolute",
      bottom: 15,
      right: 15,
      marginTop: "-35px",
    },
    trashButton: {
      float: "right",
      cursor: "pointer",
    },
    uploadBlock: {
      border: "2px dashed #bbb",
      marginLeft: "auto",
      marginRight: "auto",
      width: "100%",
      height: "45px",
      marginTop: "20px",
      paddingTop: "10px",
      paddingBottom: "55px",
      textAlign: "center",
      "&:hover": { cursor: "pointer" },
    },
    spinner: {
      marginLeft: "50%",
    },
    spinnerContainer: {
      position: "relative",
    },
    submitButton: {
      backgroundColor: "#000000",
      color: "#FFFFFF",
      marginTop: "20px",
      "&:disabled": {
        backgroundColor: "#FFFFFF",
      },
    },
  })
);

//@todo. is this not used somewhere else too. keep it in utils?
const emptyAUser: aUserItem = {
  userId: "",
  password: "",
  firstName: "",
  initials: "",
  middleName: "",
  lastName: "",
  name: "",
  emailAddress: "",
  dateOfBirth: "",
  nationality: "",
  gender: Gender.NotSpecified,
  institution: "",
  googleAccountActive: undefined,
  iban: "",
  licensePlateNumber: "",
  phoneNumber: "",
  privateEmailAddress: "",
  linkedinUrl: "",
  hardwarePreferences: "",
  plannedHolidays: "",
  employmentStartDate: "",
  employmentEndDate: "",
  travelOption: TravelOption.None,
  mobilityBudgetAmount: "0",
  contractedHours: 0,
  roles: [],
  projects: [],
  employeeNumber: "",
  homeAddressId: "",
  homeAddress: "",
  userAccountStatus: "",
  errorMessage: "",
};

const maxWidthForMobileInPixels = 1224;

interface UserDocumentsProps {
  userId: string;
  setRetryAction: (val: () => void) => void;
  setShowHttpError: (value: boolean) => void;
}

export const UserDocuments = (props: UserDocumentsProps) => {
  const { userId, setRetryAction, setShowHttpError } = props;
  const { showInfo, showError } = useContext(SnackbarContext);
  const [upload, setUpload] = useState<boolean>(false);
  const [currentFile, setCurrentFile] = useState<File | undefined>(undefined);
  const [imageAsURL, setImageAsURL] = useState<string>("");
  const [loadingDocumentMenu, setLoadingDocumentMenu] = useState(true);

  const [documentTypes, setDocumentTypes] = useState<{
    [key: string]: string;
  }>({});

  const [users, setUsers] = useState<aSimpleUserItem[]>([]);
  const [aUser, setAUser] = useState<aUserItem>(emptyAUser);
  const [undeletedUserDocuments, setUndeletedUserDocuments] = useState<
    UserDocumentDataItem[]
  >([]);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [tableType, setTableType] = useState<TableTypes>(TableTypes.SUBMITTED);

  const [userDocument, setUserDocument] = useState<CreateUserDocument>({
    parts: [{ personId: "", userDocumentType: "" }],
    base64EncodedFile: "",
  });
  let isMobileDevice: boolean = useMediaQuery(
    `(max-width:${maxWidthForMobileInPixels}px)`
  );

  const uploadID = "UPLOAD";

  const uploadDocument = (): void => {
    setSubmitting(true);
    let action = () => {
      setShowHttpError(false);
      setSubmitting(true);
      UserDocumentApi.createUserDocument(userDocument).then(
        () => {
          setUpload(true);
          showInfo("Document was uploaded");
          setCurrentFile(undefined);
          setImageAsURL("");
          setUserDocument({
            parts: [
              {
                personId: "",
                userDocumentType: "",
              },
            ],
            base64EncodedFile: "",
          });
          setSubmitting(false);
        },
        (err) => {
          if (err.message !== "Network Error") {
            showError("Failed to upload document");
          } else {
            setShowHttpError(true);
          }
          setSubmitting(false);
        }
      );
      setUpload(false);
    };
    setRetryAction(() => action);
    action();
  };

  const listRef = useRef<HTMLDivElement>(null);
  const previousScrollDiff = useRef(0);
  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTop =
        listRef.current.scrollHeight - previousScrollDiff.current;
    }
  }, []);

  useEffect(() => {
    UserApi.getUsers().then((users) => {
      setUsers(users);
    });

    if (userId && userId !== "new") {
      setLoadingDocumentMenu(true);
      UserApi.getUser(userId).then((user) => {
        setAUser(user);
      }).catch(e=> console.error(e))

      UserDocumentApi.getUndeletedUserDocuments(userId)
        .then((res) => {
          setUndeletedUserDocuments(res);
          setTableType(TableTypes.SUBMITTED);
          setLoadingDocumentMenu(false);
        })
        .catch(e=> console.error(e))
        .finally(() => setLoadingDocumentMenu(false));
    } else if (loadingDocumentMenu) {
      setLoadingDocumentMenu(false);
    }
  }, [userId, upload]);

  useEffect(() => {
    if (Object.entries(documentTypes).length === 0) {
      UserDocumentApi.getUserDocumentTypes().then((res) => {
        setDocumentTypes(res);
        setUserDocument({
          ...userDocument,
          parts: [
            {
              personId: "",
              userDocumentType: "",
            },
          ],
        });
      });
    }
  }, []);

  const classes = useStyles();

  return (
    <>
      {userDocument.parts.map((part, partIndex) => (
        <div className={classes.documentPart} key={partIndex}>
          <div className={classes.inputFieldRow}>
            <DropdownWithTooltip
              label={`Type of document`}
              value={userDocument.parts[partIndex].userDocumentType}
              values={documentTypes}
              onChange={(value) =>
                setUserDocument({
                  ...userDocument,
                  parts: userDocument.parts.map((part, index) => {
                    if (index === partIndex) {
                      part.userDocumentType = value;
                    }
                    return part;
                  }),
                })
              }
            />
          </div>
        </div>
      ))}
      {currentFile ? (
        <div style={{ position: "relative" }}>
          {currentFile.name
            .substr(currentFile.name.lastIndexOf(".") + 1)
            .toLowerCase() === "pdf" ? (
            <object
              className={
                isMobileDevice
                  ? classes.previewUploadOnMobile
                  : classes.previewUploadOnDesktop
              }
              type="application/pdf"
              data={imageAsURL}
            >
              <div className={classes.pdfUnsupportedMessage}>
                This browser does not support previewing PDF files.
              </div>
            </object>
          ) : (
            <div>
              <img
                style={{ zIndex: -1 }}
                className={
                  isMobileDevice
                    ? classes.previewUploadOnMobile
                    : classes.previewUploadOnDesktop
                }
                src={imageAsURL}
                alt="did not load"
              />
            </div>
          )}
          <Button
            variant="contained"
            className={`${classes.trashButton} ${classes.moveUpToOverlap}`}
            onClick={() => {
              setCurrentFile(undefined);
              setUserDocument({ ...userDocument, base64EncodedFile: "" });
            }}
          >
            <Delete />
          </Button>
        </div>
      ) : (
        <div
          className={classes.uploadBlock}
          onClick={(event) => document.getElementById(uploadID)?.click()}
        >
          <Typography variant="subtitle2">
            <u>Click to upload document</u>
            <input
              type="file"
              id={uploadID}
              accept="image/*,.pdf"
              hidden
              onChange={(event) => {
                let file: File | undefined | null = event.target.files?.item(0);
                if (file) {
                  var reader: FileReader = new FileReader();

                  reader.onload = function () {
                    const csv: string = reader.result as string;
                    setUserDocument({
                      ...userDocument,
                      parts: userDocument.parts.map((part, index) => {
                        if (index === 0) {
                          part.personId = userId;
                        }
                        return part;
                      }),
                      base64EncodedFile: csv,
                    });
                    setCurrentFile(file!);
                    if (
                      file!.type.startsWith("image") ||
                      file!.type.endsWith("pdf")
                    ) {
                      if (file) {
                        setImageAsURL(URL.createObjectURL(file));
                      }
                      /*      validator.validate(
                           "base64EncodedFile",
                           getValidationMessageForDocument("base64EncodedFile"),
                           getValidationFunctionForDocument("base64EncodedFile"),
                           csv
                         );
                         */
                    }
                  };

                  reader.readAsDataURL(file!);
                } else {
                  showError("You did not upload a file");
                }
              }}
            ></input>
          </Typography>
          <div>All image formats or PDF</div>
        </div>
      )}

      <Button
        disabled={submitting}
        className={classes.submitButton}
        onClick={uploadDocument}
        fullWidth
      >
        Upload
      </Button>
      <ListItem></ListItem>
      <ListItem>
        <Typography variant="h6">{`DOCUMENTS`} </Typography>
      </ListItem>
      {loadingDocumentMenu ? (
        <div className={classes.spinnerContainer}>
          <CircularProgress disableShrink className={classes.spinner} />
        </div>
      ) : (
        <DocumentTable
          aUser={aUser}
          aUsers={users}
          aDocuments={undeletedUserDocuments}
          aTableType={tableType}
          aListRef={listRef}
          aPreviousScrollDiff={previousScrollDiff}
        />
      )}
    </>
  );
};

export default UserDocuments;
