import React, { useContext, useEffect, useRef, useState } from "react";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  FormControlLabel,
  makeStyles,
  Tab,
  Tabs,
  Typography,
  TextField,
  ListItem,
} from "@material-ui/core";

import {
  FieldValidator,
  isNotEmpty,
  isNotZero,
  useValidator,
} from "../hooks/validator";
import { Dropdown } from "../component/Dropdown";
import { Delete } from "@material-ui/icons";
import { SnackbarContext } from "../component/SnackbarProvider";
import { AmountInput } from "../component/AmountInput";
import DeclarationApi, {
  CreateDeclaration,
  DeclarationDataItem,
} from "../data/declaration";
import {
  DeclarationTable,
  TableTypes,
} from "../component/Declarations/DeclarationTable";
import UserApi, { UserItem } from "../data/user";
import { Alert } from "@material-ui/lab";
import { HttpError } from "../component/HttpError";
import { useSelector } from "react-redux";
import { Account } from "../data/principal";
import { RootState } from "../redux/reducers";
import { isSafari } from "react-device-detect";

const mandatoryDeclarationFields: (keyof CreateDeclaration)[] = [
  "base64EncodedFile",
  "parts",
];

const getValidationMessage = (field: keyof CreateDeclaration): string => {
  switch (field) {
    case "base64EncodedFile": {
      return "Please select a receipt as proof of payment";
    }
    case "parts": {
      return "Amount(s) entered must be non-zero";
    }
  }
  return "";
};

const getValidationFunction = (
  field: keyof CreateDeclaration
): ((value: any) => boolean) => {
  switch (field) {
    case "base64EncodedFile":
      return isNotEmpty;
    case "parts":
      return isNotZero;
  }
  return () => false;
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel = (props: TabPanelProps): JSX.Element => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};

const a11yProps = (index: any): {} => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const useStyles = makeStyles((theme) => ({
  amountInput: {
    width: "40%",
    marginRight: "5px",
  },
  submitButton: {
    backgroundColor: "#000000",
    color: "#FFFFFF",
    marginTop: "20px",
    "&:disabled": {
      backgroundColor: "#FFFFFF",
    },
  },
  declarationPart: {
    marginTop: "30px",
  },
  horizontalChildren: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
  },
  indicator: {
    backgroundColor: "#0081d7",
  },
  inactiveTab: {
    "&:checked": { backgroundColor: "green" },
    "&:hover": {
      backgroundColor: "#cccccc",
      color: "black !important",
    },
  },
  inputFieldRow: {
    marginTop: "30px",
    marginBottom: "10px",
  },
  moveUpToOverlap: {
    position: "absolute",
    bottom: 15,
    right: 15,
    marginTop: "-35px",
  },
  pdfUnsupportedMessage: {
    height: "80px",
  },
  previewUploadOnDesktop: {
    minWidth: window.innerWidth * 0.475,
    maxWidth: window.innerWidth * 0.475,
    paddingBottom: "10px",
  },
  previewUploadOnMobile: {
    minWidth: window.innerWidth * 0.9,
    maxWidth: window.innerWidth * 0.9,
    paddingBottom: "10px",
  },
  spinner: {
    marginLeft: "50%",
  },
  spinnerContainer: {
    position: "relative",
  },
  tab: {
    color: "#0081d7",
  },
  trashButton: {
    float: "right",
    cursor: "pointer",
  },
  uploadBlock: {
    border: "2px dashed #bbb",
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    height: "45px",
    marginTop: "20px",
    paddingTop: "10px",
    paddingBottom: "55px",
    textAlign: "center",
    "&:hover": { cursor: "pointer" },
  },
  whiteToBlackButton: {
    float: "right",
    marginTop: "10px",
    "&:hover": {
      backgroundColor: "#000000",
      color: "#FFFFFF",
    },
  },
}));

const maxWidthForMobileInPixels = 1224;

export const Declaration = () => {
  let ref = useRef(0);
  const account = useSelector<RootState>(
    (state) => state.auth.currentAccount
  ) as Account | null;

  const classes = useStyles();

  const { showInfo, showError } = useContext(SnackbarContext);
  const [declaration, setDeclaration] = useState<CreateDeclaration>({
    reimburse: true,
    remark: "",
    parts: [{ amount: "", vatType: "", declarationType: "" }],
    base64EncodedFile: "",
  });
  const [loadingNewDeclarationMenu, setLoadingNewDeclarationMenu] =
    useState(true);
  const [loadingSecondDeclarationMenu, setLoadingSecondDeclarationMenu] =
    useState(true);

  const [currentFile, setCurrentFile] = useState<File | undefined>(undefined);
  const validator = useValidator<CreateDeclaration>(
    declaration,
    mandatoryDeclarationFields
  );
  const [imageAsURL, setImageAsURL] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [showHttpError, setShowHttpError] = useState(false);
  const [retryAction, setRetryAction] = useState<() => void>();
  const [VATTypes, setVatTypes] = useState<{ [key: string]: string }>({});
  const [declarationTypes, setDeclarationTypes] = useState<{
    [key: string]: string;
  }>({});

  const [validationErrorVisible, setValidationErrorVisible] = useState(false);

  const [tabNr, setTabNr] = useState(0);

  const getClassForTab = (index: number): string =>
    index === tabNr ? classes.tab : classes.inactiveTab;

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabNr(newValue);
    if (newValue === 1) {
      setLoadingSecondDeclarationMenu(true);
      DeclarationApi.getUserDeclarations().then((res) => {
        setDeclarations(res);
        setLoadingSecondDeclarationMenu(false);
      });
    }
  };

  const [users, setUsers] = useState<UserItem[]>([]);
  const [declarations, setDeclarations] = useState<DeclarationDataItem[]>([]);
  const [loadingDeclarations, setLoadingDeclarations] = useState(true);
  const [loadingUsers, setLoadingUsers] = useState(true);

  useEffect(() => {
    if (declarations.length > 0) {
      setLoadingDeclarations(false);
    }
  }, [declarations]);

  useEffect(() => {
    if (Object.entries(VATTypes).length === 0) {
      DeclarationApi.getVatTypes().then((res) => {
        setVatTypes(res);
        setDeclaration({
          ...declaration,
          parts: [
            {
              amount: "",
              vatType: "",
              declarationType: "",
            },
          ],
        });
      });
      if (Object.entries(declarationTypes).length > 0) {
        setLoadingNewDeclarationMenu(false);
      }
    }
  }, [declaration, declarationTypes, VATTypes]);

  useEffect(() => {
    if (Object.entries(declarationTypes).length === 0) {
      DeclarationApi.getDeclarationTypes().then((res) => {
        setDeclarationTypes(res);
        setDeclaration({
          ...declaration,
          parts: [
            {
              amount: "",
              vatType: "",
              declarationType: "",
            },
          ],
        });
      });
      if (Object.entries(VATTypes).length > 0) {
        setLoadingNewDeclarationMenu(false);
      }
    }
  }, [declaration, declarationTypes, VATTypes]);

  useEffect(() => {
    if (loadingUsers) {
      if (account) {
        UserApi.getUser(account.id).then((user) => {
          setUsers([user]);
          setLoadingUsers(false);
        });
      }
    }
  }, [loadingUsers, account]);

  useEffect(() => {
    if (!loadingUsers && !loadingDeclarations) {
      setLoadingSecondDeclarationMenu(false);
    }
  }, [loadingDeclarations, loadingUsers, loadingSecondDeclarationMenu]);

  const VatAfterSplit = (wantToSplit: boolean) => {
    if (wantToSplit) {
      const VATTypes: { [key: string]: string } = {
        "Excludes 9% VAT": "Excludes 9% VAT",
        "Excludes 21% VAT": "Excludes 21% VAT",
        "Includes 9% VAT": "Includes 9% VAT",
        "Includes 21% VAT": "Includes 21% VAT",
      };
      setVatTypes(VATTypes);
    } else {
      DeclarationApi.getVatTypes().then((res) => {
        setVatTypes(res);
      });
    }
  };

  let isMobileDevice: boolean = useMediaQuery(
    `(max-width:${maxWidthForMobileInPixels}px)`
  );
  let orientation: string = "";

  if (useMediaQuery(`(orientation:'portrait')`)) {
    orientation = "portrait";
  }
  if (useMediaQuery(`(orientation:'landscape')`)) {
    orientation = "landscape";
  }

  if (false)
    return (
      <div>
        <div>{`mobile?: ${isMobileDevice}`}</div>
        <div>{`orientation?: ${orientation}`}</div>
      </div>
    );

  const uploadID = "UPLOAD";

  const uploadDeclaration = (): void => {
    setSubmitting(true);

    validator.validateMultiple(
      [
        {
          field: "parts",
          message: getValidationMessage("parts"),
          valid: getValidationFunction("parts"),
          value: declaration.parts[0].amount,
        } as FieldValidator<CreateDeclaration>,
        {
          field: "base64EncodedFile",
          message: getValidationMessage("base64EncodedFile"),
          valid: getValidationFunction("base64EncodedFile"),
          value: declaration.base64EncodedFile,
        } as FieldValidator<CreateDeclaration>,
      ].concat(
        declaration.parts.length > 1
          ? [
              {
                field: "parts",
                message: getValidationMessage("parts"),
                valid: getValidationFunction("parts"),
                value: declaration.parts[1].amount,
              } as FieldValidator<CreateDeclaration>,
            ]
          : []
      )
    );

    if (!validator.isValid) {
      setValidationErrorVisible(true);
      window.scrollTo(0, 0);
      setSubmitting(false);
      return;
    }

    setValidationErrorVisible(false);

    let isPartsValid = true;

    declaration.parts.forEach((part) => {
      if (part.declarationType === "" || part.vatType === "") {
        showError("Please Fill in all the fields");
        isPartsValid = false;
      }
    });

    if (isPartsValid) {
      let action = () => {
        setShowHttpError(false);
        setSubmitting(true);
        DeclarationApi.createDeclaration(declaration).then(
          () => {
            showInfo("Declaration was uploaded");
            setCurrentFile(undefined);
            setImageAsURL("");
            setDeclaration({
              reimburse: true,
              remark: "",
              parts: [
                {
                  amount: "",
                  vatType: "",
                  declarationType: "",
                },
              ],
              base64EncodedFile: "",
            });
            setSubmitting(false);
          },
          (err) => {
            if (err.message !== "Network Error") {
              showError("Failed to upload declaration");
            } else {
              setShowHttpError(true);
            }
            setSubmitting(false);
          }
        );
      };
      setRetryAction(() => action);
      action();
    } else {
      setSubmitting(false);
    }
  };
  var pdfPreview;
  if (!isSafari) {
    pdfPreview = (
      <object
        className={
          isMobileDevice
            ? classes.previewUploadOnMobile
            : classes.previewUploadOnDesktop
        }
        type="application/pdf"
        data={imageAsURL}
      >
        {" "}
        <div className={classes.pdfUnsupportedMessage}>
          {" "}
          This browser does not support previewing PDF files.
        </div>{" "}
      </object>
    );
  } else {
    pdfPreview = (
      <object
        className={
          isMobileDevice
            ? classes.previewUploadOnMobile
            : classes.previewUploadOnDesktop
        }
        data={imageAsURL}
      >
        {" "}
        <div className={classes.pdfUnsupportedMessage}>
          {" "}
          This browser does not support previewing PDF files.
        </div>{" "}
      </object>
    );
  }


  return (
    <div>
      <HttpError
        open={showHttpError}
        close={() => setShowHttpError(false)}
        retry={() => {
          retryAction && retryAction();
        }}
      />
      <Tabs
        value={tabNr}
        onChange={handleChange}
        aria-label="simple tabs example"
        classes={{ indicator: classes.indicator }}
      >
        <Tab label="NEW" {...a11yProps(0)} className={getClassForTab(0)} />
        <Tab label="HISTORY" {...a11yProps(1)} className={getClassForTab(1)} />
      </Tabs>
      <TabPanel value={tabNr} index={0}>
        {submitting || loadingNewDeclarationMenu ? (
          <div className={classes.spinnerContainer}>
            <CircularProgress disableShrink className={classes.spinner} />
          </div>
        ) : (
          <div>
            {validationErrorVisible && (
              <Alert
                severity="error"
                onClose={() => setValidationErrorVisible(false)}
              >
                Please correct the errors in your form and submit again.
              </Alert>
            )}
            {currentFile ? (
              <div style={{ position: "relative" }}>
                {currentFile.name
                  .substr(currentFile.name.lastIndexOf(".") + 1)
                  .toLowerCase() === "pdf" ? (
                  pdfPreview
                ) : (
                  <div>
                    <img
                      style={{ zIndex: -1 }}
                      className={
                        isMobileDevice
                          ? classes.previewUploadOnMobile
                          : classes.previewUploadOnDesktop
                      }
                      src={imageAsURL}
                      alt="did not load"
                    />
                  </div>
                )}
                <Button
                  variant="contained"
                  className={`${classes.trashButton} ${classes.moveUpToOverlap}`}
                  onClick={() => {
                    setCurrentFile(undefined);
                    setDeclaration({ ...declaration, base64EncodedFile: "" });
                  }}
                >
                  <Delete />
                </Button>
              </div>
            ) : (
              <div
                className={classes.uploadBlock}
                onClick={(event) => document.getElementById(uploadID)?.click()}
              >
                <Typography variant="subtitle2">
                  <u>Click to upload receipt</u>
                  <input
                    type="file"
                    id={uploadID}
                    accept="image/*,.pdf"
                    hidden
                    onChange={(event) => {
                      let file: File | undefined | null =
                        event.target.files?.item(0);
                      if (file) {
                        var reader: FileReader = new FileReader();

                        reader.onload = function () {
                          const csv: string = reader.result as string;
                          setDeclaration({
                            ...declaration,
                            base64EncodedFile: csv,
                          });
                          setCurrentFile(file!);
                          if (
                            file!.type.startsWith("image") ||
                            file!.type.endsWith("pdf")
                          ) {
                            if (file) {
                              setImageAsURL(URL.createObjectURL(file));
                            }
                            validator.validate(
                              "base64EncodedFile",
                              getValidationMessage("base64EncodedFile"),
                              getValidationFunction("base64EncodedFile"),
                              csv
                            );
                          }
                        };

                        reader.readAsDataURL(file!);
                      } else {
                        showError("You did not submit a file");
                      }
                    }}
                  ></input>
                </Typography>
                <div>All image formats or PDF</div>
              </div>
            )}

            {declaration.parts.map((part, partIndex) => (
              <div className={classes.declarationPart} key={partIndex}>
                <div className={classes.horizontalChildren}>
                  <AmountInput
                    id={`amount-declaration-part-${partIndex + 1}`}
                    label={`Amount`}
                    value={
                      declaration.parts[partIndex].amount
                        ?.split(".")
                        .join(",") ?? ""
                    }
                    error={validator.get("parts")}
                    onBlur={() =>
                      validator.validate(
                        "parts",
                        getValidationMessage("parts"),
                        getValidationFunction("parts"),
                        part.amount
                      )
                    }
                    onChange={(value: string) =>
                      setDeclaration({
                        ...declaration,
                        parts: declaration.parts.map((part, index) => {
                          if (index === partIndex) {
                            part.amount = value;
                          }
                          return part;
                        }),
                      })
                    }
                    prefix={`€`}
                    className={`${classes.amountInput}`}
                  />
                  <Dropdown
                    label={`VAT`}
                    value={declaration.parts[partIndex].vatType}
                    values={VATTypes}
                    onChange={(value) =>
                      setDeclaration({
                        ...declaration,
                        parts: declaration.parts.map((part, index) => {
                          if (index === partIndex) {
                            part.vatType = value;
                          }
                          return part;
                        }),
                      })
                    }
                    maxWidth={`60%`}
                  />
                </div>
                <div className={classes.inputFieldRow}>
                  <Dropdown
                    label={`Type of purchase`}
                    value={declaration.parts[partIndex].declarationType}
                    values={declarationTypes}
                    onChange={(value) =>
                      setDeclaration({
                        ...declaration,
                        parts: declaration.parts.map((part, index) => {
                          if (index === partIndex) {
                            part.declarationType = value;
                          }
                          return part;
                        }),
                      })
                    }
                  />
                </div>
              </div>
            ))}
            {declaration.parts.length === 1 ? (
              <Button
                className={classes.whiteToBlackButton}
                onClick={() => {
                  VatAfterSplit(true);
                  setDeclaration({
                    ...declaration,
                    parts: declaration.parts
                      .concat({
                        amount: "",
                        vatType: "",
                        declarationType: "",
                      })
                      .map((part, index) => {
                        if (index === 0) {
                          part.amount = "";
                        }
                        return part;
                      }),
                  });
                  validator.validate(
                    "parts",
                    getValidationMessage("parts"),
                    getValidationFunction("parts"),
                    0
                  );
                }}
              >
                Split vat
              </Button>
            ) : undefined}
            <Collapse in={declaration.parts.length === 2}>
              <Delete
                className={classes.trashButton}
                onClick={() => {
                  VatAfterSplit(false);
                  setDeclaration({
                    ...declaration,
                    parts: declaration.parts.slice(0, 1),
                  });
                }}
              />
            </Collapse>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!declaration.reimburse}
                  onChange={(event, dontReimburse) => {
                    let reimburse: boolean = !dontReimburse;
                    setDeclaration({ ...declaration, reimburse });
                  }}
                />
              }
              label="Don't reimburse me, I used a business account"
            />
            <ListItem></ListItem>
            <TextField
              id="declaration-remark"
              label="Remark (optional)"
              value={declaration.remark ?? ""}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setDeclaration({ ...declaration, remark: event.target.value })
              }
              multiline
              rows={4}
              placeholder="If you have additional background information on your declaration to support the approval, please comment here."
              fullWidth={true}
              margin="dense"
              variant="outlined"
            />
            <Button
              disabled={submitting}
              className={classes.submitButton}
              onClick={uploadDeclaration}
              fullWidth
            >
              Submit
            </Button>
          </div>
        )}
      </TabPanel>
      <TabPanel value={tabNr} index={1}>
        {loadingSecondDeclarationMenu ? (
          <div className={classes.spinnerContainer}>
            <CircularProgress disableShrink className={classes.spinner} />
          </div>
        ) : (
          <DeclarationTable
            aUsers={users}
            aDeclarations={declarations}
            aTableType={TableTypes.VIEWALL}
            aPreviousScrollDiff={ref}
          />
        )}
      </TabPanel>
    </div>
  );
};
