import { AddressType } from "./address";
import { getAxios } from "./axios";
import { ProjectType } from "./project";

interface CreateWeekReport {
  year: number;
  weekNumber: number;
}

export enum Status {
  Draft = "DRAFT",
  Submitted = "SUBMITTED",
  Approved = "APPROVED",
}

export interface TimesheetEntry {
  weekDay: number;
  projectId: string;
  hours: number;
}

export interface BusinessTrip {
  addressNameFrom: string | null;
  linkedPlaceIdFrom: string | null;
  linkedPlaceIdFromType: AddressType | null;
  addressNameTo: string | null;
  linkedPlaceIdTo: string | null;
  linkedPlaceIdToType: AddressType | null;
  placeIdFrom: string | null;
  placeIdTo: string | null;
  twoWay: boolean;
  frequency: number;
}

export interface WeekReport {
  weekReportId: string;
  status: Status;
  entries?: TimesheetEntry[];
  trips?: BusinessTrip[];
}

export interface WeekReportEntry {
  weekDay: number;
  project: string;
  projectType: ProjectType;
  hours: number;
}

export interface WeekReportTrip {
  frequency: number;
  from: string;
  to: string;
  twoWay: boolean;
  distance: number;
}

export interface WeekReportItem {
  id: string;
  year: number;
  weekNumber: number;
  userId: string;
  entries?: WeekReportEntry[];
  trips?: WeekReportTrip[];
  submitted: boolean;
  approved: boolean;
  sentToAdministration: boolean;
}

export interface WeekReportFilter {
  userId?: string;
  fromYear: number;
  fromWeekNumber: number;
  toYear: number;
  toWeekNumber: number;
}

export interface Leaderboard {
  TIMESHEET_TIGER: string;
  LAZY_LOGGER: string;
  SUBMIT_SLACKER: string;
}

export interface FailedUserMapItem {
  employeeID: string;
  fullName: string;
}

interface UpdateWeekReport {
  weekReportId: string;
  entries: TimesheetEntry[];
  trips: BusinessTrip[];
}

export interface SubmitWeekReport {
  weekReportId: string;
}

export interface WithdrawWeekReport {
  weekReportId: string;
}
export interface PrefillWeekReportResult {
  success?: boolean;
  message: string;
  weekNumber?: number;
}

export interface ExportParams {
  startWeek: number;
  startYear: number;
  endWeek?: number;
  endYear?: number;
  id?: string[];
}
export default class WeekReportApi {
  static async getWeekReports(
    filter?: WeekReportFilter
  ): Promise<WeekReportItem[]> {
    let response = await getAxios().get<WeekReportItem[]>(`/weekreport`, {
      params: filter || {},
    });
    return response.data;
  }

  static async getWeekReport(
    year: number,
    weekNumber: number
  ): Promise<WeekReport> {
    let response = await getAxios().get<WeekReport>(`/weekreport/getByWeek/`, {
      params: {
        year,
        weekNumber,
      },
    })
    return response.data;
  }

  static async getUserWeekReports(): Promise<WeekReportItem[]> {
    let response = await getAxios().get<WeekReportItem[]>(`/weekreport/me`);
    return response.data;
  }

  static async getLeaderboard(): Promise<Leaderboard> {
    let response = await getAxios().get<Leaderboard>(`/weekreport/leaderboard`);
    return response.data;
  }

  static async createWeekReport(payload: CreateWeekReport): Promise<string> {
    let response = await getAxios().post<string>(`/weekreport/create`, payload);
    return response.data;
  }

  static async updateWeekReport(payload: UpdateWeekReport): Promise<void> {
    console.log(payload.trips);
    let response = await getAxios().post<void>(`/weekreport/update`, payload);
    return response.data;
  }

  static async approveWeekReport(ids: string[]): Promise<void> {
    await getAxios().post<void>(`/weekreport/approve`, {
      weekReportIds: ids,
    });
  }

  static async revertWeekReport(ids: string[]): Promise<void> {
    await getAxios().post<void>(`/weekreport/revert`, {
      weekReportIds: ids,
    });
  }

  static async submitWeekReport(payload: SubmitWeekReport): Promise<void> {
    let response = await getAxios().post<void>(`/weekreport/submit`, payload);
    return response.data;
  }

  static async withdrawWeekReport(payload: WithdrawWeekReport): Promise<void> {
    let response = await getAxios().post<void>(`/weekreport/withdraw`, payload);
    return response.data;
  }

  static async prefillWeekReport(
    prefillId: string
  ): Promise<PrefillWeekReportResult> {
    let response = await getAxios().get<PrefillWeekReportResult>(
      `/weekreport/prefill/${prefillId}`
    );
    return response.data;
  }

  static async exportWeekReports(payload: ExportParams): Promise<Blob> {
    let response = await getAxios().get<Blob>(`/weekreport/export`, {
      params: payload,
    });
    return response.data;
  }

  static async exportTravelExpenses(payload: ExportParams): Promise<Blob> {
    let response = await getAxios().get<Blob>(`/weekreport/exportExpenses`, {
      params: payload,
    });
    return response.data;
  }
}
