import React from "react";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";

interface SelectedInputProps {
  label?: string;
  value: string | null;
  values: { [key: string]: string };
  onChange?: (value: string) => void;
  maxWidth?: string;
  small?: boolean;
  disabled?: boolean;
  onClose?: () => void;
  error?: string;
}

export const Dropdown = (props: SelectedInputProps) => (
  <FormControl
    variant="outlined"
    fullWidth
    size={props.small ? "small" : undefined}
    disabled={!props.onChange || props.disabled}
    style={{ maxWidth: props.maxWidth }}
    error={!!props.error}
  >
    {props.label && <InputLabel>{props.label}</InputLabel>}
    <Select
      value={props.value || ""}
      label={props.label}
      onChange={(event) =>
        props.onChange && props.onChange(event.target.value as string)
      }
      onClose={props.onClose}
    >
      {Object.keys(props.values).map((key) => (
        <MenuItem key={key} value={key}>
          {props.values[key]}
        </MenuItem>
      ))}
    </Select>
    <FormHelperText>{props.error}</FormHelperText>
  </FormControl>
);
