import EventEmitter from "../EventEmitter";
// import {UserStore} from "../../stores/UserStore";
// import { action, makeObservable, observable } from "mobx";
import Logger from "../Logger";
import RestClient from "./RestClient";
// import config from "../../../config";
import {
  noAuthAxios,
  quadAxios,
  Token,
  TokenDto,
} from "../../data/authentication";
import PrincipalApi from "../../data/principal";
import config from "../../config";
import { AxiosProgressEvent } from "axios";

export class QuadClient extends EventEmitter {
  restClient: RestClient;
  // readonly users: Map<string, APIUser> = new Map<string, APIUser>();
  // rooms: Map<string, APIRoom> = new Map<string, APIRoom>();
  connected: boolean = false;
  userId?: string;
  // appData?: AppData = undefined;
  errors: any[];
  // userStore: UserStore;

  private logger = new Logger("QuadClient");

  // private readonly messages: Map<string, Message> = new Map<string, Message>();

  constructor() {
    super();
    // this.connect = this.connect.bind(this);
    this.restClient = new RestClient({
      client: this,
      apiUrl: config.apiUrl,
      name: "shadow",
    });
    this.connected = false;
    this.errors = [];

    // makeObservable(this, {
    //   joinedRoomId: observable,
    //   errors: observable,
    //   userId: observable,
    //   appData: observable,
    //   connected: observable,
    //   onGatewayMessage: action,
    //   connect: action,
    // });
  }

  async logout() {
    config.apiToken = undefined;

    const debug = localStorage.getItem("debug");
    const devAuthEnabled = config.devAuthEnabled;
    localStorage.clear();
    config.devAuthEnabled = devAuthEnabled;

    //used by the debug logging library
    if (debug) {
      localStorage.setItem("debug", debug);
    }

    // await this.disconnect();
  }

  getClientUser() {
    // return this.userId && this.userStore.get(this.userId);
  }

  post(
    route: string,
    data?: any,
    progressCallback?: (data: AxiosProgressEvent) => any
  ) {
    return this.restClient.post(route, data, { progressCallback });
  }

  get(route: string, data?: any) {
    return this.restClient.get(route, data);
  }

  put(
    route: string,
    data?: any,
    progressCallback?: (data: AxiosProgressEvent) => any
  ) {
    return this.restClient.put(route, data, { progressCallback });
  }

  patch(
    route: string,
    data?: any,
    progressCallback?: (data: AxiosProgressEvent) => any
  ) {
    return this.restClient.patch(route, data, { progressCallback });
  }

  delete<T>(route: string, data?: any) {
    return this.restClient.delete<T>(route, data);
  }

  async login(
    email: string | null,
    password: string | null,
    loginToken?: string | null
  ) {
    let response = await noAuthAxios
      .post<TokenDto>(`/login`, {
        email,
        password,
        token: loginToken,
      })
      .catch((error) => {
        const dataMessage = error.response?.data?.message;

        if (dataMessage) {
          throw new Error(dataMessage);
        }

        throw error;
      });

    let accessToken = response.data.access_token;

    if (config.debugAltApi) {
      let response1 = await quadAxios.post<TokenDto>(`/login`, {
        email,
        username: email,
        password,
        token: loginToken,
      });

      accessToken = response1.data.access_token;
    }

    const token: Token = {
      idToken: response.data.access_token,
      accessToken,
      refreshToken: response.data.refresh_token,
      tokenType: response.data.token_type,
      expiresIn: response.data.expires_in,
    };

    config.oauthToken = token;

    const user = await PrincipalApi.getAccount();
    return {
      token,
      user,
    };
  }

  async deleteUser(userId: string): Promise<void> {
    await this.delete<void>(`/deleteUser/${userId}`);
  }
}

const quadClient = new QuadClient();
export default quadClient;
