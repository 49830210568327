import React, { FunctionComponent } from "react";
import { Typography } from "@material-ui/core";

export const Section: FunctionComponent<{}> = (props) => (
  <Typography
    variant="subtitle1"
    style={{ fontWeight: "bold", textTransform: "uppercase" }}
  >
    {props.children}
  </Typography>
);

export const Subsection: FunctionComponent<{}> = (props) => (
  <Typography variant="subtitle1" style={{ textTransform: "uppercase" }}>
    {props.children}
  </Typography>
);
