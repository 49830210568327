import React, { useEffect, useState } from "react";
import { Autocomplete } from "@material-ui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import { useDebounce } from "../hooks/debounce";

import GoogleMapsApi, { Prediction } from "../data/googleMaps";

type GoogleMapsPlacefinderProps = React.HTMLAttributes<HTMLDivElement> & {
  label: string;
  sessionToken: string;
  value?: Prediction;
  disabled?: boolean;
  error?: string;

  onSelectPrediction: (
    value: Prediction | null,
    event: React.ChangeEvent<{}>
  ) => void;
};

export const GoogleMapsPlacefinder = (props: GoogleMapsPlacefinderProps) => {
  const [isSearching, setIsSearching] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>(
    props.value?.description ?? ""
  );
  const debouncedSearchTerm = useDebounce<string>(searchTerm, 500);

  const [addressList, setAddressList] = useState<Prediction[]>([]);

  const [showGoogleError, setShowGoogleError] = useState(false);

  useEffect(() => {
    if (debouncedSearchTerm) {
      setIsSearching(true);
      GoogleMapsApi.getAddressSuggestions(
        debouncedSearchTerm,
        props.sessionToken
      )
        .then((res) => setAddressList(res))
        .catch(() => setShowGoogleError(true))
        .finally(() => setIsSearching(false));
    }
  }, [debouncedSearchTerm, props.sessionToken]);

  return (
    <React.Fragment>
      <Dialog open={showGoogleError ?? false}>
        <DialogTitle>GOOGLE MAPS UNREACHABLE</DialogTitle>
        <DialogContent>
          Address lookup failed because the Google Maps service is offline,
          please try again later.
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            color="primary"
            onClick={() => setShowGoogleError(false)}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Autocomplete
        id="google-address-autocomplete"
        disabled={props.disabled}
        fullWidth={true}
        getOptionLabel={(prediction) => prediction.description ?? ""}
        getOptionSelected={(prediction) => addressList.includes(prediction)}
        onChange={(ev, value) => props.onSelectPrediction(value, ev)}
        options={addressList}
        loading={isSearching}
        renderInput={(params) => (
          <TextField
            {...params}
            label={props.label}
            variant="outlined"
            error={!!props.error}
            helperText={props.error}
            onChange={(ev) => setSearchTerm(ev.target.value)}
          />
        )}
        value={props.value}
        onBlur={props.onBlur}
      ></Autocomplete>
    </React.Fragment>
  );
};
