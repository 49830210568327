import React, { useEffect, useRef, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import DeclarationApi, { DeclarationDataItem } from "../../data/declaration";
import { CircularProgress, Grid } from "@material-ui/core";
import UserApi, { SimpleUserItem } from "../../data/user";
import {
  DeclarationTable,
  TableTypes,
} from "../../component/Declarations/DeclarationTable";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const useStyles = makeStyles((theme: Theme) => ({
  indicator: {
    backgroundColor: "#0081d7",
  },
  inactiveTab: {
    "&:checked": { backgroundColor: "green" },
    "&:hover": {
      backgroundColor: "#cccccc",
      color: "black !important",
    },
  },
  spinner: {
    marginLeft: "50%",
  },
  spinnerContainer: {
    position: "relative",
  },
  tab: {
    color: "#0081d7",
  },
}));

const TabPanel = (props: TabPanelProps): JSX.Element => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};

const a11yProps = (index: any): {} => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

export const ReviewDeclarations = () => {
  // Clicking a checkbox re-renders the table causing the scrollbar to move up.
  // listRef stores the reference to the list of declarations, previousScrollDiff
  // contains the location to scroll back to on re-render.
  const listRef = useRef<HTMLDivElement>(null);
  const previousScrollDiff = useRef(0);

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTop =
        listRef.current.scrollHeight - previousScrollDiff.current;
    }
  });

  const classes = useStyles();

  const [unapprovedDeclarations, setUnapprovedDeclarations] = useState<
    DeclarationDataItem[]
  >([]);

  const [processedDeclarations, setProcessedDeclarations] = useState<
    DeclarationDataItem[]
  >([]);

  const [users, setUsers] = useState<SimpleUserItem[]>([]);

  const [tabNr, setTabNr] = useState(0);

  const [tableType, setTableType] = useState<TableTypes>(TableTypes.PENDING);

  const [loadingUnapproveddeclarations, setLoadingUnapproveddeclarations] =
    useState(true);
  const [loadingProcesseddeclarations, setLoadingProcesseddeclarations] =
    useState(true);
  const [loadingUsers, setLoadingUsers] = useState(true);

  useEffect(() => {
    DeclarationApi.getUnapprovedDeclarations().then((res) => {
      setUnapprovedDeclarations(res);
      setLoadingUnapproveddeclarations(false);
    });
    UserApi.getUsers().then((users) => {
      setUsers(users);
      setLoadingUsers(false);
    });
  }, []);

  const getClassForTab = (index: number): string =>
    index === tabNr ? classes.tab : classes.inactiveTab;

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabNr(newValue);
    let newTableType: TableTypes =
      newValue === 0 ? TableTypes.PENDING : TableTypes.PROCESSED;
    setTableType(newTableType);
    if (newTableType === TableTypes.PENDING) {
      DeclarationApi.getUnapprovedDeclarations().then((res) => {
        setUnapprovedDeclarations(res);
        setLoadingUnapproveddeclarations(false);
      });
      setLoadingProcesseddeclarations(true);
    }
    if (newTableType === TableTypes.PROCESSED) {
      DeclarationApi.getProcessedDeclarations().then((res) => {
        setProcessedDeclarations(res);
        setLoadingProcesseddeclarations(false);
      });
      setLoadingUnapproveddeclarations(true);
    }
  };

  return (tableType === TableTypes.PENDING && loadingUnapproveddeclarations) ||
    (tableType === TableTypes.PROCESSED && loadingProcesseddeclarations) ||
    loadingUsers ? (
    <div className={classes.spinnerContainer}>
      <CircularProgress disableShrink className={classes.spinner} />
    </div>
  ) : (
    <Box>
      <Grid container item sm={12}>
        <Tabs
          value={tabNr}
          onChange={handleChange}
          aria-label="simple tabs example"
          classes={{ indicator: classes.indicator }}
        >
          <Tab
            label="Pending"
            {...a11yProps(0)}
            className={getClassForTab(0)}
          />
          <Tab
            label="Processed"
            {...a11yProps(1)}
            className={getClassForTab(1)}
          />
        </Tabs>
      </Grid>
      <TabPanel value={tabNr} index={0}>
        <DeclarationTable
          aUsers={users}
          aDeclarations={unapprovedDeclarations}
          aTableType={tableType}
          aListRef={listRef}
          aPreviousScrollDiff={previousScrollDiff}
        />
      </TabPanel>
      <TabPanel value={tabNr} index={1}>
        <DeclarationTable
          aUsers={users}
          aDeclarations={processedDeclarations}
          aTableType={tableType}
          aListRef={listRef}
          aPreviousScrollDiff={previousScrollDiff}
        />
      </TabPanel>
    </Box>
  );
};
