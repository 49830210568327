import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  FormControlLabel,
  makeStyles,
  Typography,
  TextField,
  ListItem,
} from "@material-ui/core";
import { Dropdown } from "../component/Dropdown";
import { Delete } from "@material-ui/icons";
import { SnackbarContext } from "../component/SnackbarProvider";
import { AmountInput } from "../component/AmountInput";
import DeclarationApi, {
  UpdateDeclarationInterface,
  DeclarationDataItem,
} from "../data/declaration";
import { Alert } from "@material-ui/lab";
import { isSafari } from "react-device-detect";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Account } from "../data/principal";
import { RootState } from "../redux/reducers";

const useStyles = makeStyles((theme) => ({
  amountInput: {
    width: "40%",
    marginRight: "5px",
  },
  submitButton: {
    backgroundColor: "#000000",
    color: "#FFFFFF",
    marginTop: "20px",
    "&:disabled": {
      backgroundColor: "#FFFFFF",
    },
  },
  declarationPart: {
    marginTop: "30px",
  },
  horizontalChildren: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
  },
  indicator: {
    backgroundColor: "#0081d7",
  },
  inactiveTab: {
    "&:checked": { backgroundColor: "green" },
    "&:hover": {
      backgroundColor: "#cccccc",
      color: "black !important",
    },
  },
  attachmentButton: {
    WebkitTransform: "rotate(45deg)",
    msTransform: "rotate(45deg)",
    transform: "rotate(45deg)",
    cursor: "pointer",
    opacity: "50%",
    "&:hover": {
      opacity: "100%",
    },
  },
  inputFieldRow: {
    marginTop: "30px",
    marginBottom: "10px",
  },
  moveUpToOverlap: {
    position: "absolute",
    bottom: 15,
    right: 15,
    marginTop: "-35px",
  },
  pdfUnsupportedMessage: {
    height: "80px",
  },
  previewUploadOnDesktop: {
    minWidth: window.innerWidth * 0.475,
    maxWidth: window.innerWidth * 0.475,
    paddingBottom: "10px",
  },
  previewUploadOnMobile: {
    minWidth: window.innerWidth * 0.9,
    maxWidth: window.innerWidth * 0.9,
    paddingBottom: "10px",
  },
  spinner: {
    marginLeft: "50%",
  },
  spinnerContainer: {
    position: "relative",
  },
  tab: {
    color: "#0081d7",
  },
  trashButton: {
    float: "right",
    cursor: "pointer",
  },
  uploadBlock: {
    border: "2px dashed #bbb",
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    height: "45px",
    marginTop: "20px",
    paddingTop: "10px",
    paddingBottom: "55px",
    textAlign: "center",
    "&:hover": { cursor: "pointer" },
  },
  whiteToBlackButton: {
    float: "right",
    marginTop: "10px",
    "&:hover": {
      backgroundColor: "#000000",
      color: "#FFFFFF",
    },
  },
}));

interface partsObject {
  amount: number;
  vatType: string;
  declarationType: string;
}

interface DeclarationObject {
  declarationId: string;
  parts: partsObject[];
  reimburse: boolean;
  remark: string;
  status: string;
  userId: string;
  base64EncodedFile: string;
}

const totalAmount = (amount: number, vat: number, vatType: string): number => {
  if (
    vatType === "Includes 21% VAT" ||
    vatType === "Includes 9% VAT" ||
    vatType === "0% Tariff (EU)" ||
    vatType === "0% Tariff (Non-EU)"
  ) {
    return amount + vat;
  }
  if (vatType === "9% VAT" || vatType === "21% VAT") {
    return vat;
  }
  return amount;
};

const mapDeclarationObject = (
  declaration: DeclarationDataItem
): DeclarationObject => {
  const partsObj = declaration.parts.map((part) => {
    return {
      amount: totalAmount(part.amount, part.vatAmount, part.vatType),
      vatType: part.vatType,
      declarationType: part.declarationType,
    };
  });

  return {
    declarationId: declaration.id,
    parts: partsObj,
    reimburse: declaration.reimburse,
    remark: declaration.remark,
    status: declaration.status,
    userId: declaration.userId,
    base64EncodedFile: "",
  };
};

const maxWidthForMobileInPixels = 1224;

export const UpdateDeclaration = () => {
  const classes = useStyles();
  let history = useHistory();
  const params = useParams();

  const declarationJson = JSON.parse(JSON.stringify(params));

  const { showInfo, showError } = useContext(SnackbarContext);
  const [declarationById, setDeclarationById] = useState<DeclarationObject>();
  const [googleFileId, setGoogleFileId] = useState("");
  const [loadingNewDeclarationMenu, setLoadingNewDeclarationMenu] =
    useState(true);

  const [imageAsURL, setImageAsURL] = useState<string>("");
  const [vatAmounts, setVatAmounts] = useState<number[]>();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [VATTypes, setVatTypes] = useState<{ [key: string]: string }>({});
  const [declarationTypes, setDeclarationTypes] = useState<{
    [key: string]: string;
  }>({});

  const account = useSelector<RootState>(
    (state) => state.auth.currentAccount
  ) as Account | null;

  const [validationErrorVisible, setValidationErrorVisible] = useState(false);

  useEffect(() => {
    if (Object.entries(VATTypes).length === 0) {
      DeclarationApi.getVatTypes().then((res) => {
        checkPreviousDeclaractionVatTypes(res)
      });
      if (Object.entries(declarationTypes).length > 0) {
        setLoadingNewDeclarationMenu(false);
      }
    }
  // eslint-disable-next-line
  }, [declarationTypes, VATTypes, declarationById]);

  useEffect(() => {
    DeclarationApi.getDeclarationById(declarationJson.id)
      .then((res) => {
        setDeclarationById(mapDeclarationObject(res));
        const vatAmountsObj = res.parts.map((part) => {
          return part.vatAmount;
        });
        setVatAmounts(vatAmountsObj);
      })
      .catch((err) => console.log(err));

    DeclarationApi.getAttachmentDownloadUrl(declarationJson.id)
      .then((res) => {
        setGoogleFileId(res);
      })
      .catch((err) => console.log(err));
  }, [declarationJson.id]);

  useEffect(() => {
    if (Object.entries(declarationTypes).length === 0) {
      DeclarationApi.getDeclarationTypes().then((res) => {
        setDeclarationTypes(res);
      });
      if (Object.entries(VATTypes).length > 0) {
        setLoadingNewDeclarationMenu(false);
      }
    }
  }, [declarationTypes, VATTypes]);

  let isMobileDevice: boolean = useMediaQuery(
    `(max-width:${maxWidthForMobileInPixels}px)`
  );
  let orientation: string = "";

  if (useMediaQuery(`(orientation:'portrait')`)) {
    orientation = "portrait";
  }
  if (useMediaQuery(`(orientation:'landscape')`)) {
    orientation = "landscape";
  }

  if (false)
    return (
      <div>
        <div>{`mobile?: ${isMobileDevice}`}</div>
        <div>{`orientation?: ${orientation}`}</div>
      </div>
    );

  const uploadID = "UPLOAD";

  const uploadDeclaration = (): void => {
    setSubmitting(true);
    if (declarationById === undefined) {
      return;
    }

    const partObj = declarationById.parts.map((part) => {
      return {
        amount: part.amount.toString(),
        vatType: part.vatType,
        declarationType: part.declarationType,
      };
    });

    let obj: UpdateDeclarationInterface = {
      declarationId: declarationById.declarationId,
      googleFileId: googleFileId,
      reimburse: declarationById.reimburse,
      remark: declarationById.remark,
      parts: partObj,
      base64EncodedFile: declarationById.base64EncodedFile,
    };

    DeclarationApi.updateDeclaration(obj)
      .then((res) => {
        showInfo("Declaration is successfully updated!");
        setSubmitting(false);
        account?.isAdmin
          ? history.push("/admin/declaration")
          : history.push("/employee/declarations");
      })
      .catch((err) => console.log(err));
  };

  const VatAfterSplit = (wantToSplit: boolean) => {
   
    if (wantToSplit) {
      const VATTypes: { [key: string]: string } = {
        "Excludes 9% VAT": "Excludes 9% VAT",
        "Excludes 21% VAT": "Excludes 21% VAT",
        "Includes 9% VAT": "Includes 9% VAT",
        "Includes 21% VAT": "Includes 21% VAT",
      };
      setVatTypes(VATTypes);
    } else {
      DeclarationApi.getVatTypes().then((res) => {
        setVatTypes(res);
      });
    }
  };


  const checkPreviousDeclaractionVatTypes = (VATTypes: { [key: string]: string }) => {
    if(declarationById === undefined){
      return;
    }
    if(declarationById.parts.length > 1){
      const VATTypesObj: { [key: string]: string } = {
        "Excludes 9% VAT": "Excludes 9% VAT",
        "Excludes 21% VAT": "Excludes 21% VAT",
        "Includes 9% VAT": "Includes 9% VAT",
        "Includes 21% VAT": "Includes 21% VAT",
      };
      setVatTypes(VATTypesObj);
    }else{
      setVatTypes(VATTypes)
    }
  }

  var pdfPreview;
  if (!isSafari) {
    pdfPreview = (
      <object
        className={
          isMobileDevice
            ? classes.previewUploadOnMobile
            : classes.previewUploadOnDesktop
        }
        type="application/pdf"
        data={googleFileId === "" ? imageAsURL : googleFileId}
      >
        {" "}
        <div className={classes.pdfUnsupportedMessage}>
          {" "}
          This browser does not support previewing PDF files.
        </div>{" "}
      </object>
    );
  } else {
    pdfPreview = (
      <object
        className={
          isMobileDevice
            ? classes.previewUploadOnMobile
            : classes.previewUploadOnDesktop
        }
        data={googleFileId === "" ? imageAsURL : googleFileId}
      >
        {" "}
        <div className={classes.pdfUnsupportedMessage}>
          {" "}
          This browser does not support previewing PDF files.
        </div>{" "}
      </object>
    );
  }


  if (declarationById === undefined || vatAmounts === undefined) return null;

  return (
    <div>
      {submitting || loadingNewDeclarationMenu ? (
        <div className={classes.spinnerContainer}>
          <CircularProgress disableShrink className={classes.spinner} />
        </div>
      ) : (
        <div>
          {validationErrorVisible && (
            <Alert
              severity="error"
              onClose={() => setValidationErrorVisible(false)}
            >
              Please correct the errors in your form and submit again.
            </Alert>
          )}

          <div style={{ position: "relative" }}>{pdfPreview}</div>

          <div
            className={classes.uploadBlock}
            onClick={(event) => document.getElementById(uploadID)?.click()}
          >
            <Typography variant="subtitle2">
              <u>Click to upload receipt</u>
              <input
                type="file"
                id={uploadID}
                accept="image/*,.pdf"
                hidden
                onChange={(event) => {
                  let file: File | undefined | null =
                    event.target.files?.item(0);
                  if (file) {
                    var reader: FileReader = new FileReader();

                    reader.onload = function () {
                      const csv: string = reader.result as string;
                      setDeclarationById({
                        ...declarationById,
                        base64EncodedFile: csv,
                      });
                      if (
                        file!.type.startsWith("image") ||
                        file!.type.endsWith("pdf")
                      ) {
                        if (file) {
                          setImageAsURL(URL.createObjectURL(file));
                          setGoogleFileId("");
                        }
                      }
                    };

                    reader.readAsDataURL(file!);
                  } else {
                    showError("You did not submit a file");
                  }
                }}
              ></input>
            </Typography>
            <div>All image formats or PDF</div>
          </div>

          {declarationById.parts.map((part, partIndex) => (
            <div className={classes.declarationPart} key={partIndex}>
              <div className={classes.horizontalChildren}>
                <AmountInput
                  id={`amount-declaration-part-${partIndex + 1}`}
                  label={`Amount`}
                  defaultValue={
                    declarationById.parts[partIndex].amount
                      .toString()
                      .split(".")
                      .join(",") ?? ""
                  }
                  onChange={(value: string) =>
                    setDeclarationById({
                      ...declarationById,
                      parts: declarationById.parts.map((part, index) => {
                        if (index === partIndex) {
                          part.amount = +value;
                        }
                        return part;
                      }),
                    })
                  }
                  prefix={`€`}
                  className={`${classes.amountInput}`}
                />
                <Dropdown
                  label={`VAT`}
                  value={declarationById.parts[partIndex].vatType}
                  values={VATTypes}
                  onChange={(value) =>
                    setDeclarationById({
                      ...declarationById,
                      parts: declarationById.parts.map((part, index) => {
                        if (index === partIndex) {
                          part.vatType = value;
                        }
                        return part;
                      }),
                    })
                  }
                  maxWidth={`60%`}
                />
              </div>
              <div className={classes.inputFieldRow}>
                <Dropdown
                  label={`Type of purchase`}
                  value={declarationById.parts[partIndex].declarationType}
                  values={declarationTypes}
                  onChange={(value) =>
                    setDeclarationById({
                      ...declarationById,
                      parts: declarationById.parts.map((part, index) => {
                        if (index === partIndex) {
                          part.declarationType = value;
                        }
                        return part;
                      }),
                    })
                  }
                />
              </div>
            </div>
          ))}
          {declarationById.parts.length === 1 ? (
            <Button
              className={classes.whiteToBlackButton}
              onClick={() => {
                VatAfterSplit(true);
                setDeclarationById({
                  ...declarationById,
                  parts: declarationById.parts
                    .concat({
                      amount: 0,
                      vatType: "Includes 9% VAT",
                      declarationType: "Office supplies",
                    })
                    .map((part, index) => {
                      if (index === 0) {
                        part.amount = 0;
                      }
                      return part;
                    }),
                });
              }}
            >
              Split vat
            </Button>
          ) : undefined}
          <Collapse in={declarationById.parts.length === 2}>
            <Delete
              className={classes.trashButton}
              onClick={() => {
                VatAfterSplit(false);
                setDeclarationById({
                  ...declarationById,
                  parts: declarationById.parts.slice(0, 1),
                });
              }}
            />
          </Collapse>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={!declarationById.reimburse}
                onChange={(event, dontReimburse) => {
                  let reimburseObj: boolean = dontReimburse;
                  setDeclarationById({
                    ...declarationById,
                    reimburse: !reimburseObj,
                  });
                }}
              />
            }
            label="Don't reimburse me, I used a business account"
          />
          <ListItem></ListItem>
          <TextField
            id="declaration-remark"
            label="Remark (optional)"
            defaultValue={declarationById.remark ?? ""}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setDeclarationById({
                ...declarationById,
                remark: event.target.value,
              })
            }
            multiline
            rows={4}
            placeholder="If you have additional background information on your declaration to support the approval, please comment here."
            fullWidth={true}
            margin="dense"
            variant="outlined"
          />
          <Button
            disabled={submitting}
            className={classes.submitButton}
            onClick={uploadDeclaration}
            fullWidth
          >
            Update
          </Button>
        </div>
      )}
    </div>
  );
};
