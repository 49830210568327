/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Box, createStyles, makeStyles, Tab, Tabs } from "@material-ui/core";
import { RouteComponentProps } from "react-router-dom";
import { HttpError } from "../../../component/HttpError";
import UserDocuments from "./UserDocuments";
import UserDetails from "./UserDetails";

interface RouterMatch {
  id: string;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel = (props: TabPanelProps): JSX.Element => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};

const a11yProps = (index: any): {} => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const useStyles = makeStyles(() =>
  createStyles({
    indicator: {
      backgroundColor: "#0081d7",
    },
    inactiveTab: {
      "&:checked": { backgroundColor: "green" },
      "&:hover": {
        backgroundColor: "#cccccc",
        color: "black !important",
      },
    },
    tab: {
      color: "#0081d7",
    },
  })
);

export const UserItem = (props: RouteComponentProps<RouterMatch>) => {
  const userId = props.match.params.id;
  const newUser = userId === "new";
  const [showHttpError, setShowHttpError] = useState(false);
  const [retryAction, setRetryAction] = useState<() => void>();
  const [tabNr, setTabNr] = useState(0);

  const getClassForTab = (index: number): string =>
    index === tabNr ? classes.tab : classes.inactiveTab;

  const classes = useStyles();

  const handleChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    setTabNr(newValue);
  };

  return (
    <div>
      <HttpError
        open={showHttpError}
        close={() => setShowHttpError(false)}
        retry={() => {
          retryAction && retryAction();
        }}
      />
      <Tabs
        value={tabNr}
        onChange={handleChange}
        aria-label="simple tabs example"
        classes={{ indicator: classes.indicator }}
      >
        <Tab
          label={newUser ? "New user" : "User Details"}
          {...a11yProps(0)}
          className={getClassForTab(0)}
        />

        <Tab
          label="DOCUMENTS"
          {...a11yProps(1)}
          className={getClassForTab(1)}
        />
      </Tabs>
      <TabPanel value={tabNr} index={0}>
        <UserDetails
          history={props.history}
          userId={userId}
          setRetryAction={setRetryAction}
          setShowHttpError={setShowHttpError}
          retryAction={retryAction}
          showHttpError={showHttpError}
        />
      </TabPanel>

      <TabPanel value={tabNr} index={1}>
        <UserDocuments
          userId={userId}
          setRetryAction={setRetryAction}
          setShowHttpError={setShowHttpError}
        />
      </TabPanel>
    </div>
  );
};
