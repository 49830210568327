import config from "../config";
import { AxiosAuthRefreshRequestConfig } from "axios-auth-refresh";
import Axios from "axios";

export const noAuthAxios = Axios.create({
  baseURL: config.apiUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

export const quadAxios = Axios.create({
  baseURL: config.apiUrlOld,
  headers: {
    "Content-Type": "application/json",
  },
});

interface AuthorizationUriDto {
  redirect_uri: string;
}

export interface AuthorizationUri {
  redirectUri: string;
}

export interface TokenDto {
  id_token: string;
  access_token: string;
  refresh_token: string;
  token_type: string;
  expires_in: number;
}

export interface Token {
  idToken: string;
  accessToken: string;
  refreshToken: string;
  tokenType: string;
  expiresIn: number;
}

const AUTHORIZATION_CODE_GRANT = "authorization_code";
const REFRESH_TOKEN_GRANT = "refresh_token";

export default class AuthenticationApi {
  static async getAuthorizationUri(
    redirectUri: string
  ): Promise<AuthorizationUri> {
    // Use the noAuthAxios Axios to avoid collision with the auth axios
    let response = await noAuthAxios.get<AuthorizationUriDto>(
      `/auth/authorize?redirect_uri=${encodeURIComponent(redirectUri)}`
    );
    return {
      redirectUri: response.data.redirect_uri,
    };
  }

  static async getTokenFromAuthorizationCode(
    authorizationCode: string,
    state: string,
    redirectUri: string
  ): Promise<Token> {
    // Use the noAuthAxios Axios to avoid collision with the auth axios
    let response = await noAuthAxios.post<TokenDto>(`/auth/token`, {
      grant_type: AUTHORIZATION_CODE_GRANT,
      code: authorizationCode,
      state: state,
      redirect_uri: redirectUri,
    });
    return {
      idToken: response.data.id_token,
      accessToken: response.data.access_token,
      refreshToken: response.data.refresh_token,
      tokenType: response.data.token_type,
      expiresIn: response.data.expires_in,
    };
  }

  static async getTokenFromRefreshToken(refreshToken: string): Promise<Token> {
    // Use the noAuthAxios Axios to avoid collision with the auth axios
    let response = await noAuthAxios.post<TokenDto>(
      `/auth/token`,
      {
        grant_type: REFRESH_TOKEN_GRANT,
        refresh_token: refreshToken,
      },
      { skipAuthRefresh: true } as AxiosAuthRefreshRequestConfig
    );
    return {
      idToken: response.data.id_token,
      accessToken: response.data.access_token,
      refreshToken: response.data.refresh_token,
      tokenType: response.data.token_type,
      expiresIn: response.data.expires_in,
    };
  }

  static async revokeTokens(): Promise<void> {
    // Use the noAuthAxios Axios to avoid collision with the auth axios
    await noAuthAxios.delete(`/auth/token`);
  }
}
