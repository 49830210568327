/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext, useRef } from "react";
import {
  Backdrop,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  createStyles,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  List,
  ListItem,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Theme,
} from "@material-ui/core";
import { Alert, Autocomplete } from "@material-ui/lab";
import { RouteComponentProps } from "react-router-dom";

import { Title } from "../../component/Title";
import { TextInput } from "../../component/TextInput";
import { Utils } from "../../lib/utils";
import CustomerApi, { CustomerItem } from "../../data/customer";
import ProjectApi, {
  CreateProject,
  ProjectEdit,
  ProjectType,
  ProjectTypes,
  UpdateProject,
  ProjectUser,
  BillingCycle,
  BillingCycles,
} from "../../data/project";
import UserApi, { Role, SimpleUserItem } from "../../data/user";
import { SnackbarContext } from "../../component/SnackbarProvider";
import {
  useValidator,
  isNotEmpty,
  isValidDate,
  isValidDateOrEmpty,
  isValidEmail,
} from "../../hooks/validator";
import { DatePicker } from "../../component/DatePicker";
import { CheckBoxes } from "../../component/Checkboxes";
import { GoogleMapsPlacefinder } from "../../component/GoogleMapsPlacefinder";
import { HttpError } from "../../component/HttpError";
import { Prediction } from "../../data/googleMaps";
import { v4 as uuidv4 } from "uuid";

const mandatoryCreateProjectFields: (keyof CreateProject)[] = [
  "name",
  "customerId",
  "startDate",
  "projectType",
];

const mandatoryUpdateProjectFields: (keyof UpdateProject)[] = [
  "projectId",
  "name",
  "startDate",
  "projectType",
];

const mandatoryCustomAddressFields: (keyof ProjectEdit)[] = [
  "projectEmailAddress",
  "googleAddressId",
];

const getValidationMessage = (field: keyof UpdateProject): string => {
  switch (field) {
    case "name":
      return "Name must not be empty";
    case "customerId":
      return "A customer must be selected";
    case "startDate":
      return "Project start date is required";
    case "endDate":
      return "Project end date must be valid or empty";
    case "projectType":
      return "Project type is required";
    case "projectEmailAddress":
      return "E-mail address must not be empty";
    case "googleAddressId":
      return "Visiting address must not be empty";
  }
  return "";
};

const getValidationFunction = (
  field: keyof UpdateProject
): ((value: any) => boolean) => {
  switch (field) {
    case "name":
      return isNotEmpty;
    case "customerId":
      return isNotEmpty;
    case "startDate":
      return isValidDate;
    case "endDate":
      return isValidDateOrEmpty;
    case "projectType":
      return isNotEmpty;
    case "projectEmailAddress":
      return isValidEmail;
    case "googleAddressId":
      return isNotEmpty;
  }
  return () => false;
};

const getRequiredFieldsForSettings = (
  isNewProject: boolean,
  useCustomerAddress: boolean
): (keyof UpdateProject)[] => {
  let requiredFields: (keyof UpdateProject)[];
  if (isNewProject) {
    requiredFields = mandatoryCreateProjectFields;
  } else {
    requiredFields = mandatoryUpdateProjectFields;
  }

  if (!useCustomerAddress) {
    requiredFields.concat(mandatoryCustomAddressFields);
  }
  return requiredFields;
};

interface RouterMatch {
  id: string;
  secondaryId: string;
}

const emptyProject: UpdateProject = {
  projectId: "",
  customerId: "",
  name: "",
  startDate: "",
  endDate: "",
  employees: [],
  projectType: ProjectType.REGULAR,
  billingCycle: BillingCycle.UNSPECIFIED,
  projectEmailAddress: "",
  googleAddressId: "",
  googleAddress: "",
  googleSessionToken: "",
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {},
    disableButton: {
      marginLeft: "2em",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  })
);

export const ProjectItem = (props: RouteComponentProps<RouterMatch>) => {
  const customerId = props.match.params.secondaryId;
  if (customerId && customerId.length > 0) {
    emptyProject.customerId = customerId;
  }

  const projectId = props.match.params.id;
  const newProject = projectId === "new";

  const [employeeOptions, setEmployeeOptions] = useState<{
    [key: string]: string;
  }>({});

  const [project, setProject] = useState<UpdateProject>(emptyProject);
  const [customer, setCustomer] = useState<CustomerItem | null>(null);
  const { showInfo, showError } = useContext(SnackbarContext);
  const [loading, setLoading] = useState<boolean>(true);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [showHttpError, setShowHttpError] = useState(false);
  const [retryAction, setRetryAction] = useState<() => void>();
  const classes = useStyles();
  const [useCustomerAddress, setUseCustomerAddress] = useState<boolean>(false);
  const validator = useValidator<UpdateProject>(
    project,
    getRequiredFieldsForSettings(newProject, useCustomerAddress)
  );
  const [validationErrorVisible, setValidationErrorVisible] = useState(false);
  const [value, setValue] = useState(0);
  const [currentProjectStatus, setCurrentProjectStatus] = useState<string>("");

  const onChangeProjectAddress = (value: Prediction | null) => {
    if (value != null) {
      setProject({
        ...project,
        googleAddressId: value.placeId,
        googleAddress: value.description,
      });
      validator.validate(
        "googleAddressId",
        getValidationMessage("googleAddressId"),
        getValidationFunction("googleAddressId"),
        value.placeId
      );
    }
  };

  const [customerList, setCustomerList] = useState<CustomerItem[]>([]);

  const onChangeProjectCustomerId = (
    _event: React.ChangeEvent<{}>,
    value: CustomerItem | null
  ) => {
    if (value?.customerId) {
      setProject({ ...project, customerId: value.customerId });
      setCustomer(value);
    }
  };

  const onChangeEmployees = async (employees: any) => {
    var ProjectEmployees: ProjectUser[] = [];
    var resFilter: SimpleUserItem[] = [];
    await UserApi.getUsers().then((res) => {
      resFilter = res.filter((m) => employees.includes(m.userId));
    });
    resFilter.forEach((employee: SimpleUserItem) => {
      ProjectEmployees.push({
        projectId: project.projectId,
        userId: employee.userId,
        projectName: project.name,
        userName: employee.name,
      });
    });
    setProject({ ...project, employees: ProjectEmployees });
  };

  const saveProject = () => {
    setDisabled(true);
    validator.validateMultiple(
      getRequiredFieldsForSettings(newProject, useCustomerAddress).map(
        (field) => {
          return {
            field,
            message: getValidationMessage(field),
            valid: getValidationFunction(field),
          };
        }
      )
    );

    if (!validator.isValid && project.customerId === null) {
      setValidationErrorVisible(true);
      window.scrollTo(0, 0);
      setDisabled(false);
      return;
    }

    setValidationErrorVisible(false);
    let action = () => {
      setDisabled(true);
      setShowHttpError(false);
      let request = newProject
        ? ProjectApi.createProject({
            name: project.name,
            customerId: project.customerId,
            startDate: project.startDate,
            endDate: project.endDate,
            projectEmailAddress: useCustomerAddress
              ? undefined
              : project.projectEmailAddress,
            googleAddressId: useCustomerAddress
              ? undefined
              : project.googleAddressId,
            googleAddress: project.googleAddress,
            googleSessionToken: project.googleSessionToken,
            employees: project.employees ? project.employees : [],
            projectType: project.projectType,
            billingCycle: project.billingCycle,
          })
        : ProjectApi.updateProject({
            projectId: project.projectId,
            customerId: project.customerId,
            name: project.name,
            startDate: project.startDate,
            endDate: project.endDate,
            projectEmailAddress: useCustomerAddress
              ? undefined
              : project.projectEmailAddress,
            googleAddressId: useCustomerAddress
              ? undefined
              : project.googleAddressId,
            googleAddress: project.googleAddress,
            googleSessionToken: project.googleSessionToken,
            employees: project.employees,
            projectType: project.projectType,
            billingCycle: project.billingCycle,
          });

      request.then(
        (res) => {
          showInfo(`${newProject ? "Created" : "Updated"} project`);
          props.history.push("/admin/projects");
        },
        (err) => {
          if (err.message !== "Network Error") {
            showError(
              `Failed to ${newProject ? `create` : `update`} project: : \n${
                err.response.data
              }`
            );
          } else {
            setShowHttpError(true);
          }
          setDisabled(false);
        }
      );
    };
    setRetryAction(() => action);
    action();
  };

  const archiveProject = () => {
    setDisabled(true);
    let action = () => {
      let request = ProjectApi.archiveProject(project.projectId);
      request.then(
        (res) => {
          showInfo("Archived project");
          props.history.push("/admin/projects");
        },
        (err) => {
          if (err.message !== "Network Error") {
            showError("Failed to archive project");
          } else {
            setShowHttpError(true);
          }
          setDisabled(false);
        }
      );
    };
    setRetryAction(() => action);
    action();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    validator.updateMandatoryInputs(
      getRequiredFieldsForSettings(newProject, event.target.checked)
    );
    if (event.target.checked) {
      setProject({
        ...project,
        googleAddressId: undefined,
        projectEmailAddress: undefined,
      });
      setUseCustomerAddress(true);
    } else {
      setProject({
        ...project,
        googleAddressId: "",
        projectEmailAddress: "",
      });
      setUseCustomerAddress(false);
    }
  };

  useEffect(() => {
    console.log("rerender")
    if (loading) {
      CustomerApi.getCustomers().then((customers) => {
        setCustomerList(customers);
        customers
          .filter((customer) => customer.customerId === customerId)
          .map((customer) => setCustomer(customer));
      });

      ProjectApi.getProject(projectId).then((res) => {
        setCurrentProjectStatus(res.projectStatus ? res.projectStatus : "");
      });

      UserApi.getUsers().then((users) => {
        const map: any = {};
        users
          .filter((user) => user.roles?.includes(Role.Employee))
          .filter((activeUser) => activeUser.userAccountStatus === "ACTIVE" || activeUser.userAccountStatus === "PENDINGPROFILECOMPLETION")
          .forEach((p: SimpleUserItem) => {
            map[p.userId] = p.name;
          });
        setEmployeeOptions(map);
      });

      if (newProject) {
        setLoading(false);
        setProject({
          ...project,
          projectType: ProjectType.REGULAR,
          googleSessionToken: uuidv4(),
        });
        validator.validate(
          "projectType",
          getValidationMessage("projectType"),
          getValidationFunction("projectType")
        );
      } else {
        ProjectApi.getProject(projectId).then(
          (res) => {
            const useCustomerAddress = res.projectEmailAddress === undefined;
            setUseCustomerAddress(useCustomerAddress);
            setProject({
              ...project,
              projectId: projectId,
              customerId: res.customerId,
              billingCycle: res.billingCycle,
              name: res.name,
              startDate: res.startDate,
              endDate: res.endDate,
              employees: res.employees,
              projectType: res.projectType,
              projectEmailAddress: res.projectEmailAddress,
              googleAddressId: res.projectVisitingAddressId,
              googleAddress: res.projectVisitingAddress,
              googleSessionToken: uuidv4(),
            });
            validator.updateMandatoryInputs(
              getRequiredFieldsForSettings(newProject, useCustomerAddress),
              true
            );
            if (res.customerId) {
              CustomerApi.getCustomer(res.customerId).then((customer) => {
                setCustomer(customer);
              });
            }
            setLoading(false);
          },
          (_err) => {
            showError("Failed to load project");
            setLoading(false);
          }
        );
      }
    }
  }, []);

  const typeMenuItems = Object.keys(ProjectTypes.getProjectTypes()).map(
    (key) => (
      <MenuItem key={key} value={key}>
        {ProjectTypes.getNameFromEnum(
          ProjectTypes.getProjectTypes()[parseInt(key)] as ProjectType
        )}
      </MenuItem>
    )
  );

  const billingCycleMenuItems = Object.keys(BillingCycles.getBillingCycles()).map(
    (key) => (
      <MenuItem key={key} value={key}>
        {BillingCycles.getNameFromEnum(
          BillingCycles.getBillingCycles()[parseInt(key)] as BillingCycle
        )}
      </MenuItem>
    )
  );

  const typeLabel = useRef<HTMLLabelElement>(null);
  const typeLabelWidth = typeLabel.current ? typeLabel.current.clientWidth : 0;

  return (
    <Grid container>
      <Grid container item xs={9}>
        <Container>
          <HttpError
            open={showHttpError}
            close={() => setShowHttpError(false)}
            retry={() => {
              retryAction && retryAction();
            }}
          />
          {validationErrorVisible && (
            <Alert
              severity="error"
              onClose={() => setValidationErrorVisible(false)}
            >
              Please correct the errors in your form and submit again.
            </Alert>
          )}
          <Title>{newProject ? "New" : "Edit"} project</Title>
          <List>
            <ListItem>
              <TextInput
                id="project-name"
                label="Project name"
                value={project.name}
                onChange={(name) => setProject({ ...project, name })}
                error={validator.get("name")}
                onBlur={() =>
                  validator.validate(
                    "name",
                    getValidationMessage("name"),
                    getValidationFunction("name")
                  )
                }
              />
            </ListItem>
            <ListItem id="customer-list">
              <Autocomplete
                id="CustomerList"
                options={customerList}
                disabled={!newProject}
                value={customer}
                fullWidth={true}
                getOptionLabel={(customer: any) => customer.name}
                getOptionSelected={(customer: any) => {
                  return customerList.includes(customer);
                }}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    label="Customer"
                    variant="outlined"
                    value={customer}
                    disabled={!newProject}
                    error={!!validator.get("customerId")}
                    onBlur={() =>
                      validator.validate(
                        "customerId",
                        getValidationMessage("customerId"),
                        getValidationFunction("customerId")
                      )
                    }
                    helperText={validator.get("customerId")}
                  />
                )}
                onChange={onChangeProjectCustomerId}
              />
            </ListItem>
            <ListItem>
              <CheckBoxes
                id="employees"
                label="Employees"
                values={project.employees?.map((m) => m.userId)}
                options={employeeOptions}
                onChange={onChangeEmployees}
              />
            </ListItem>
            <ListItem id="project-start-date">
              <DatePicker
                label="Start date"
                value={Utils.parseDate(project.startDate)}
                error={validator.get("startDate")}
                onChange={(date) => {
                  if (date instanceof Date && !isNaN(date?.getTime())) {
                    let startDate: string = Utils.formatDate(date);
                    setProject({ ...project, startDate });
                    validator.validate(
                      "startDate",
                      getValidationMessage("startDate"),
                      getValidationFunction("startDate"),
                      startDate
                    );
                  }
                }}
                onBlur={() =>
                  validator.validate(
                    "startDate",
                    getValidationMessage("startDate"),
                    getValidationFunction("startDate")
                  )
                }
              />
            </ListItem>
            <ListItem id="project-end-date">
              <DatePicker
                label="End date (optional)"
                value={Utils.parseDate(project.endDate)}
                error={validator.get("endDate")}
                onChange={(date) => {
                  if (date instanceof Date && !isNaN(date?.getTime())) {
                    let endDate: string = Utils.formatDate(date);
                    setProject({ ...project, endDate });
                    validator.validate(
                      "endDate",
                      getValidationMessage("endDate"),
                      getValidationFunction("endDate"),
                      endDate
                    );
                  } else {
                    let endDate: string = "";
                    setProject({ ...project, endDate });
                    validator.validate(
                      "endDate",
                      getValidationMessage("endDate"),
                      getValidationFunction("endDate"),
                      endDate
                    );
                  }
                }}
              />
            </ListItem>
            <ListItem id="project-type">
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="type-select-label" ref={typeLabel}>
                  Type
                </InputLabel>
                <Select
                  labelWidth={typeLabelWidth}
                  labelId="type-select-label"
                  id="demo-simple-select"
                  value={
                    project.projectType === ProjectType.REGULAR
                      ? typeMenuItems[0].key
                      : project.projectType === ProjectType.HOLIDAY
                      ? typeMenuItems[1].key
                      : project.projectType === ProjectType.SICKLEAVE
                      ? typeMenuItems[2].key
                      : project.projectType === ProjectType.VACATIONLEAVE
                      ? typeMenuItems[3].key
                      : value
                  }
                  onChange={(event) => {
                    setValue(event.target.value as number);
                    const projectType = ProjectTypes.getProjectTypes()[
                      event.target.value as number
                    ] as ProjectType;
                    setProject({ ...project, projectType });
                    validator.validate(
                      "projectType",
                      getValidationMessage("projectType"),
                      getValidationFunction("projectType"),
                      projectType
                    );
                  }}
                  onBlur={() =>
                    validator.validate(
                      "projectType",
                      getValidationMessage("projectType"),
                      getValidationFunction("projectType")
                    )
                  }
                >
                  {typeMenuItems}
                </Select>
              </FormControl>
            </ListItem>
            <ListItem id="billing-cycle">
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="billing-cycle-select-label" ref={typeLabel}>
                  Billing Cycle
                </InputLabel>
                <Select
                  labelWidth={typeLabelWidth}
                  labelId="billing-cycle-select-label"
                  id="demo-simple-select"
                  value={
                    project.billingCycle === BillingCycle.UNSPECIFIED
                      ? billingCycleMenuItems[0].key
                      : project.billingCycle === BillingCycle.FOUR_WEEKLY
                      ? billingCycleMenuItems[1].key
                      : project.billingCycle === BillingCycle.MONTHLY
                      ? billingCycleMenuItems[2].key
                      : value
                  }
                  onChange={(event) => {
                    setValue(event.target.value as number);
                    const billingCycle = BillingCycles.getBillingCycles()[
                      event.target.value as number
                    ] as BillingCycle;
                    console.log(project.billingCycle);
                    setProject({ ...project, billingCycle });
                    validator.validate(
                      "billingCycle",
                      getValidationMessage("billingCycle"),
                      getValidationFunction("billingCycle"),
                      billingCycle
                    );
                  }}
                  onBlur={() =>
                    validator.validate(
                      "billingCycle",
                      getValidationMessage("billingCycle"),
                      getValidationFunction("billingCycle")
                    )
                  }
                >
                  {billingCycleMenuItems}
                </Select>
              </FormControl>
            </ListItem>
            <ListItem id="use-customers-visiting-address">
              <FormControlLabel
                id="use-customer-address"
                disabled={project.customerId.length === 0}
                control={
                  <Checkbox
                    checked={useCustomerAddress}
                    onChange={handleChange}
                    name="Additional addresses"
                  />
                }
                label="Use this customers visiting address"
              />
            </ListItem>
            <ListItem>
              <TextInput
                id="project-email-address"
                label="Project email address"
                value={
                  useCustomerAddress
                    ? customer?.emailAddress ?? ""
                    : project.projectEmailAddress!
                }
                onChange={
                  useCustomerAddress
                    ? undefined
                    : (projectEmailAddress) =>
                        setProject({
                          ...project,
                          projectEmailAddress: projectEmailAddress,
                        })
                }
                error={validator.get("projectEmailAddress")}
                onBlur={() =>
                  validator.validate(
                    "projectEmailAddress",
                    getValidationMessage("projectEmailAddress"),
                    getValidationFunction("projectEmailAddress")
                  )
                }
              />
            </ListItem>
            <ListItem id="project-address">
              <GoogleMapsPlacefinder
                label="Address"
                value={
                  useCustomerAddress
                    ? customer?.visitingAddressId !== undefined &&
                      customer?.visitingAddress !== undefined
                      ? {
                          placeId: customer.visitingAddressId,
                          description: customer.visitingAddress,
                        }
                      : undefined
                    : project.googleAddressId !== undefined &&
                      project.googleAddress !== undefined
                    ? {
                        placeId: project.googleAddressId,
                        description: project.googleAddress,
                      }
                    : undefined
                }
                sessionToken={project.googleSessionToken}
                disabled={useCustomerAddress}
                onSelectPrediction={onChangeProjectAddress}
                error={validator.get("googleAddressId")}
                onBlur={() =>
                  validator.validate(
                    "googleAddressId",
                    getValidationMessage("googleAddressId"),
                    getValidationFunction("googleAddressId")
                  )
                }
              />
            </ListItem>
            <ListItem>
              <Button
                id="project-save-button"
                variant="contained"
                color="primary"
                onClick={saveProject}
                disabled={disabled}
              >
                Save
              </Button>
              {!newProject && currentProjectStatus === "ACTIVE" && (
                <Button
                  onClick={archiveProject}
                  disabled={disabled}
                  className={classes.disableButton}
                >
                  Archive
                </Button>
              )}
            </ListItem>
            <Backdrop className={classes.backdrop} open={loading}>
              <CircularProgress color="inherit" />
            </Backdrop>
          </List>
        </Container>
      </Grid>
    </Grid>
  );
};
