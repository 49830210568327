import React, { useEffect, useState } from "react";
import { Box, CircularProgress } from "@material-ui/core";

type RemoteData<T> = LoadingData | NoData | Data<T>;

interface LoadingData {
  tag: "loading-data";
}

interface NoData {
  tag: "no-data";
  message: string;
}

interface Data<T> {
  tag: "data";
  data: T;
}

interface RemoteDataContainerProps<T> {
  fetch: () => Promise<T>;
  errorMessage: (error: any) => string;
  loadingElement?: JSX.Element;
  renderData: (data: T) => JSX.Element;
}

const Spinner = () => (
  <div
    style={{
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "2em",
    }}
  >
    <CircularProgress />
  </div>
);

export const RemoteDataContainer = <T extends unknown>({
  fetch,
  errorMessage,
  loadingElement,
  renderData,
}: RemoteDataContainerProps<T>) => {
  const [remoteData, setRemoteData] = useState<RemoteData<T>>({
    tag: "loading-data",
  });

  useEffect(() => {
    setRemoteData({
      tag: "loading-data",
    });
    fetch()
      .then((data) => {
        setRemoteData({ tag: "data", data });
      })
      .catch((error) => {
        setRemoteData({ tag: "no-data", message: errorMessage(error) });
      });
  }, [fetch, errorMessage]);

  switch (remoteData.tag) {
    case "loading-data":
      return loadingElement ? loadingElement : <Spinner />;
    case "no-data":
      return (
        <Box p={2} bgcolor="secondary.main" color="primary.contrastText">
          {remoteData.message}
        </Box>
      );
    case "data":
      return renderData(remoteData.data);
  }
};
