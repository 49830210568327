import { WeekReportItem } from "../../data/weekReport";
import { SimpleUserItem } from "../../data/user";
import { WeekReport } from "./WeekReport";

export const WeekReports = ({
  weekReports,
  users = [],
  presentOnly,
}: {
  weekReports: WeekReportItem[];
  users?: SimpleUserItem[];
  presentOnly: boolean;
}) => {
  const weekReportsApproved = weekReports
    .sort((a, b) =>
      a.year === b.year ? b.weekNumber - a.weekNumber : b.year - a.year
    )
    .filter((weekReport) => weekReport.approved);
  if (weekReportsApproved.length === 0) {
    return <div>No approved week reports found</div>;
  } else {
    return (
      <div>
        {weekReportsApproved.map((weekReport) => {
          let names: string[] = [];
          if (users.length > 1) {
            names = users
              .filter((u) => u.userId === weekReport.userId)
              .map((u) => u.name);
          } else if (users.length === 1) {
            names = [users[0].name];
          }
          return (
            <WeekReport
              presentOnly={presentOnly}
              key={weekReport.id}
              weekReport={weekReport}
              name={names ? names[0] : undefined}
            />
          );
        })}
      </div>
    );
  }
};
