import React from "react";
import { InputAdornment, TextField } from "@material-ui/core";

interface AmountInputProps {
  id: string;
  label: string;
  value?: string;
  onChange?: (value: string) => void;
  onBlur?: (
    event?: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  error?: string;
  prefix?: string;
  className?: string;
  defaultValue?: string;
}

const keepAmountValid = (currentAmount: string, newAmount: string): string => {
  if (newAmount.length === 0) {
    return "";
  } else {
    newAmount = newAmount.split(".").join(",");
  } // Leave only comma's, no dots.

  const countCharacter = (value: string, character: string): number =>
    value.split(character).length - 1;

  const valid = (value: string): boolean => {
    if (isNaN(parseInt(value))) {
      return false;
    }

    // Allow only one comma.
    let numberOfCommas = countCharacter(value, ",");

    if (numberOfCommas > 1) {
      return false;
    }

    // Check if all content are numbers or comma's.
    let actualValues: string[] = value.split(",");
    let parsedValues: string[] = value
      .split(",")
      .map((value) => parseInt(value).toString());

    if (actualValues.length > 1) {
      if (
        actualValues[1] !== parsedValues[1].toString() &&
        actualValues[1][0] === "0"
      ) {
        parsedValues[1] = "0" + parsedValues[1];
      }
    }

    let lengthAfterParsing = parsedValues[0].length + numberOfCommas;

    if (parsedValues.length === 2 && parsedValues[1] !== "NaN") {
      lengthAfterParsing += parsedValues[1].length === 1 ? 1 : 2;
    }

    return value.length === lengthAfterParsing;
  };

  if (valid(newAmount)) {
    return newAmount.split(",").join(".");
  } else {
    return currentAmount.split(",").join(".");
  }
};

let currentValue: string = "";

export const AmountInput = (props: AmountInputProps) => {
  if (props.value === undefined && props.defaultValue !== undefined) {
    currentValue = props.defaultValue;
  }

  if (props.value !== undefined && props.defaultValue === undefined) {
    currentValue = props.value;
  }

  return (
    <TextField
      id={props.id}
      label={props.label}
      variant="outlined"
      fullWidth
      defaultValue={props.defaultValue}
      value={props.value}
      onBlur={props.onBlur}
      error={!!props.error}
      helperText={props.error}
      disabled={!props.onChange}
      onChange={(event) => {
        props.onChange &&
          props.onChange(
            keepAmountValid(currentValue, event.target.value as string)
          );
      }}
      InputProps={
        props.prefix
          ? {
              startAdornment: (
                <InputAdornment position={`start`}>
                  {props.prefix}
                </InputAdornment>
              ),
            }
          : undefined
      }
      className={props.className}
    />
  );
};
