import { Action, Reducer } from "redux";
import storage from "localforage";
import { AuthenticationConstants } from "../constants";
import {
  AuthenticationAction,
  AuthenticationTokenAction,
  AuthenticationAccountAction,
  AuthenticationRefreshAction,
} from "../actions";
import { Account } from "../../data/principal";

const defaultState: AuthState = {
  isLoggedIn: false,
  isInAuthorization: false,
  isInRefresh: false,
  idToken: null,
  refreshToken: null,
  currentAccount: null,
  refreshingCall: null,
};

export type RefreshAction = Promise<void>;

export interface AuthState {
  isLoggedIn: boolean;
  isInAuthorization: boolean;
  isInRefresh: boolean;
  idToken: string | null;
  refreshToken: string | null;
  currentAccount: Account | null;
  refreshingCall: RefreshAction | null;
}

export const authConfig = {
  key: "auth",
  storage: storage,
  blacklist: ["isInRefresh", "refreshingCall"],
};

export const authReducer: Reducer<AuthState, Action<string>> = (
  state = defaultState,
  actionIn
): AuthState => {
  const action = actionIn as AuthenticationAction;
  if (action === null) {
    console.warn("Incorrect action class passed to authentication reducer!");
    return state;
  }

  switch (action.type) {
    case AuthenticationConstants.AUTHORIZATION_START:
      return { ...state, isInAuthorization: true };
    case AuthenticationConstants.AUTHORIZATION_END:
      return { ...state, isInAuthorization: false };
    case AuthenticationConstants.LOGIN:
      return { ...state, isLoggedIn: true };
    case AuthenticationConstants.LOGOUT:
      return { ...state,
        currentAccount: null,
        isInAuthorization: false,
        isLoggedIn: false ,
        idToken: null,
      };
    case AuthenticationConstants.TOKEN_SET:
      const actionts = action as AuthenticationTokenAction;
      return {
        ...state,
        idToken: actionts.token.idToken,
        refreshToken: actionts.token.refreshToken ?? state.refreshToken,
      };
    case AuthenticationConstants.TOKEN_REFRESH_UNSET:
      return { ...state, refreshToken: null };
    case AuthenticationConstants.ACCOUNT_SET:
      const actionus = action as AuthenticationAccountAction;
      return { ...state, currentAccount: actionus.account };
    case AuthenticationConstants.REFRESH_START:
      const actionrc = action as AuthenticationRefreshAction;
      return { ...state, isInRefresh: true, refreshingCall: actionrc.fn };
    case AuthenticationConstants.REFRESH_END:
      return { ...state, isInRefresh: false, refreshingCall: null };
    default:
      return state;
  }
};
