import { Grid, Paper, Box, Typography, CircularProgress, makeStyles, Theme, Link } from "@material-ui/core";
import moment from "moment";
import { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import AdminDashboardAPI, { DashboardDeclarationsBlockData } from "../../../data/admindashboard";

const useStyles = makeStyles((theme: Theme) => ({
    statistics: {
        backgroundColor: "#f2f2f2"
    },
    mb: {
        marginBottom: "1em"
    },
    mr: {
        marginRight: "0.5em"
    }
}));

const adminDashboardApi = new AdminDashboardAPI();

type DeclarationsBlockDataProps = {
    data: DashboardDeclarationsBlockData | null;

    onGoToDeclarations: () => void;
}

const DeclarationsBlockData = ({ data, onGoToDeclarations }: DeclarationsBlockDataProps) => {
    const classes = useStyles();

    if (data === null) {
        return <div>
            <Typography variant="body1" className={classes.mb}>Unable to load data</Typography>
        </div>
    }

    const totalExpenses = new Intl.NumberFormat("nl-NL", { style: "currency", currency: "EUR" }).format(data.totalExpenses);

    return (
        <div>
            <Typography variant="body1" className={classes.mb}><b>{data.pendingDeclarationCount}</b> <Link href="#" onClick={onGoToDeclarations}>Expense declarations pending</Link></Typography>
            <Typography variant="body1"><b>Expenses this month</b></Typography>
            <Typography variant="body1">{totalExpenses}</Typography>
        </div>
    )
}

export const DeclarationsBlock = withRouter(({ history }: RouteComponentProps) => {
    const classes = useStyles();

    const [loading, setLoading] = useState<boolean>(true);

    const [blockData, setBlockData] = useState<DashboardDeclarationsBlockData | null>(null);

    const week = moment().isoWeek();
    const year = moment().year();

    useEffect(() => {
        setLoading(true);

        adminDashboardApi.getDeclarationsBlockData(year, week)
            .then(data => {
                setBlockData(data);
            })
            .catch(err => {
                console.log(err);
                setBlockData(null);
                //dispatch(err);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [week, year]);

    const goToDeclarations = () => history.push("/admin/reviewDeclarations");

    return (
        <Grid item xs={12}>
            <Paper className={classes.statistics} elevation={3}>
                <Box p="2em">
                    <Typography variant="h6" component="h2">Declarations</Typography>
                    <hr></hr>
                    {loading ? (
                        <Grid container justifyContent="center"><CircularProgress /></Grid>
                    ) : (
                        <DeclarationsBlockData
                            data={blockData}
                            onGoToDeclarations={goToDeclarations} />
                    )}
                </Box>
            </Paper>
        </Grid>
    );
});