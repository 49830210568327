import { Token } from "./data/authentication";
import { makeAutoObservable } from "mobx";
import { autoSave } from "./lib/autoSave";

const env = (key: string): string => {
  const value = process.env[key];
  if (!value) {
    throw new Error(`Missing required environment variable: ${key}`);
  }
  return value;
};

interface Config {
  devAuthEnabled: boolean;
  debugAltApi: boolean;
  oauthToken?: Token;
  appName?: string;
  appEnv?: string;
  appUrl?: string;
  apiUrlOld?: string;
  apiUrl: string;
  appVersion?: string;
  lastCommitMessage?: string;
  apiToken?: string;
}

const appEnv = process.env.REACT_APP_ENV;
const isDev = appEnv === "development";

const config: Config = {
  appUrl: process.env.REACT_APP_URL,
  apiUrl: env("REACT_APP_QUAD_API_HOST"),
  // apiUrl: env("REACT_APP_API_HOST"),
  apiUrlOld: env("REACT_APP_API_HOST"),
  // debugAltApi: process.env.REACT_APP_DEBUG_ALT_API === "true",
  debugAltApi: isDev && false,
  // devAuthEnabled: process.env.REACT_APP_DEV_AUTH_ENABLED === "true",
  devAuthEnabled: isDev,
  apiToken: undefined,
  oauthToken: undefined,
};

makeAutoObservable(config);
autoSave(config, "apiToken", "apiToken");
autoSave(config, "oauthToken", "oauthToken");
autoSave(config, "devAuthEnabled", "devAuthEnabled");

export default config;
