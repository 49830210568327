import { getAxios } from "./axios";

export enum UserDocumentType {
  PROFILEPHOTO = "Profile Photo",
  PASSPORTORIDCARD = "Passport or ID Card",
  UNSIGNEDEMPLOYMENTCONTRACT = "Unsigned Employment Contract",
  SIGNEDEMPLOYMENTCONTRACT = "Signed Employment Contract",
  UNSIGNEDLOONHEFFINGSVERKLARING = "Unsigned Loonheffingsverklaring",
  SIGNEDLOONHEFFINGSVERKLARING = "Signed Loonheffingsverklaring",
  LATESTCURRICULUMVITAE = "Latest Curriculum Vitae",
}

export interface UserDocumentTypes {
  [key: string]: string;
}

export interface UserDocumentComponent {
  personId: string;
  userDocumentType: string;
  yukiId: string;
}

export interface UserDocumentDataItem {
  id: string;
  userId: string;
  parts: UserDocumentComponent[];
  submissionDate: string;
  submissionTime?: string;
  deletionDate: string;
}

export interface CreateUserDocumentComponent {
  personId: string;
  userDocumentType: string;
}
export interface CreateUserDocument {
  parts: CreateUserDocumentComponent[];
  base64EncodedFile: string;
}

export default class UserDocumentApi {
  static async getUserDocumentTypes(): Promise<UserDocumentTypes> {
    let response = await getAxios().post<UserDocumentTypes>(
      `/UserDocumentTypes`
    );
    return response.data;
  }

  static async getUserDocuments(): Promise<UserDocumentDataItem[]> {
    let response = await getAxios().post<UserDocumentDataItem[]>(
      `/getUserDocuments`
    );
    return response.data;
  }

  static async getUserDocumentAttachmentDownloadUrl(
    UserDocumentId: string
  ): Promise<string> {
    let response = await getAxios().get<string>(
      `/getUserDocumentAttachmentDownloadUrl/${UserDocumentId}`
    );
    return response.data;
  }

  static async createUserDocument(
    payload: CreateUserDocument
  ): Promise<string> {
    let response = await getAxios().post<string>(
      `/createUserDocument`,
      payload
    );
    return response.data;
  }
  static async getUserDocument(
    UserDocumentId: string
  ): Promise<UserDocumentDataItem> {
    let response = await getAxios().get<UserDocumentDataItem>(
      `/document/${UserDocumentId}`
    );
    return response.data;
  }

  static async getSubmittedUserDocuments(
    userId: string
  ): Promise<UserDocumentDataItem[]> {
    let response = await getAxios().get<UserDocumentDataItem[]>(
      `/getSubmittedUserDocuments/${userId}`
    );
    return response.data;
  }

  static async getUndeletedUserDocuments(
    userId: string
  ): Promise<UserDocumentDataItem[]> {
    let response = await getAxios().get<UserDocumentDataItem[]>(
      `/getUndeletedUserDocuments/${userId}`
    );
    return response.data;
  }
  static async getDeletedUserDocuments(
    userId: string
  ): Promise<UserDocumentDataItem[]> {
    let response = await getAxios().get<UserDocumentDataItem[]>(
      `/getDeletedUserDocuments/${userId}`
    );
    return response.data;
  }

  static async getAllSubmittedUserDocuments(): Promise<UserDocumentDataItem[]> {
    let response = await getAxios().post<UserDocumentDataItem[]>(
      `/getAllSubmittedUserDocuments`
    );
    return response.data;
  }

  static async getAllUndeletedUserDocuments(): Promise<UserDocumentDataItem[]> {
    let response = await getAxios().post<UserDocumentDataItem[]>(
      `/getAllUndeletedUserDocuments`
    );
    return response.data;
  }
  static async getAllDeletedUserDocuments(): Promise<UserDocumentDataItem[]> {
    let response = await getAxios().post<UserDocumentDataItem[]>(
      `/getAllDeletedUserDocuments`
    );
    return response.data;
  }

  static async deleteUserDocument(UserDocumentId: string): Promise<boolean> {
    let response = await getAxios().delete<boolean>(
      `/document/${UserDocumentId}`
    );
    return response.data;
  }
}
