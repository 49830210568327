import {
  Button,
  createStyles,
  Divider,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";

import clsx from "clsx";

import moment from "moment";

import { ProjectItem, ProjectType } from "../../../data/project";
import { Section } from "../../../component/Section";
import { WeekHolidayList } from "../../../data/holiday";
import { Status, WeekReport } from "../../../data/weekReport";
import { useMemo, useReducer, useState, useEffect } from "react";

import { initialState, reducer } from "./reducer";

const maxHoursPerDay = 12;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      maxWidth: "3.4em",
      backgroundColor: theme.palette.background.paper,
    },
    inputDisabled: {
      backgroundColor: theme.palette.action.disabled,
    },
    styleNoProjects: {
      height: 77 * 5 + 60.5 + 69,
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
    styleTableCell: {
      padding: "0px",
      border: "0px",
    },
    divider: {
      margin: "0px",
      borderWidth: "1px",
      backgroundColor: theme.palette.action.active,
    },
  })
);

declare type PaletteType =
  | "primary"
  | "secondary"
  | "error"
  | "warning"
  | "info"
  | "success";

declare type InputProps = {
  className: string;
};

const getColorFromPallette = (theme: Theme, palette?: PaletteType) => {
  if (palette !== undefined) {
    return theme.palette[palette].main;
  }
  return theme.palette.text.primary;
};

const useEntryFieldStyles = (palette?: PaletteType) =>
  makeStyles((theme: Theme) => ({
    root: {
      "& .MuiInputBase-input": {
        color: getColorFromPallette(theme, palette),
      },
    },
  }));

const EntryField = ({
  id,
  inputProps,
  value,
  color,
  error,
  disabled,
  onChange,
}: {
  id: string;
  inputProps: InputProps | undefined;
  value: number;
  color?: PaletteType;
  error: boolean;
  disabled?: boolean;
  onChange: (value: number) => void;
}) => {
  const classes = useStyles();
  const colorClasses = useEntryFieldStyles(color)();

  return (
    <TextField
      id={id}
      variant="outlined"
      inputProps={inputProps}
      type="text"
      size="small"
      className={clsx({
        [classes.input]: true,
        [colorClasses.root]: true,
      })}
      value={value || ""}
      error={error}
      disabled={disabled}
      onChange={(event) => {
        const value = Number.parseInt(event.target.value, 10);
        if (Number.isNaN(value)) {
          onChange(0);
        } else if (value < 0) {
          onChange(0);
        } else if (value > maxHoursPerDay) {
          onChange(maxHoursPerDay);
        } else {
          onChange(value);
        }
      }}
    />
  );
};

interface TimesheetEntriesProps {
  projects: ProjectItem[];
  entries: { hours: number; error: boolean }[][];
  message: string;
  weekStart: string;
  weekReport: WeekReport;
  disabledAll: boolean;
  holidays: WeekHolidayList;
  onChange: (entry: {
    projectId: string;
    weekDay: number;
    hours: number;
  }) => void;
  onReset: () => void;
  showPrefill: boolean;
  disabledPrefill: boolean;
  prefillFunction: () => void;
  showSuggested: boolean;
  userStartDate: string | undefined;
  userEndDate: string | undefined;
}

export const TimesheetEntries = (timesheetEntries: TimesheetEntriesProps) => {
  const classes = useStyles();

  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [year, setYear] = useState<number[]>([]);
  const [month, setMonth] = useState<number[]>([]);
  const [day, setDay] = useState<number[]>([]);

  const GetProjectEnddate = (projectIndex: number) => {
    if (timesheetEntries.projects[projectIndex].endDate === undefined) {
      return "2100-10-10";
    } else {
      return timesheetEntries.projects[projectIndex].endDate;
    }
  };

  useEffect(() => {
    if (typeof timesheetEntries.userStartDate !== "undefined") {
      setStartDate(timesheetEntries.userStartDate);
    }

    var months: number[] = [];
    var years: number[] = [];
    var days: number[] = [];

    Array.from(Array(5).keys()).map((k) => {
      let date = moment(timesheetEntries.weekStart, "DD-MM-YYYY")
        .add(k, "days")
        .format("DD-MM-YYYY");

      // console.log(date)

      months.push(Number(date.substring(3, 5)));
      years.push(Number(date.substring(6, 10)));
      days.push(Number(date.substring(0, 2)));
      return date;
    });
    setMonth(months);
    setYear(years);
    setDay(days);

    if (typeof timesheetEntries.userEndDate !== "undefined") {
      setEndDate(timesheetEntries.userEndDate);
    }
  }, [
    timesheetEntries.userStartDate,
    timesheetEntries.weekStart,
    timesheetEntries.userEndDate,
  ]);

  const [state] = useReducer(
    reducer,
    initialState(timesheetEntries.weekReport)
  );

  const { isDraft, isSubmitted, isApproved } = useMemo((): {
    isDraft: boolean;
    isSubmitted: boolean;
    isApproved: boolean;
  } => {
    const isDraft = state.report.status === Status.Draft;
    const isSubmitted = state.report.status !== Status.Draft;
    const isApproved = state.report.status === Status.Approved;

    return {
      isDraft,
      isSubmitted,
      isApproved,
    };
  }, [state]);

  return timesheetEntries.projects.length > 0 ? (
    <TableContainer>
      <Table id="timesheet-table">
        <TableHead>
          <TableRow>
            <TableCell>
              <Section>Timesheet</Section>
            </TableCell>
            {Array.from(Array(5).keys()).map((k) => (
              <TableCell key={k} align="center">
                {moment(timesheetEntries.weekStart, "DD-MM-YYYY")
                  .add(k, "days")
                  .format("DD-MM-YYYY")}
                <Typography variant="subtitle1">
                  {
                    timesheetEntries.holidays.holidays.find(
                      (hol) => moment.utc(hol.date).isoWeekday() === k + 1
                    )?.fullName
                  }
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {timesheetEntries.entries.map((projectEntries, projectIndex) =>
            timesheetEntries.projects[projectIndex].projectType !==
              ProjectType.HOLIDAY &&
            timesheetEntries.projects[projectIndex].projectType !==
              ProjectType.SICKLEAVE &&
            timesheetEntries.projects[projectIndex].projectType !==
              ProjectType.VACATIONLEAVE ? (
              <TableRow
                id={`regular-project-${projectIndex - 2}`}
                key={timesheetEntries.projects[projectIndex].projectId}
              >
                <TableCell key="_header_">
                  <Typography variant="caption">
                    {timesheetEntries.projects[projectIndex].customerName}
                  </Typography>
                  <Typography>
                    {timesheetEntries.projects[projectIndex].name}
                  </Typography>
                </TableCell>

                {projectEntries.map((entry, weekDayIndex) => {
                  let holi = timesheetEntries.holidays.holidays.find(
                    (hol) =>
                      moment.utc(hol.date).isoWeekday() === weekDayIndex + 1
                  );
                  let holiIdx: number | undefined = undefined;
                  if (holi !== undefined) {
                    holiIdx = moment.utc(holi.date).isoWeekday();
                  }

                  return (
                    <TableCell key={weekDayIndex} align="center">
                      <EntryField
                        id={`week-day-${weekDayIndex + 1}`}
                        inputProps={
                          timesheetEntries.projects[projectIndex]
                            .projectType === ProjectType.HOLIDAY ||
                          weekDayIndex + 1 === holiIdx ||
                          moment(startDate).isAfter(
                            `${year[weekDayIndex]}-${month[weekDayIndex]}-${day[weekDayIndex]}`
                          ) ||
                          moment(endDate).isBefore(
                            `${year[weekDayIndex]}-${month[weekDayIndex]}-${day[weekDayIndex]}`
                          ) ||
                          moment(
                            timesheetEntries.projects[projectIndex].startDate
                          ).isAfter(
                            `${year[weekDayIndex]}-${month[weekDayIndex]}-${day[weekDayIndex]}`
                          ) ||
                          moment(
                            timesheetEntries.projects[projectIndex].endDate
                          ).isBefore(
                            `${year[weekDayIndex]}-${month[weekDayIndex]}-${day[weekDayIndex]}`
                          )
                            ? { className: classes.inputDisabled }
                            : undefined
                        }
                        value={entry.hours}
                        color={
                          timesheetEntries.projects[projectIndex]
                            .projectType !== ProjectType.REGULAR
                            ? "error"
                            : undefined
                        }
                        error={entry.error}
                        disabled={
                          timesheetEntries.projects[projectIndex]
                            .projectType === ProjectType.HOLIDAY ||
                          weekDayIndex + 1 === holiIdx ||
                          moment(startDate).isAfter(
                            `${[year[weekDayIndex]]}-${month[weekDayIndex]}-${
                              day[weekDayIndex]
                            }`
                          ) ||
                          moment(endDate).isBefore(
                            `${year[weekDayIndex]}-${month[weekDayIndex]}-${day[weekDayIndex]}`
                          ) ||
                          moment(
                            timesheetEntries.projects[projectIndex].startDate
                          ).isAfter(
                            `${year[weekDayIndex]}-${month[weekDayIndex]}-${day[weekDayIndex]}`
                          ) ||
                          moment(
                            timesheetEntries.projects[projectIndex].endDate
                          ).isBefore(
                            `${year[weekDayIndex]}-${month[weekDayIndex]}-${day[weekDayIndex]}`
                          )
                        }
                        onChange={(hours) =>
                          timesheetEntries.onChange({
                            projectId:
                              timesheetEntries.projects[projectIndex].projectId,
                            weekDay: weekDayIndex + 1,
                            hours,
                          })
                        }
                      />
                    </TableCell>
                  );
                })}
              </TableRow>
            ) : null
          )}
          {timesheetEntries.projects.some(({ projectType }) =>
            [ProjectType.SICKLEAVE, ProjectType.VACATIONLEAVE].includes(
              projectType
            )
          ) &&
          timesheetEntries.projects.some(({ projectType }) =>
            [ProjectType.REGULAR].includes(projectType)
          ) ? (
            <TableRow id="divider-row">
              <TableCell colSpan={6} className={classes.styleTableCell}>
                <Divider className={classes.divider}></Divider>
              </TableCell>
            </TableRow>
          ) : null}
          {timesheetEntries.entries.map((projectEntries, projectIndex) =>
            timesheetEntries.projects[projectIndex].projectType !==
              ProjectType.HOLIDAY &&
            (timesheetEntries.projects[projectIndex].projectType ===
              ProjectType.SICKLEAVE ||
              timesheetEntries.projects[projectIndex].projectType ===
                ProjectType.VACATIONLEAVE) ? (
              <TableRow
                id={`nonregular-project-${projectIndex + 1}`}
                key={timesheetEntries.projects[projectIndex].projectId}
              >
                <TableCell key="_header_">
                  <Typography variant="caption">
                    {timesheetEntries.projects[projectIndex].customerName}
                  </Typography>
                  <Typography>
                    {timesheetEntries.projects[projectIndex].name}
                  </Typography>
                </TableCell>
                {projectEntries.map((entry, weekDayIndex) => {
                  let holi = timesheetEntries.holidays.holidays.find(
                    (hol) =>
                      moment.utc(hol.date).isoWeekday() === weekDayIndex + 1
                  );
                  let holiIdx: number | undefined = undefined;
                  if (holi !== undefined) {
                    holiIdx = moment.utc(holi.date).isoWeekday();
                  }

                  return (
                    <TableCell key={weekDayIndex} align="center">
                      <EntryField
                        id={`week-day-${weekDayIndex + 1}`}
                        inputProps={
                          timesheetEntries.projects[projectIndex]
                            .projectType === ProjectType.HOLIDAY ||
                          weekDayIndex + 1 === holiIdx ||
                          moment(startDate).isAfter(
                            `${year[weekDayIndex]}-${month[weekDayIndex]}-${day[weekDayIndex]}`
                          ) ||
                          moment(endDate).isBefore(
                            `${year[weekDayIndex]}-${month[weekDayIndex]}-${day[weekDayIndex]}`
                          ) ||
                          moment(
                            timesheetEntries.projects[projectIndex].startDate
                          ).isAfter(
                            `${year[weekDayIndex]}-${month[weekDayIndex]}-${day[weekDayIndex]}`
                          ) ||
                          moment(GetProjectEnddate(projectIndex)).isBefore(
                            `${year[weekDayIndex]}-${month[weekDayIndex]}-${day[weekDayIndex]}`
                          )
                            ? { className: classes.inputDisabled }
                            : undefined
                        }
                        value={entry.hours}
                        color={
                          timesheetEntries.projects[projectIndex]
                            .projectType !== ProjectType.REGULAR
                            ? "error"
                            : undefined
                        }
                        error={entry.error}
                        disabled={
                          timesheetEntries.projects[projectIndex]
                            .projectType === ProjectType.HOLIDAY ||
                          weekDayIndex + 1 === holiIdx ||
                          moment(startDate).isAfter(
                            `${year[weekDayIndex]}-${month[weekDayIndex]}-${day[weekDayIndex]}`
                          ) ||
                          moment(endDate).isBefore(
                            `${year[weekDayIndex]}-${month[weekDayIndex]}-${day[weekDayIndex]}`
                          ) ||
                          moment(
                            timesheetEntries.projects[projectIndex].startDate
                          ).isAfter(
                            `${year[weekDayIndex]}-${month[weekDayIndex]}-${day[weekDayIndex]}`
                          ) ||
                          moment(GetProjectEnddate(projectIndex)).isBefore(
                            `${year[weekDayIndex]}-${month[weekDayIndex]}-${day[weekDayIndex]}`
                          )
                        }
                        onChange={(hours) =>
                          timesheetEntries.onChange({
                            projectId:
                              timesheetEntries.projects[projectIndex].projectId,
                            weekDay: weekDayIndex + 1,
                            hours,
                          })
                        }
                      />
                    </TableCell>
                  );
                })}
              </TableRow>
            ) : null
          )}
          <TableRow>
            <TableCell>
              {timesheetEntries.showPrefill ? (
                <Button
                  id="suggest-hours"
                  disabled={timesheetEntries.showSuggested}
                  onClick={timesheetEntries.prefillFunction}
                  variant="contained"
                  style={{ marginRight: "10px" }}
                >
                  SUGGEST HOURS
                </Button>
              ) : undefined}
              <Button
                id="clear"
                disabled={timesheetEntries.disabledAll}
                onClick={timesheetEntries.onReset}
              >
                CLEAR
              </Button>
            </TableCell>
            <TableCell colSpan={5}>{timesheetEntries.message}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  ) : !(isApproved || isSubmitted) || isDraft ? (
    <TableContainer>
      <div className={classes.styleNoProjects}>
        <img src="project.svg" alt="Project logo" />
        No projects
      </div>
    </TableContainer>
  ) : null;
};
