import { createStore, applyMiddleware, Store } from "redux";
import { Persistor, persistStore } from "redux-persist";
import initSubscriber, { Subscribe } from "redux-subscriber";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import thunkMiddleware from "redux-thunk";
import { routerMiddleware } from "connected-react-router";
import { History } from "history";

import createRootReducer, { RootState } from "./reducers";

export type StoreBase = {
  store: Store<RootState>;
  persistor: Persistor;
};

export default class StoreManager {
  static store: Store<RootState> | null;
  static subscriber: Subscribe | null;

  static getStore(): Store<RootState> {
    if (StoreManager.store === null) {
      throw new Error("Store not configured");
    }
    return StoreManager.store;
  }

  static getSubscriber(): Subscribe {
    if (StoreManager.subscriber === null) {
      throw new Error("Store not configured");
    }
    return StoreManager.subscriber;
  }

  static configureStore(history: History<any>): StoreBase {
    const composeEnhancers = composeWithDevTools({});

    const store = createStore(
      createRootReducer(history),
      composeEnhancers(
        applyMiddleware(thunkMiddleware, routerMiddleware(history))
      )
    );
    const persistor = persistStore(store);
    StoreManager.store = store;
    StoreManager.subscriber = initSubscriber(store);

    return {
      store,
      persistor,
    };
  }
}
