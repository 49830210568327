import React from "react";
import {
  Container,
  createStyles,
  IconButton,
  Grid,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { RemoteDataContainer } from "../../component/RemoteDataContainer";
import { Title } from "../../component/Title";
import ProjectApi, { ProjectItem } from "../../data/project";
import { ProjectsComponent } from "../../component/Project/ProjectsComponent";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      display: "flex",
      justifyContent: "space-between",
    },
    cell: {
      verticalAlign: "top",
    },
  })
);


const Projects = ({
  selectProject,
  projects
}: {
  selectProject: (id: string) => void;
  projects: ProjectItem[];
}) => {
  return (
    <ProjectsComponent selectProject={selectProject} projects={projects} />
  );
};

export const ProjectList = withRouter(({ history }: RouteComponentProps) => {
  const classes = useStyles();

  const selectProject = (id: string) => {
    history.push(`/admin/projects/${id}`);
  };

  return (
    <Grid container>
      <Grid container item xs={9}>
        <RemoteDataContainer
          fetch={ProjectApi.getProjects}
          errorMessage={() => "Failed loading projects"}
          renderData={(projects) => (
            <Container>
              <div className={classes.header}>
                <Title>Projects</Title>
                <IconButton
                  id="add-project"
                  color="primary"
                  onClick={() => selectProject("new")}
                >
                  <AddIcon />
                </IconButton>
              </div>
              <Projects selectProject={selectProject} projects={projects} />
            </Container>
          )}
        />
      </Grid>
    </Grid>
  );
});
