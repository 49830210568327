import axios, { AxiosProgressEvent, RawAxiosRequestHeaders, AxiosResponse } from "axios";
import Logger from "../Logger";
import { formatTimeLeft } from "../utils";
import config from "../../config";
import { ErrorCode } from "./ErrorCode";
import AuthService from "../AuthService";

export interface RequestOptions {
  withController?: boolean;
  progressCallback?: (event: AxiosProgressEvent) => any;
  headers?: RawAxiosRequestHeaders;
  signal?: AbortSignal;
  withRefresh?: boolean;
}

interface RestClientProps {
  client: any;
  apiUrl: string;
  name: string;
}

interface APIResponse {
  data: any;
  message: string;
  response: any;
  rest: any;
}

interface Error {
  errorCode?: any;
  message: string;
  type: string;
}

class RestClient {
  private readonly apiUrl: string;
  private readonly client: any;
  private logger: Logger;

  constructor({ client, apiUrl, name }: RestClientProps) {
    this.apiUrl = apiUrl;
    this.client = client;
    this.logger = new Logger("RestClient-" + name);
  }

  post<T>(route: string, data?: any, options?: RequestOptions) {
    return this.makeApiCall<T>(route, "POST", data, options);
  }

  get<T>(route: string, data?: any, options?: RequestOptions) {
    return this.makeApiCall<T>(route, "GET", data, options);
  }

  put<T>(route: string, data?: any, options?: RequestOptions) {
    return this.makeApiCall<T>(route, "PUT", data, options);
  }

  patch<T>(route: string, data: any, options?: RequestOptions) {
    return this.makeApiCall<T>(route, "PATCH", data, options);
  }

  delete<T>(route: string, data?: any, options?: RequestOptions) {
    return this.makeApiCall<T>(route, "DELETE", data, options);
  }

  async refreshToken(): Promise<string> {
    const { token, user } = await this.makeApiCall(
      "/token",
      "GET",
      null,
      undefined,
      false
    );
    config.apiToken = token;
    return token;
  }

  isSecuredRoute(route: string): boolean {
    const publicRoutes = ["/login", "/register", "/reset-password"];
    return (
      publicRoutes.filter((publicRoute) => route.trim() === publicRoute)
        .length === 0
    );
  }

  async makeApiCall<T>(
    route: string,
    requestType: string,
    data?: any,
    options: RequestOptions = {},
    withRefresh = true
  ): Promise<T> {
    let headers: any = {};
    let token = config.apiToken;
    const { progressCallback } = options;

    if (token && this.isSecuredRoute(route)) {
      const ms = AuthService.msTillExpiration(token);

      const text = formatTimeLeft(ms);
      this.logger.trace(text);

      if (ms < 600000 && withRefresh && this.client) {
        try {
          token = await this.refreshToken();
        } catch (e: any) {
          this.logger.warn(
            "Failed to refresh jwt token, logging out client. Error: %o",
            e
          );
          this.client
            .logout()
            .catch((e: any) =>
              this.logger.error("Failed to logout. Error: %o", e)
            );
          throw e;
        }
      }
      headers["Authorization"] = "Bearer " + token;
    }

    if (this.apiUrl === undefined) {
      throw new Error(
        "API base url not set. Please set the API base url using the API_BASE_URL environment variable."
      );
    }

    return this.makeAjaxCall<T>(
      this.apiUrl + route,
      requestType,
      data,
      headers,
      progressCallback
    )
      .then(({ data }) => {
        return data;
      })
      .catch(({ message, response }: APIResponse) => {
        if (response?.data?.errorCode === ErrorCode.INVALID_JWT_TOKEN) {
          this.logger.error(
            "invalid jwt token. removing auth data and reloading"
          );

          if (AuthService.isAuthenticated()) {
            config.apiToken = undefined;
          }

          window.location.reload();
        }

        throw this.getErrorData(message, response);
      });
  }

  makeAjaxCall<T>(
    url: string,
    requestType: string,
    data?: any,
    headers = {},
    progressCallback?: (event: AxiosProgressEvent) => void
  ): Promise<AxiosResponse<T>> {
    const method = requestType ? requestType : "GET";
    this.logger.trace(`Api request: ${method} ${url}`);

    return axios<T>({
      url: url,
      method,
      data: data && requestType !== "GET" ? data : null,
      params: data && requestType === "GET" ? data : null,
      headers: headers,
      onUploadProgress: (event) => {
        if (progressCallback) {
          progressCallback(event);
        }
      },
    });
  }

  getErrorData(message: string, response: any): Record<string, unknown> {
    const type = "Network Error";

    if (!response) {
      message =
        !message || message === "Network Error"
          ? "Network unavailable"
          : message;
      return {
        message,
        error: {
          type,
          message,
        },
      };
    }

    const { data, status: httpCode } = response;

    let error: Error = {
      type,
      message: "",
    };

    let errorCode;
    if (data && typeof data === "object" && data.message) {
      error.message = data.message;
      error.errorCode = data.errorCode;
      errorCode = data.errorCode;
    } else if (typeof data === "string") {
      error.message = data;
    } else {
      error.message = message || "Unknown error";
    }

    const config = response.config || {};
    const { url } = config;

    return {
      type,
      message: error.message,
      error,
      errorCode,
      httpCode,
      url,
    };
  }
}

export default RestClient;
