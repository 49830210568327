import React from "react";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core";

import { Typography } from "@material-ui/core";
import { UserDocumentType } from "../data/userDocument";

interface SelectedInputProps {
  label?: string;
  value: string | null;
  values: { [key: string]: string };
  onChange?: (value: string) => void;
  maxWidth?: string;
  small?: boolean;
  disabled?: boolean;
  onClose?: () => void;
  error?: string;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    spanStyle: {
      width: "100%",
    },
  })
);

export const DropdownWithTooltip = (props: SelectedInputProps) => {
  const classes = useStyles();
  return (
    <FormControl
      variant="outlined"
      fullWidth
      size={props.small ? "small" : undefined}
      disabled={!props.onChange || props.disabled}
      style={{ maxWidth: props.maxWidth }}
      error={!!props.error}
    >
      {props.label && <InputLabel>{props.label}</InputLabel>}

      <Select
        value={props.value || ""}
        label={props.label}
        onChange={(event) =>
          props.onChange && props.onChange(event.target.value as string)
        }
        onClose={props.onClose}
      >
        {Object.keys(props.values).map((key) => {
          switch (key) {
            case UserDocumentType.PROFILEPHOTO:
              return (
                <MenuItem key={key} value={key}>
                  <Tooltip
                    title={
                      <>
                        <Typography color="inherit">
                          {" "}
                          For the best result please take a portrait picture
                          that includes the shoulders at eye level and with
                          sufficient lighting against a white (or even)
                          background.{" "}
                        </Typography>
                      </>
                    }
                    placement="left-end"
                    arrow
                  >
                    <span className={classes.spanStyle}>
                      {" "}
                      {props.values[key]}{" "}
                    </span>
                  </Tooltip>
                </MenuItem>
              );
            case UserDocumentType.PASSPORTORIDCARD:
              return (
                <MenuItem key={key} value={key}>
                  <Tooltip
                    title={
                      <>
                        <Typography color="inherit">
                          Upload a photocopy of the front and backside of your
                          ID card or Passport. Please bring this document to the
                          office on the first occasion for a mandatory ID check.
                        </Typography>
                      </>
                    }
                    placement="left-end"
                    arrow
                  >
                    <span className={classes.spanStyle}>
                      {" "}
                      {props.values[key]}
                    </span>
                  </Tooltip>
                </MenuItem>
              );

            case UserDocumentType.UNSIGNEDEMPLOYMENTCONTRACT:
              return (
                <MenuItem key={key} value={key}>
                  <Tooltip
                    title={
                      <>
                        <Typography color="inherit">
                          Upload the employment contract for this employee.
                        </Typography>
                      </>
                    }
                    placement="left-end"
                    arrow
                  >
                    <span className={classes.spanStyle}>
                      {" "}
                      {props.values[key]}
                    </span>
                  </Tooltip>
                </MenuItem>
              );
            case UserDocumentType.SIGNEDEMPLOYMENTCONTRACT:
              return (
                <MenuItem key={key} value={key}>
                  <Tooltip
                    title={
                      <>
                        <Typography color="inherit">
                          {" "}
                          Click the attachment button corresponding to{" "}
                          <em> unsigned employment contract </em> in the section
                          below to download your contract and upload a signed
                          copy.
                        </Typography>
                      </>
                    }
                    placement="left-end"
                    arrow
                  >
                    <span className={classes.spanStyle}>
                      {" "}
                      {props.values[key]}
                    </span>
                  </Tooltip>
                </MenuItem>
              );
            case UserDocumentType.UNSIGNEDLOONHEFFINGSVERKLARING:
              return (
                <MenuItem key={key} value={key}>
                  <Tooltip
                    title={
                      <>
                        <Typography color="inherit">
                          Upload the Loonheffingsverklaring for this employee.
                        </Typography>
                      </>
                    }
                    placement="left-end"
                    arrow
                  >
                    <span className={classes.spanStyle}>
                      {" "}
                      {props.values[key]}
                    </span>
                  </Tooltip>
                </MenuItem>
              );
            case UserDocumentType.SIGNEDLOONHEFFINGSVERKLARING:
              return (
                <MenuItem key={key} value={key}>
                  <Tooltip
                    title={
                      <>
                        <Typography color="inherit">
                          Click here to download the form and upload the
                          completed and signed copy.
                        </Typography>
                      </>
                    }
                    placement="left-end"
                    onClick={() =>
                      window.open(
                        "https://download.belastingdienst.nl/belastingdienst/docs/model_opgaaf_gegevens_loonheffingen_lh0082z9fol.pdf"
                      )
                    }
                    arrow
                  >
                    <span className={classes.spanStyle}>
                      {" "}
                      {props.values[key]}
                    </span>
                  </Tooltip>
                </MenuItem>
              );
            case UserDocumentType.LATESTCURRICULUMVITAE:
              return (
                <MenuItem key={key} value={key}>
                  <Tooltip
                    title={
                      <>
                        <Typography color="inherit">
                          Upload your latest CV, all formats are allowed.
                        </Typography>
                      </>
                    }
                    placement="left-end"
                    arrow
                  >
                    <span className={classes.spanStyle}>
                      {" "}
                      {props.values[key]}
                    </span>
                  </Tooltip>
                </MenuItem>
              );
            default:
              return (
                <MenuItem key={key} value={key}>
                  <Tooltip
                    title={
                      <>
                        <Typography color="inherit">{props.label}</Typography>
                      </>
                    }
                    placement="left-end"
                    arrow
                  >
                    <span className={classes.spanStyle}>
                      {" "}
                      {props.values[key]}
                    </span>
                  </Tooltip>
                </MenuItem>
              );
          }
        })}
      </Select>

      <FormHelperText>{props.error}</FormHelperText>
    </FormControl>
  );
};
