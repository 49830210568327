import {getAxios} from "../../../data/axios";
import {HolidayAllowanceWithUserCollection} from "../../../data/holiday-allowance";

export default class HolidayAllowanceClient {
    static async getHolidayAllowances(): Promise<HolidayAllowanceWithUserCollection> {
        let response = await getAxios().get<HolidayAllowanceWithUserCollection>(`/holiday-allowances`);
        return response.data;
    }

    static async retrieveExport(): Promise<Blob> {
        let response = await getAxios().get<Blob>(`/holiday-allowances/export`);
        return response.data;
    }
}