import { AppDataGrid, AppDataGridProps } from "./AppDataGrid";
import { TotalsGridFooter } from "./TotalsGridFooter";

export type TotalsDataGridProps = AppDataGridProps & {
  /**
   * Set whether or not to show the totals
   */
  showTotals?: boolean;

  /**
   * Set the column which to render the "Totals" key in, defaults to the first column.
   */
  totalsField?: string;

  /**
   * The text to display when there's a column which cannot be summed, or if there's no rows. Defaults to an empty string.
   */
  emptyColumnsText?: string;

  setShowRevertButton: React.Dispatch<React.SetStateAction<boolean>>;
};

export const TotalsDataGrid = (props: TotalsDataGridProps) => {
  const {
    columns,
    rows,
    components,
    componentsProps,
    setShowRevertButton,
    ...otherProps
  } = props;

  // Add totals column
  //columns.unshift(totalsColDef);
  const totalsfield = columns.length > 0 ? columns[0].field : null;

  // Overwrite the component (props) if any are given
  const componentsNew = components ?? {};
  componentsNew.Footer = TotalsGridFooter;

  const componentsPropsNew = componentsProps ?? {};
  componentsPropsNew.footer = {
    showTotals: props.showTotals ?? true,
    totalsField: props.totalsField ?? totalsfield,
    emptyColumnsText: props.emptyColumnsText,
  };

  return (
    <AppDataGrid
      columns={columns}
      rows={rows}
      components={componentsNew}
      componentsProps={componentsPropsNew}
      setShowRevertButton={setShowRevertButton}
      {...otherProps}
    />
  );
};
