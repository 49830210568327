import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  createStyles,
  makeStyles,
  Grid,
  Container,
  Divider,
  Theme,
  Select,
  ListSubheader,
  MenuItem,
  FormControl,
  Button,
  ButtonGroup,
  CircularProgress,
} from "@material-ui/core";

import { RemoteDataContainer } from "../../component/RemoteDataContainer";
import { Title } from "../../component/Title";
import WeekReportApi, { WeekReportFilter } from "../../data/weekReport";
import UserApi, { SimpleUserItem } from "../../data/user";
import { WeekReports } from "../../component/WeekReports";
import {
  useCurrentYearAndWeek,
  addWeeks,
  getWeeksInYear,
} from "../../hooks/dates";
import { Dropdown } from "../../component/Dropdown";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";
import { Account } from "../../data/principal";
import NmbrsApi from "./../../data/nmbrs";
import { useLocation } from "react-router-dom";

const startYear = 2019;
const startWeek = 45;

interface WeekPickerProps {
  weeks: { [key: number]: number[] };
  value: { year: number; weekNumber: number };
  onChange: (value: { year: number; weekNumber: number }) => void;
}

export const WeekPicker = ({ weeks, value, onChange }: WeekPickerProps) => {
  return (
    <FormControl
      variant="outlined"
      fullWidth
      size="small"
      style={{ maxWidth: "10em" }}
    >
      <Select
        value={`${value.year}-${value.weekNumber}`}
        onChange={(event) => {
          const [year, weekNumber] = (event.target.value as string)
            .split("-")
            .map((v) => +v);
          onChange({ year, weekNumber });
        }}
      >
        {Object.keys(weeks)
          .reverse()
          .map((year: any) =>
            [...weeks[year], 0].reverse().map((week: any) =>
              week === 0 ? (
                <ListSubheader key={week}>{year}</ListSubheader>
              ) : (
                <MenuItem key={week} value={`${year}-${week}`}>
                  Week {week + " - " + year}
                </MenuItem>
              )
            )
          )}
      </Select>
    </FormControl>
  );
};

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filters: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      marginTop: "1em",
      marginBottom: "1.5em",
    },
    filterGlue: {
      marginLeft: "1em",
      marginRight: "1em",
    },
    whiteToBlackButton: {
      "&:hover": {
        backgroundColor: "#000000",
        color: "#FFFFFF",
      },
    },
    spinner: {
      marginLeft: "50%",
    },
    spinnerContainer: {
      position: "relative",
    },
  })
);

const range = (start: number, end: number) =>
  start <= end
    ? [...new Array(end - start + 1)].map((_, index) => index + start)
    : [];

export const getWeeks = ({
  year,
  weekNumber,
}: {
  year: number;
  weekNumber: number;
}) => {
  const weekMap = {} as { [key: number]: number[] };
  if (year < startYear || (year === startYear && weekNumber < startWeek)) {
    return weekMap;
  }
  if (year > startYear) {
    weekMap[startYear] = range(startWeek, getWeeksInYear(startYear));
    range(startYear + 1, year - 1).forEach((y) => {
      weekMap[y] = range(1, getWeeksInYear(y));
    });
    weekMap[year] = range(1, weekNumber);
  } else if (year === startYear) {
    weekMap[startYear] = range(startWeek, weekNumber);
  }
  return weekMap;
};

export const WeekReportHistory = () => {
  const location = useLocation();
  const classes = useStyles();
  const account = useSelector<RootState>(
    (state) => state.auth.currentAccount
  ) as Account | null;

  const currentYearAndWeek = useCurrentYearAndWeek();
  const [filter, setFilter] = useState<Partial<WeekReportFilter>>({});
  const [users, setUsers] = useState<SimpleUserItem[]>([]);
  const [syncingNmbrs, setSyncingNmbrs] = useState<boolean>(false);

  useEffect(() => {
    UserApi.getUsers().then(setUsers);
  }, []);

  useEffect(() => {
    const stringify = JSON.stringify(location.state);
    if (stringify === undefined) return;
    const data = JSON.parse(stringify);
    console.log(data);
    setFilter({
      userId: data.id,
      fromWeekNumber: data.week,
      fromYear: data.year,
      toWeekNumber: data.week,
      toYear: data.year,
    });
  }, [location]);

  const weeks = useMemo(() => {
    if (!currentYearAndWeek) {
      return [];
    }
    return getWeeks(currentYearAndWeek);
  }, [currentYearAndWeek]);

  const effectiveFilter = useMemo(() => {
    if (!currentYearAndWeek) {
      return null;
    }
    const yearAndWeek = addWeeks(currentYearAndWeek, -1);
    const baseFilter = yearAndWeek
      ? {
          fromYear: yearAndWeek.year,
          fromWeekNumber: yearAndWeek.weekNumber,
          toYear: yearAndWeek.year,
          toWeekNumber: yearAndWeek.weekNumber,
        }
      : ({} as WeekReportFilter);
    return {
      ...baseFilter,
      ...filter,
    };
  }, [currentYearAndWeek, filter]);

  const userOptions = useMemo(() => {
    return users.reduce((agg, user) => {
      agg["" + user.userId] = user.name;
      return agg;
    }, {} as any);
  }, [users]);

  const getWeekReports = useCallback(() => {
    if (!effectiveFilter) {
      return Promise.resolve([]);
    }
    return WeekReportApi.getWeekReports(effectiveFilter);
  }, [effectiveFilter]);

  if (!currentYearAndWeek || !effectiveFilter) {
    return null;
  }

  const getEmployeeInfo = () => {
    NmbrsApi.getEmployeeInfo({
      fromWeekNumber: effectiveFilter.fromWeekNumber,
      toWeekNumber: effectiveFilter.toWeekNumber,
      fromYear: effectiveFilter.fromYear,
      toYear: effectiveFilter.toYear,
    }).then((res) => {
      setSyncingNmbrs(false);
      return res.status;
    });
    setSyncingNmbrs(true);
  };

  const exportWeekReports = () => {
    WeekReportApi.exportWeekReports({
      startWeek: effectiveFilter.fromWeekNumber,
      startYear: effectiveFilter.fromYear,
      endWeek: effectiveFilter.toWeekNumber,
      endYear: effectiveFilter.toYear,
      id: effectiveFilter.userId ? [effectiveFilter.userId] : undefined,
    }).then((data) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute(
        "download",
        `Weekreports ${effectiveFilter.fromYear}wk${effectiveFilter.fromWeekNumber}-${effectiveFilter.toYear}wk${effectiveFilter.toWeekNumber}.csv`
      );
      document.body.appendChild(link);
      link.click();
      link.remove();
    });

    setTimeout(() => {
      WeekReportApi.exportTravelExpenses({
        startWeek: effectiveFilter.fromWeekNumber,
        startYear: effectiveFilter.fromYear,
        endWeek: effectiveFilter.toWeekNumber,
        endYear: effectiveFilter.toYear,
        id: effectiveFilter.userId ? [effectiveFilter.userId] : undefined,
      }).then((data) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute(
          "download",
          `Travel expenses ${effectiveFilter.fromYear}wk${effectiveFilter.fromWeekNumber}-${effectiveFilter.toYear}wk${effectiveFilter.toWeekNumber}.csv`
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
    }, 1000);
  };

  return syncingNmbrs ? (
    <div className={classes.spinnerContainer}>
      <CircularProgress disableShrink className={classes.spinner} />
    </div>
  ) : (
    <Grid container>
      <Grid container item xs={12}>
        <RemoteDataContainer
          fetch={getWeekReports}
          errorMessage={() => "Failed loading week report history"}
          renderData={(weekReports) => (
            <Container>
              <Title>Week report history</Title>
              <Divider />
              <div className={classes.filters}>
                <WeekPicker
                  value={{
                    year: effectiveFilter.fromYear,
                    weekNumber: effectiveFilter.fromWeekNumber,
                  }}
                  weeks={weeks}
                  onChange={({ year, weekNumber }) =>
                    setFilter({
                      ...filter,
                      fromYear: year,
                      fromWeekNumber: weekNumber,
                    })
                  }
                />
                <div className={classes.filterGlue}>to</div>
                <WeekPicker
                  value={{
                    year: effectiveFilter.toYear,
                    weekNumber: effectiveFilter.toWeekNumber,
                  }}
                  weeks={weeks}
                  onChange={({ year, weekNumber }) =>
                    setFilter({
                      ...filter,
                      toYear: year,
                      toWeekNumber: weekNumber,
                    })
                  }
                />
                <div className={classes.filterGlue}>for</div>
                <Dropdown
                  label="All employees"
                  value={effectiveFilter.userId || ""}
                  values={userOptions}
                  onChange={(userId) => setFilter({ ...filter, userId })}
                  small
                  maxWidth="20em"
                />
                {account?.isAdmin ? (
                  <ButtonGroup
                    color="primary"
                    aria-label="outlined primary button group"
                  >
                    <Button
                      className={classes.whiteToBlackButton}
                      onClick={exportWeekReports}
                    >
                      Export
                    </Button>
                    <Button
                      className={classes.whiteToBlackButton}
                      onClick={getEmployeeInfo}
                    >
                      Sync with Nmbrs
                    </Button>
                  </ButtonGroup>
                ) : (
                  ``
                )}
              </div>
              {weekReports.length === 0 && <div>No week reports found</div>}
              {weekReports.length > 0 && (
                <WeekReports
                  presentOnly={true}
                  weekReports={weekReports}
                  users={users}
                />
              )}
            </Container>
          )}
        />
      </Grid>
    </Grid>
  );
};
