import React, { useEffect, useState, useContext } from "react";
import {
  Backdrop,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  Container,
  createStyles,
  FormControl,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Theme,
  Typography,
} from "@material-ui/core";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { Title } from "../../component/Title";
import { TextInput } from "../../component/TextInput";
import { SnackbarContext } from "../../component/SnackbarProvider";
import { useValidator, isNotEmpty, isValidEmail } from "../../hooks/validator";
import { GoogleMapsPlacefinder } from "../../component/GoogleMapsPlacefinder";
import { HttpError } from "../../component/HttpError";
import { Prediction } from "../../data/googleMaps";
import CustomerApi, {
  CreateCustomer,
  CustomerEdit,
  CustomerProject,
  UpdateCustomer,
} from "../../data/customer";
import ProjectApi, { ProjectUser } from "../../data/project";
import { Alert } from "@material-ui/lab";
import { v4 as uuidv4 } from "uuid";

const mandatoryCreateCustomerFields: (keyof CreateCustomer)[] = [
  "name",
  "emailAddress",
  "googleAddressId",
];

const mandatoryUpdateCustomerFields: (keyof UpdateCustomer)[] = [
  "customerId",
  "name",
  "emailAddress",
  "googleAddressId",
];

const mandatoryUseSecondAddressFields: (keyof CustomerEdit)[] = [
  "invoiceEmailAddress",
  "mailAddress",
];

const getValidationMessage = (field: keyof UpdateCustomer): string => {
  switch (field) {
    case "name":
      return "Name must not be empty";
    case "emailAddress":
      return "Email address must be valid";
    case "googleAddressId":
      return "Visiting address must not be empty";
    case "invoiceEmailAddress":
      return "Invoice email address must be valid";
    case "mailAddress":
      return "Mail address must not be empty";
  }
  return "";
};

const getValidationFunction = (
  field: keyof UpdateCustomer
): ((value: any) => boolean) => {
  switch (field) {
    case "name":
      return isNotEmpty;
    case "emailAddress":
      return isValidEmail;
    case "googleAddressId":
      return isNotEmpty;
    case "invoiceEmailAddress":
      return isValidEmail;
    case "mailAddress":
      return isNotEmpty;
  }
  return () => false;
};

const getRequiredFieldsForSettings = (
  isNewCustomer: boolean,
  secondAddress: boolean
): (keyof UpdateCustomer)[] => {
  let requiredFields: (keyof UpdateCustomer)[];
  if (isNewCustomer) {
    requiredFields = mandatoryCreateCustomerFields;
  } else {
    requiredFields = mandatoryUpdateCustomerFields;
  }

  if (secondAddress) {
    requiredFields.concat(mandatoryUseSecondAddressFields);
  }
  return requiredFields;
};

interface RouterMatch {
  id: string;
}

const emptyCustomer: UpdateCustomer = {
  customerId: "",
  name: "",
  emailAddress: "",
  googleAddressId: "",
  googleAddress: "",
  googleSessionToken: uuidv4(),
  invoiceEmailAddress: "",
  mailAddress: "",
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    disableButton: {
      marginLeft: "2em",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    projectsBlock: {
      border: "2px dashed #bbb",
      width: "100%",
      height: "45px",
      paddingTop: "10px",
      paddingBottom: "20px",
      textAlign: "center",
    },
  })
);

export const CustomerItem = withRouter(
  (props: RouteComponentProps<RouterMatch>) => {
    const customerId = props.match.params.id;
    const newCustomer = customerId === "new";

    const [projectEmployees, setProjectEmployees] = useState(
      new Map<String, ProjectUser[]>()
    );

    const [customer, setCustomer] = useState<UpdateCustomer>(emptyCustomer);
    const [customerProjects, setCustomerProjects] = useState<CustomerProject[]>(
      []
    );

    const { showInfo, showError } = useContext(SnackbarContext);
    const [loading, setLoading] = useState<boolean>(true);
    const [disabled, setDisabled] = useState<boolean>(false);
    const [showHttpError, setShowHttpError] = useState(false);
    const [retryAction, setRetryAction] = useState<() => void>();
    const classes = useStyles();
    const [hasAdditionalAddresses, setHasAdditionalAddresses] = useState(false);
    const validator = useValidator<UpdateCustomer>(
      customer,
      getRequiredFieldsForSettings(newCustomer, hasAdditionalAddresses)
    );
    const [validationErrorVisible, setValidationErrorVisible] = useState(false);

    const selectProject = (id: string) => {
      props.history.push(`/admin/projects/${id}`);
    };

    const onChangeCustomerAddress = (value: Prediction | null) => {
      if (value != null) {
        setCustomer({
          ...customer,
          googleAddressId: value.placeId,
          googleAddress: value.description,
        });
        validator.validate(
          "googleAddressId",
          getValidationMessage("googleAddressId"),
          getValidationFunction("googleAddressId"),
          value.placeId
        );
      }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setHasAdditionalAddresses(event.target.checked);
      validator.updateMandatoryInputs(
        getRequiredFieldsForSettings(newCustomer, event.target.checked)
      );
    };

    const createNewProject = (): void => {
      props.history.push(`/admin/projects/new/${customer.customerId}`);
    };

    const saveCustomer = () => {
      setDisabled(true);

      validator.validateMultiple(
        getRequiredFieldsForSettings(newCustomer, hasAdditionalAddresses).map(
          (field) => {
            return {
              field,
              message: getValidationMessage(field),
              valid: getValidationFunction(field),
            };
          }
        )
      );

      if (!validator.isValid) {
        setValidationErrorVisible(true);
        window.scrollTo(0, 0);
        setDisabled(false);
        return;
      }

      setValidationErrorVisible(false);
      let action = () => {
        setDisabled(true);
        setShowHttpError(false);
        let request = newCustomer
          ? CustomerApi.createCustomer({
              name: customer.name,
              emailAddress: customer.emailAddress,
              googleAddressId: customer.googleAddressId,
              googleAddress: customer.googleAddress,
              googleSessionToken: customer.googleSessionToken,
              invoiceEmailAddress: customer.invoiceEmailAddress,
              mailAddress: customer.mailAddress,
            })
          : CustomerApi.updateCustomer({
              customerId: customer.customerId,
              name: customer.name,
              emailAddress: customer.emailAddress,
              googleAddressId: customer.googleAddressId,
              googleAddress: customer.googleAddress,
              googleSessionToken: customer.googleSessionToken,
              invoiceEmailAddress: customer.invoiceEmailAddress,
              mailAddress: customer.mailAddress,
            });
        request.then(
          (res) => {
            showInfo(`${newCustomer ? `Created` : `Updated`} customer`);
            props.history.push("/admin/customers");
          },
          (err) => {
            if (err.message !== "Network Error") {
              showError(
                `Failed to ${newCustomer ? `create` : `update`} customer: \n${
                  err.response.data
                }`
              );
            } else {
              setShowHttpError(true);
            }
            setDisabled(false);
          }
        );
      };
      setRetryAction(() => action);
      action();
    };

    const disableCustomer = () => {
      setDisabled(true);
      CustomerApi.disableCustomer({ customerId: customer.customerId }).then(
        (res) => {
          showInfo("Disabled customer");
          props.history.push("/admin/customers");
        },
        (err) => {
          showError("Failed to disable customer");
          setDisabled(false);
        }
      );
    };

    useEffect(() => {
      if (loading) {
        const updateEmployees = (k: string, v: ProjectUser[]) => {
          setProjectEmployees((p) => p.set(k, v));
        };

        if (newCustomer) {
          setLoading(false);
          setCustomer({
            ...customer,
            googleSessionToken: uuidv4(),
          });
        } else {
          CustomerApi.getCustomer(customerId).then(
            (res) => {
              setCustomer({
                ...customer,
                customerId: res.customerId,
                name: res.name,
                emailAddress: res.emailAddress,
                googleAddressId: res.visitingAddressId,
                googleAddress: res.visitingAddress,
                googleSessionToken: uuidv4(),
                invoiceEmailAddress: res.invoiceEmailAddress,
                mailAddress: res.mailAddress,
              });
              setCustomerProjects(res.projects || []);
              setLoading(false);
              let hasAdditionalAddresses: boolean =
                res.invoiceEmailAddress !== undefined ||
                res.mailAddress !== undefined;
              if (hasAdditionalAddresses) {
                setHasAdditionalAddresses(true);
              }
              if (res.projects) {
                ProjectApi.getProjects().then((projects) => {
                  res.projects?.forEach((customerProject) => {
                    const foundProject = projects.find(
                      (project) =>
                        project.projectId === customerProject.projectId
                    );
                    if (foundProject?.employees) {
                      updateEmployees(
                        customerProject.projectId,
                        foundProject?.employees
                      );
                    }
                  });
                });
              }
              validator.updateMandatoryInputs(
                getRequiredFieldsForSettings(
                  newCustomer,
                  hasAdditionalAddresses
                ),
                true
              );
            },
            (err) => {
              showError("Failed to load customer");
              setLoading(false);
            }
          );
        }
      }
    }, [newCustomer, customer, customerId, showError, validator, loading]);

    return (
      <Grid container>
        <Grid container item xs={9}>
          <Container>
            <HttpError
              open={showHttpError}
              close={() => setShowHttpError(false)}
              retry={() => {
                retryAction && retryAction();
              }}
            />
            {validationErrorVisible && (
              <Alert
                severity="error"
                onClose={() => setValidationErrorVisible(false)}
              >
                Please correct the errors in your form and submit again.
              </Alert>
            )}
            <Title>{newCustomer ? "New" : "Edit"} customer</Title>
            <List>
              <ListItem>
                <TextInput
                  id="customer-name"
                  label="Name"
                  value={customer.name}
                  onChange={(name) => setCustomer({ ...customer, name })}
                  error={validator.get("name")}
                  onBlur={() =>
                    validator.validate(
                      "name",
                      getValidationMessage("name"),
                      getValidationFunction("name")
                    )
                  }
                />
              </ListItem>
              <ListItem
                divider
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Typography variant="subtitle2">Project(s)</Typography>
                <Button
                  id="new-project-button"
                  disabled={disabled}
                  onClick={createNewProject}
                  style={{ marginLeft: "auto" }}
                >
                  NEW PROJECT
                </Button>
              </ListItem>
              <ListItem>
                {customerProjects.length > 0 ? (
                  <Table>
                    <TableBody>
                      {customerProjects.map((project) => (
                        <TableRow
                          key={project.projectId}
                          hover
                          onClick={() => selectProject(project.projectId)}
                          id={project.name}
                        >
                          <TableCell id={project.name}>
                            <div>{project.name}</div>
                          </TableCell>
                          <TableCell>
                            {projectEmployees
                              .get(project.projectId)
                              ?.map((employee) => {
                                return (
                                  <div id={employee.userName} key={employee.userId}>
                                    {employee.userName}
                                  </div>
                                );
                              })}
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                ) : (
                  <FormControl className={classes.projectsBlock}>
                    No projects
                  </FormControl>
                )}
              </ListItem>
              <ListItem>
                <TextInput
                  id="customer-email-address"
                  label="Email address"
                  value={customer.emailAddress}
                  onChange={(emailAddress) =>
                    setCustomer({ ...customer, emailAddress })
                  }
                  error={validator.get("emailAddress")}
                  onBlur={() =>
                    validator.validate(
                      "emailAddress",
                      getValidationMessage("emailAddress"),
                      getValidationFunction("emailAddress")
                    )
                  }
                />
              </ListItem>
              <ListItem>
                <GoogleMapsPlacefinder
                  id="customer-address"
                  label="Address"
                  value={
                    customer.googleAddressId !== undefined &&
                    customer.googleAddress !== undefined
                      ? {
                          placeId: customer.googleAddressId,
                          description: customer.googleAddress,
                        }
                      : undefined
                  }
                  sessionToken={customer.googleSessionToken}
                  onSelectPrediction={onChangeCustomerAddress}
                  error={validator.get("googleAddressId")}
                  onBlur={() =>
                    validator.validate(
                      "googleAddressId",
                      getValidationMessage("googleAddressId"),
                      getValidationFunction("googleAddressId")
                    )
                  }
                />
              </ListItem>
              <ListItem>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="customer-additional-addresses"
                      checked={hasAdditionalAddresses}
                      onChange={handleChange}
                      name="Additional addresses"
                    />
                  }
                  label="Mail address is different from visiting address"
                />
              </ListItem>
              <Collapse in={hasAdditionalAddresses}>
                <ListItem>
                  <TextInput
                    id="customer-invoice-email-address"
                    label="Invoice email address"
                    value={customer.invoiceEmailAddress || ""}
                    onChange={(invoiceEmailAddress) =>
                      setCustomer({ ...customer, invoiceEmailAddress })
                    }
                    error={validator.get("invoiceEmailAddress")}
                    onBlur={() =>
                      validator.validate(
                        "invoiceEmailAddress",
                        getValidationMessage("invoiceEmailAddress"),
                        getValidationFunction("invoiceEmailAddress")
                      )
                    }
                  />
                </ListItem>
                <ListItem>
                  <TextInput
                    id="customer-mail-address"
                    label="Mail address"
                    value={customer.mailAddress || ""}
                    onChange={(mailAddress) =>
                      setCustomer({ ...customer, mailAddress })
                    }
                    error={validator.get("mailAddress")}
                    onBlur={() =>
                      validator.validate(
                        "mailAddress",
                        getValidationMessage("mailAddress"),
                        getValidationFunction("mailAddress")
                      )
                    }
                  />
                </ListItem>
              </Collapse>
              <ListItem>
                <Button
                  id="customer-save-button"
                  variant="contained"
                  color="primary"
                  onClick={saveCustomer}
                  disabled={disabled}
                >
                  Save
                </Button>
                {!newCustomer && (
                  <Button
                    id="customer-disable-button"
                    onClick={disableCustomer}
                    disabled={disabled}
                    className={classes.disableButton}
                  >
                    Disable
                  </Button>
                )}
              </ListItem>
              <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
              </Backdrop>
            </List>
          </Container>
        </Grid>
      </Grid>
    );
  }
);
