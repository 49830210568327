export enum StatisticType {
  TIMESHEET_TIGER = "TIMESHEET_TIGER",
  SUBMIT_SLACKER = "SUBMIT_SLACKER",
  LAZY_LOGGER = "LAZY_LOGGER",
}

export class StatisticTypeNames {
  static getDisplayName(type: StatisticType): string {
    switch (type) {
      case StatisticType.TIMESHEET_TIGER:
        return "Timesheet tiger";
      case StatisticType.SUBMIT_SLACKER:
        return "Submit slacker";
      case StatisticType.LAZY_LOGGER:
        return "Lazy logger";
    }
  }
}
