import { Container, Divider, Grid } from "@material-ui/core";

import { RemoteDataContainer } from "../../component/RemoteDataContainer";
import { Title } from "../../component/Title";
import WeekReportApi from "../../data/weekReport";
import { WeekReports } from "../../component/WeekReports";

export const WeekReportHistory = () => {



  return (
    <Grid container>
      <Grid container item xs={12}>
        <RemoteDataContainer
          fetch={WeekReportApi.getUserWeekReports}
          errorMessage={() => "Failed loading week report history"}
          renderData={(weekReports) => (
            <Container>
              <Title>Week report history</Title>
              <Divider />
              {weekReports.length === 0 && <div>No week reports found</div>}
              {weekReports.length > 0 && (
                <WeekReports presentOnly={true} weekReports={weekReports} />
              )}
            </Container>
          )}
        />
      </Grid>
    </Grid>
  );
};
