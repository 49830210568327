/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Backdrop,
  createStyles,
  makeStyles,
  Theme,
  Select,
  MenuItem,
  FormControl,
  CircularProgress,
  InputLabel,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { useCurrentYearAndWeek, addWeeks } from "../../hooks/dates";
import WeekReportApi, {
  WeekReportFilter,
  WeekReportItem,
} from "../../data/weekReport";
import ProjectApi, { BillingCycles, ProjectItem, ProjectUser } from "../../data/project";

import { WeekPicker, getWeeks } from "../admin/WeekReportHistory";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      display: "flex",
      justifyContent: "space-between",
    },
    cell: {
      verticalAlign: "top",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  })
);

interface userHours {
  userName: string;
  userId: string;
  weekAndHours: weekAndHours[];
}

interface weekAndHours {
  hours: number;
  week: number;
  year: number;
  reportPresent: boolean;
}

export const BillingTool = () => {
  let history = useHistory();
  const [selectedProject, setSelectedProject] = useState<ProjectItem>();
  const [projects, setProjects] = useState<ProjectItem[]>([]);
  const [users, setUsers] = useState<ProjectUser[]>([]);
  const [weekReports, setWeekReports] = useState<WeekReportItem[]>([]);
  const [loadingReports, setLoadingReports] = useState<boolean>(true);
  const [loadingProjects, setLoadingProjects] = useState<boolean>(true);
  const currentYearAndWeek = useCurrentYearAndWeek();
  const classes = useStyles();
  const [filter, setFilter] = useState<Partial<WeekReportFilter>>({});

  useEffect(() => {

    //updates projects for dropdown
    ProjectApi.getActiveProjects().then((res) => {
      setProjects(res);
      setLoadingProjects(false);
      localStorage.setItem("projects", JSON.stringify(res));
    });

    //localstorage stuff
    let stringFilter = localStorage.getItem("filter");
    if (stringFilter !== null && stringFilter !== undefined) {
      setFilter(JSON.parse(stringFilter));
    }

    let stringUsers = localStorage.getItem("users");
    if (stringUsers !== null && stringUsers !== undefined) {
      setUsers(JSON.parse(stringUsers));
    }

    let stringProject = localStorage.getItem("project");
    if (stringProject !== null && stringProject !== undefined) {
      setSelectedProject(JSON.parse(stringProject));
    }

    let stringProjects = localStorage.getItem("projects");
    if (stringProjects !== null && stringProjects !== undefined) {
      setProjects(JSON.parse(stringProjects));
      setLoadingProjects(false);
    }
    
  }, []);

  useEffect(() => {
    if(filter.fromYear === undefined) {
      setLoadingReports(false);
      return;
    }
    setLoadingReports(true);
    let reps = localStorage.getItem("reports");
    if(reps !== null && localStorage.getItem("filter") === JSON.stringify(filter)){
      setWeekReports(JSON.parse(reps));
      setLoadingReports(false);
      return;
    }
    getWeekReports().then((res) => {
      if(effectiveFilter){
        setWeekReports(res);
        localStorage.setItem("reports", JSON.stringify(res));
        setLoadingReports(false);
      }
    });
    localStorage.setItem("filter", JSON.stringify(filter));
  }, [filter]);

  const handleProjectChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    var id = event.target.value;
    const p = projects.filter((p) => p.projectId === id)[0];
    setSelectedProject(p);
    localStorage.setItem("project", JSON.stringify(p));
    localStorage.setItem("users", JSON.stringify(p.employees));
    setUsers(p.employees);
  };

  const UsersReports = (weekReports: WeekReportItem[]): userHours[] => {
    let userHours: userHours[] = [];
    if (!users) {
      return userHours;
    }
    users.forEach((user) => {
      let weekAndHours: weekAndHours[] = [];
      selectedWeeks().forEach((sel) => {
        let sum = 0;
        let reportFound = false;
        let year = 0;
        weekReports.forEach((weekReport) => {
          if (
            weekReport.userId !== user.userId ||
            weekReport.weekNumber !== sel
          ) {
            return;
          }
          reportFound = true;
          year = weekReport.year;
          weekReport.entries?.forEach((e) => {
            if (e.project === selectedProject?.name) {
              sum += e.hours;
            }
          });
        });
        weekAndHours.push({
          hours: sum,
          week: sel,
          year: year,
          reportPresent: reportFound,
        });
      });
      userHours.push({
        userName: user.userName,
        userId: user.userId,
        weekAndHours: weekAndHours,
      });
    });
    return userHours;
  };

  function handleCellClick(id: string, week: number, year: number) {
    if (year === 0) {
      window.alert("Week report doesn't exist");
      return;
    }
    history.push("/admin/weekreporthistory", {
      id: id,
      week: week,
      year: year,
    });
  }

  const selectedWeeks = (): number[] => {
    let arr: number[] = [];
    if (effectiveFilter === undefined || effectiveFilter === null) {
      return arr;
    }
    arr.push(effectiveFilter.fromWeekNumber);
    arr.push(
      addWeeks(
        {
          year: effectiveFilter.fromYear,
          weekNumber: effectiveFilter.fromWeekNumber,
        },
        1
      ).weekNumber
    );
    arr.push(
      addWeeks(
        {
          year: effectiveFilter.fromYear,
          weekNumber: effectiveFilter.fromWeekNumber,
        },
        2
      ).weekNumber
    );
    arr.push(
      addWeeks(
        {
          year: effectiveFilter.fromYear,
          weekNumber: effectiveFilter.fromWeekNumber,
        },
        3
      ).weekNumber
    );
    return arr;
  };

  const weeks = useMemo(() => {
    if (!currentYearAndWeek) {
      return [];
    }
    return getWeeks(currentYearAndWeek);
  }, [currentYearAndWeek]);

  const effectiveFilter = useMemo(() => {
    if (!currentYearAndWeek) {
      return null;
    }
    const yearAndWeek = addWeeks(currentYearAndWeek, -1);
    const baseFilter = yearAndWeek
      ? {
          fromYear: yearAndWeek.year,
          fromWeekNumber: yearAndWeek.weekNumber,
          toYear: yearAndWeek.year,
          toWeekNumber: yearAndWeek.weekNumber,
        }
      : ({} as WeekReportFilter);
    return {
      ...baseFilter,
      ...filter,
    };
  }, [currentYearAndWeek, filter]);

  const getWeekReports = useCallback(() => {
    if (!effectiveFilter) {
      return Promise.resolve([]);
    }
    return WeekReportApi.getWeekReports(effectiveFilter);
  }, [effectiveFilter]);

  return (
    <div>
      <h1>Billing Tool</h1>
      <Backdrop className={classes.backdrop} open={loadingReports}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box sx={{ minWidth: 120 }}>
        <FormControl
          variant="outlined"
          fullWidth
          size="small"
          style={{ maxWidth: "10em" }}
        >
          <InputLabel id="demo-simple-select-label">
            {selectedProject?.name}
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Project"
            onChange={handleProjectChange}
          >{!loadingProjects ?
            projects.map((project) => {
              return (
                <MenuItem value={project.projectId}>{project.name}</MenuItem>
              );
            }):  <CircularProgress color="inherit" />}
          </Select>
          {effectiveFilter != null ? (
            <div>
              <WeekPicker
                value={{
                  year: effectiveFilter.fromYear,
                  weekNumber: effectiveFilter.fromWeekNumber,
                }}
                weeks={weeks}
                onChange={({ year, weekNumber }) => {
                  setFilter({
                    ...filter,
                    fromYear: year,
                    fromWeekNumber: weekNumber,
                    toYear: addWeeks({ year: year, weekNumber: weekNumber }, 3)
                      .year,
                    toWeekNumber: addWeeks(
                      { year: year, weekNumber: weekNumber },
                      3
                    ).weekNumber,
                  });
                }}
              />
            </div>
          ) : (
            <div></div>
          )}
        </FormControl>
        <h2>Billing cycle: {selectedProject ? BillingCycles.getNameFromEnum(selectedProject.billingCycle) : null}</h2>
      </Box>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>

              {selectedWeeks().map((week) => (
                <TableCell>W{week}</TableCell>
              ))}
              <TableCell>Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {UsersReports(weekReports).map((user) => {
              let sum = 0;
              return (
                <TableRow key={user.userName} hover>
                  <TableCell className={classes.cell}>
                    {user.userName}
                  </TableCell>
                  {user.weekAndHours.map((week) => {
                    return (
                      <TableCell
                        className={classes.cell}
                        onClick={() =>
                          handleCellClick(user.userId, week.week, week.year)
                        }
                        style={{ cursor: "pointer" }}
                      >
                        {week.reportPresent ? (
                          <>
                            {week.hours === 40 ? (
                              <>{week.hours}</>
                            ) : (
                              <mark>{week.hours}</mark>
                            )}
                          </>
                        ) : (
                          <>?</>
                        )}
                      </TableCell>
                    );
                  })}
                  <TableCell className={classes.cell}>
                    {user.weekAndHours.forEach((week) => {
                      sum += week.hours;
                    })}
                    {sum}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {/* 
      <ul>
        {users.map((user) => (
          <li key={user.userId}>
            {user.userName +
              ": " +
              weekReports
                .filter((w) => w.userId === user.userId)
                .map((wr) => {
                  var sum = 0;
                  wr.entries?.map((e) => {
                    if (e.project == selectedProject?.name) sum += e.hours;
                  });
                  return "W" + wr.weekNumber + ": " + sum + " hours \r";
                })}
          </li>
        ))}
      </ul> */}
    </div>
  );
};
