import React from "react";
import { Route } from "react-router-dom";

import { AppBarRoute } from "../WithAppBar";
import { Dashboard } from "./employee/Dashboard";
import { WeekReportHistory } from "./employee/WeekReportHistory";
import { Addresses } from "./employee/Dashboard/Addresses";
import { Declaration } from "./Declaration";
import { Account } from "../data/principal";
import { ProfileItem } from "./ProfileItem";
import { UpdateDeclaration } from "./UpdateDeclaration";

export const EmployeeRoutes = (account: Account) => {
  if (!account || !account.isEmployee) {
    return null;
  }

  return (
    <div>
      {routes.map((route, index) => (
        <Route key={index} {...route.props} />
      ))}
      <Route path="/employee" exact>
        <Dashboard />
      </Route>
    </div>
  );
};

export const routes: AppBarRoute[] = [
  {
    title: "Dashboard",
    props: {
      path: "/employee",
    },
  },
  {
    title: "Declarations",
    props: {
      path: "/employee/declarations",
      component: Declaration,
    },
  },
  {
    title: "Addresses",
    props: {
      path: "/employee/addresses",
      component: Addresses,
    },
  },
  {
    title: "Week report history",
    props: {
      path: "/employee/history",
      component: WeekReportHistory,
    },
  },
  {
    props: {
      path: "/employee/profile",
      component: ProfileItem,
    },
  },
  {
    props: {
      path: "/employee/updateDeclaration/:id",
      component: UpdateDeclaration,
    },
  },
];
