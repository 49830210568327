import React from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
} from "@material-ui/core";

interface CheckBoxesProps {
  id: string;
  label: string;
  values?: string[];
  options: { [key: string]: string };
  onChange?: (values: string[]) => void;
  error?: string;
}

export const CheckBoxes = (props: CheckBoxesProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const values = props.values || [];
    const newValues = event.target.checked
      ? values.concat(event.target.name)
      : values.filter((v) => v !== event.target.name);
    props.onChange && props.onChange(newValues);
  };

  return (
    <FormControl component="fieldset" error={!!props.error}>
      <FormLabel component="legend">{props.label}</FormLabel>
      <FormGroup>
        {Object.keys(props.options).map((key) => (
          <FormControlLabel
            id={props.options[key]}
            key={key}
            disabled={!props.onChange}
            control={
              <Checkbox
                checked={props.values?.includes(key)}
                onChange={handleChange}
                name={key}
              />
            }
            label={props.options[key]}
          />
        ))}
      </FormGroup>
      <FormHelperText>{props.error}</FormHelperText>
    </FormControl>
  );
};
