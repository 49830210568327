import {
  Dialog,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";

interface ErrorLogDialogProps {
  open: boolean;
  errorData: string;
  onClose: () => void;
}

export const ErrorPopup = (props: ErrorLogDialogProps) => {
  const { onClose, open, errorData } = props;

  return (
    <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">
        Something went wrong with creating the Google Workspace account
      </DialogTitle>
      <List>
        <ListItem key="h1">
          <ListItemText
            primary={
              <Typography variant="subtitle2">
                Please read this log for details
              </Typography>
            }
          />
        </ListItem>
        <Divider key="dv1" />
        <ListItem key="h2">
          <ListItemText
            primary={<Typography variant="subtitle2">Error log:</Typography>}
          />
        </ListItem>
        <ListItem key="h3">
          <ListItemText primary={errorData} />
        </ListItem>
      </List>
    </Dialog>
  );
};
