import { useMemo} from "react";
import {
  Grid,
} from "@material-ui/core";

import { YearAndWeek } from "../../hooks/dates";
import { WeekReportItem } from "../../data/weekReport";
import { weekStartAndEnd } from "../../hooks/dates";
import HistoryTimeTable from "./HistoryTimeTable";

export interface ProjectAndHours {
  name: string;
  hours: number;
}

export interface TimesheetByProject {
  userId: string;
  userName?: string;
  yearAndWeek: YearAndWeek;
  projectRows?: ProjectAndHours[];
  totalHours: number;
  totalBusinessTripExpenses?: number;
}



export const WeekReport = ({
  weekReport,
  name,
  presentOnly,
}: {
  weekReport: WeekReportItem;
  name?: string;
  presentOnly: boolean;
}) => {

  const [weekStart, weekEnd] = useMemo(
    () => weekStartAndEnd(weekReport),
    [weekReport]
  );

  const totalDistance = weekReport.trips?.reduce(
    (sum, trip) => sum + trip.distance * trip.frequency,
    0
  );
  
  var expensePerKm = 0.01;
  const [dateDay, dateMonth, dateYear] = weekStart.split("-");
  const date = new Date(
    +Number(dateYear),
    Number(dateMonth) - 1,
    +Number(dateDay)
  );
  if (date.getFullYear() === 2022) {
    expensePerKm = 0.19;
  } else if (date.getFullYear() === 2023) {
    expensePerKm = 0.21;
  } else if (date.getFullYear() >= 2024) {
    expensePerKm = 0.22;
  }

  const GenerateTimesheetByProject = (weekReport: WeekReportItem): TimesheetByProject => {
    let timesheet : TimesheetByProject = {userId: weekReport.userId, userName: name, yearAndWeek: {weekNumber: weekReport.weekNumber, year: weekReport.year}, totalHours: 0}
    let rows : ProjectAndHours[] = [];
    weekReport.entries?.forEach((entry) => {
      if(entry.projectType === "HOLIDAY") return;
      if(rows.find(i => i.name === entry.project) !== undefined){
        rows.forEach((row) => {
          if(row.name === entry.project) row.hours += entry.hours;
        })
      }
      else{
        rows.push({name: entry.project, hours: entry.hours})
      }
    })

    let totalHours = 0;
    rows.forEach((row, index) => {

      //delete rows if no hours are worked
      if(row.hours === 0){
        rows.splice(index);
      }

      //count total hours
      totalHours += row.hours;
    })

    timesheet.totalHours = totalHours;
    timesheet.projectRows = rows;
    if(totalDistance === undefined){
      timesheet.totalBusinessTripExpenses = 0;
    }
    else{
      timesheet.totalBusinessTripExpenses = totalDistance * expensePerKm;
    }
    

    return timesheet;
  }

  return (
    <div>
      <Grid container spacing={1} justify="center">
        <Grid item xs={5}>
          <HistoryTimeTable 
          timesheet={GenerateTimesheetByProject(weekReport)}
          weekreport={weekReport}
          trips={weekReport.trips || []}
          expensePerKm={expensePerKm}
          reportPeriod={`${weekStart} until ${weekEnd}`}/>

        </Grid>
      </Grid>
    </div>
  );
};
