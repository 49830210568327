import { format, parse, parseISO } from "date-fns";
import dayjs from "dayjs";

const formatDistance = (distance: number): string => {
  return distance % 1 !== 0 ? distance.toFixed(1) : distance.toString();
};

const formatAmount = (amount: number): string => {
  return amount.toFixed(2).replace(".", ",");
};

const parseDate = (str: string | undefined | null): Date | null => {
  return str ? parseISO(str) : null;
};

const parseDateTime = (date: string, time?: string): Date | null => {
  return date
    ? parse(
        date + " " + (time ? time : "00:00:00:000000000"),
        "yyyy-MM-dd HH:mm:ss:SSSSSSSSS",
        new Date()
      )
    : null;
};

// export function sleep(ms: number) {
//   return new Promise(resolve => setTimeout(resolve, ms));
// }

export function throttle(callback: { apply: (arg0: any, arg1: IArguments) => void; }, limit: number | undefined) {
  let waiting = false;
  return function () {
    if (!waiting) {
      // @ts-ignore
      callback.apply(this, arguments);
      waiting = true;
      setTimeout(function () {
        waiting = false;
      }, limit);
    }
  }
}
export function formatTimeLeft(ms: number): string {
  const duration = dayjs.duration(ms);
  const days = dayjs().add(duration).diff(dayjs(), "days");
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();
  const milliseconds = duration.milliseconds();

  const daysMessage = days + " days ";
  const hoursMessage = hours + " hours ";
  const minutesMessage = minutes + " minutes ";
  const secondsMessage = seconds + "." + milliseconds + " seconds ";
  const ending = "left till expiration";

  let result = "";

  if (days) {
    result += daysMessage;
  }

  if (hours) {
    result += hoursMessage;
  }

  if (minutes) {
    result += minutesMessage;
  }
  result += secondsMessage + ending;


  return result;

}
const formatDate = (date: Date | null): string => {
  return date ? format(date, "yyyy-MM-dd") : "";
};

const viewDate = (date: Date | null): string => {
  return date ? format(date, "dd-MM-yyyy") : "";
};

const viewDateTime = (dateTime: Date | null): string => {
  return dateTime ? format(dateTime, "yyyy-MM-dd HH:mm:ss:SSSSSSSSS") : "";
};

export function copyToClipboard(text: string) {
  if (navigator.clipboard) {
    return navigator.clipboard.writeText(text)
  } else {
    throw new Error("Clipboard API not available");
  }
}
export const Utils = {
  copyToClipboard,
  formatDistance,
  formatAmount,
  parseDate,
  parseDateTime,
  formatDate,
  viewDate,
  viewDateTime,
};
