class EventEmitter {
  private callbacks: { [event: string]: Function[] } = {};
  private singularCallbacks: { [event: string]: Function[] } = {};

  constructor() {
    this.emit = this.emit.bind(this);
  }

  clearCallbacks() {
    this.callbacks = {};
    this.singularCallbacks = {};
  }

  emit(event: string, data?: any) {
    if (event in this.callbacks) {
      this.callbacks[event].forEach((callback) => {
        callback(data);
      });
    }

    if (event in this.singularCallbacks) {
      this.singularCallbacks[event].forEach((callback) => {
        callback(data);
      });
      delete this.singularCallbacks[event];
    }
  }

  removeListener(event: string, removedCallback: Function) {
    if (event in this.callbacks) {
      this.callbacks[event] = this.callbacks[event].filter(
        (callback) => callback !== removedCallback
      );
    }

    if (event in this.singularCallbacks) {
      this.singularCallbacks[event] = this.singularCallbacks[event].filter(
        (callback) => callback !== removedCallback
      );
    }
  }

  on(event: string, callback: Function) {
    if (!(event in this.callbacks)) {
      this.callbacks[event] = [];
    }

    this.callbacks[event].push(callback);
  }

  once(event: string, callback: Function) {
    if (!(event in this.singularCallbacks)) {
      this.singularCallbacks[event] = [];
    }

    this.singularCallbacks[event].push(callback);
  }
}

export default EventEmitter;
