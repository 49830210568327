import React, {ReactElement} from "react";
import {RemoteDataContainer} from "../../../component/RemoteDataContainer";
import {HolidayAllowanceList} from "./HolidayAllowanceList";
import HolidayAllowanceClient from "./HolidayAllowanceClient";
import {Box, Button, Container, createStyles, Grid, makeStyles, Theme} from "@material-ui/core";
import {Title} from "../../../component/Title";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        header: {
            display: "flex",
            justifyContent: "space-between",
        },
        cell: {
            verticalAlign: "top",
        },
    })
);

export const HolidayAllowance: () => ReactElement = () => {
    const classes = useStyles();

    const exportHolidayAllowance = () => {
        HolidayAllowanceClient.retrieveExport()
            .then((data) => {
                const downloadUrl = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement("a");
                link.href = downloadUrl;
                link.setAttribute(
                    "download",
                    `HolidayAllowance.csv`
                );
                document.body.appendChild(link);
                link.click();
                link.remove();
            });
    }

    return (
        <Box m="3%">
            <Grid container spacing={3}>
                <Grid item sm={12}>
                    <RemoteDataContainer
                        fetch={HolidayAllowanceClient.getHolidayAllowances}
                        errorMessage={() => "Failed loading holiday allowances"}
                        renderData={(allowances) => (
                            <Container>
                                <div className={classes.header}>
                                    <Title>Holiday Allowances</Title>
                                    <Button color="primary" variant="outlined" onClick={exportHolidayAllowance}>
                                        Export
                                    </Button>
                                </div>
                                <HolidayAllowanceList collection={allowances}/>
                            </Container>
                        )}
                    />
                </Grid>
            </Grid>
        </Box>
    );
}
