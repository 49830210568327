import Axios, { AxiosError, AxiosInstance } from "axios";
import { Store } from "redux";
import StoreManager from "../redux/store";
import { RootState } from "../redux/reducers";
import {
  actionLogin,
  actionLogout,
  actionRefreshEnd,
  actionRefreshStart,
  actionRemoveRefreshToken,
  actionSetToken,
  AuthenticationActions,
} from "../redux/actions";
import AuthenticationApi from "./authentication";
import { RefreshAction } from "../redux/reducers/authentication.reducer";
import config from "../config";

const axios = Axios.create({
  baseURL: config.apiUrl,
  // baseURL: API_HOST,
  headers: {
    "Content-Type": "application/json",
  },
});

const quadAxios = Axios.create({
  // baseURL: QUAD_API_HOST,
  baseURL: config.apiUrlOld,
  headers: {
    "Content-Type": "application/json",
  },
});

quadAxios.interceptors.request.use((axiosConfig) => {
  const store = StoreManager.getStore();
  const state = store.getState();
  axiosConfig.headers[
    "Authorization"
  ] = `Bearer ${config.oauthToken?.accessToken}`;
  return axiosConfig;
});

const refreshToken = (
  error: AxiosError,
  store: Store<RootState>
): Promise<void> => {
  const state = store.getState();

  // If the state was already moving to authorize the client, ignore attempting to refresh
  if (state.auth.isInAuthorization) {
    return Promise.reject(error);
  }

  // If the state is currently already refreshing, return the same promise
  if (state.auth.isInRefresh) {
    return state.auth.refreshingCall as RefreshAction;
  }

  // If we have a refresh token available, use it in order to refresh the authentication
  if (state.auth.refreshToken !== null) {
    const refreshFn = AuthenticationApi.getTokenFromRefreshToken(
      state.auth.refreshToken
    )
      .then((token) => {
        config.oauthToken = token;
        store.dispatch(actionSetToken(token));
        // We do not have to set the account because the refreshing
        // should automatically yield the same account
        store.dispatch(actionLogin());
      })
      .catch((err) => {
        store.dispatch(actionRemoveRefreshToken());
        store.dispatch(actionLogout());
      })
      .finally(() => {
        store.dispatch(actionRefreshEnd());
      });
    store.dispatch(actionRefreshStart(refreshFn));

    // Return the same promise
    return refreshFn;
  }

  // This dispatch function is not properly typed so it won't accept a thunk
  store.dispatch(AuthenticationActions.logout() as any);

  // Pass back the original error
  return Promise.reject(error);
};

axios.interceptors.request.use((axiosConfig) => {
  const store = StoreManager.getStore();
  const state = store.getState();
  axiosConfig.headers["Authorization"] = `Bearer ${config.oauthToken?.accessToken}`;

  if (config.debugAltApi) {
    quadAxios
      .request({
        ...axiosConfig,
        baseURL: quadAxios.defaults.baseURL,
      })
      .catch((e) => {
        console.error("Inside " + e.message);
      });
  }

  return axiosConfig;
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const status = error.response ? error.response.status : null;

    if (status === 401) {
      return refreshToken(error, StoreManager.getStore()).then(() => {
        const store = StoreManager.getStore();
        const state = store.getState();
        error.config.headers["Authorization"] = `Bearer ${config.oauthToken?.idToken}`;
        return axios.request(error.config);
      });
    }

    return Promise.reject(error);
  }
);

export function getAxios(): AxiosInstance {
  return axios;
}
