import {
  HolidayAllowancePerYear,
  HolidayAllowanceWithUserPerYear,
} from "../data/holiday-allowance";

export function sumBalance(
  allowances: HolidayAllowancePerYear | HolidayAllowanceWithUserPerYear
): number {
  return Object.values(allowances).reduce(
    (sum, allowance) => sum + allowance.balance,
    0
  );
}

export function getUnit(amount: number): string {
  return amount > 1 ? "days" : "day";
}
