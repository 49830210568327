import { getAxios } from "./axios";

export interface BusinessTripSyncStatus {
  id: string;
  status: string;
  errors: string[];
  failedEmployees: Record<string, string>;
  period: string;
  weekRange: string;
}

export interface NmbrsFlowResultDto {
  status: string;
}

export interface NmbrsParams {
  fromWeekNumber: number;
  toWeekNumber: number;
  fromYear: number;
  toYear: number;
}

export default class NmbrsApi {
  static async getAllBusinessTripSyncStatusses(): Promise<
    BusinessTripSyncStatus[]
  > {
    let response = await getAxios().get<BusinessTripSyncStatus[]>(
      `/getAllBusinessTripSyncStatusses`
    );
    return response.data;
  }

  static async getMostRecentBusinessTripSyncStatus(): Promise<BusinessTripSyncStatus> {
    let response = await getAxios().get<BusinessTripSyncStatus>(
      `/getMostRecentBusinessTripSyncStatus`
    );
    return response.data;
  }

  static async getBusinessTripSyncStatusForPeriod(
    period: string,
    weekRange: string
  ): Promise<BusinessTripSyncStatus> {
    const params = { period: period, weekRange: weekRange };
    let response = await getAxios().get<BusinessTripSyncStatus>(
      `/getBusinessTripSyncStatusPeriod`,
      { params }
    );
    return response.data;
  }

  static async getEmployeeInfo(
    payload: NmbrsParams
  ): Promise<NmbrsFlowResultDto> {
    let response = await getAxios().get<NmbrsFlowResultDto>(
      `/getEmployeeInfo`,
      {
        params: payload,
      }
    );
    return response.data;
  }
}
