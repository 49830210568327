import { getAxios } from "./axios";

export interface Holiday {
  id: string;
  vacation: boolean;
  dateString: string;
  fullName: string;
}

export interface HolidayList {
  holidays: Holiday[];
}

export interface WeekHoliday {
  fullName: string;
  date: string;
}

export interface WeekHolidayList {
  holidays: WeekHoliday[];
}

export default class HolidayApi {
  static async getHolidays(): Promise<HolidayList> {
    let response = await getAxios().get<HolidayList>(`/holidays/get`);
    return response.data;
  }

  static async getHolidaysForWeek(
    startDate: moment.Moment,
    endDate: moment.Moment
  ): Promise<WeekHolidayList> {
    let response = await getAxios().get<WeekHolidayList>(
      `/holidays/checkForWeek`,
      {
        params: {
          startDate: startDate.format("YYYY-MM-DD"),
          endDate: endDate.format("YYYY-MM-DD"),
        },
      }
    );
    return response.data;
  }

  static async refreshHolidays(): Promise<string> {
    let response = await getAxios().get<string>(`/holidays/refresh`);
    return response.data;
  }

  static async toggleHoliday(id: string, newState: boolean): Promise<string> {
    let response = await getAxios().post<string>(`/holidays/toggle`, {
      id,
      state: newState,
    });
    return response.data;
  }
}
