import {
    createStyles,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Theme,
    Tooltip
} from "@material-ui/core";
import React from "react";
import {
    HolidayAllowance,
    HolidayAllowanceWithUser,
    HolidayAllowanceWithUserCollection,
    HolidayAllowanceWithUserPerYear
} from "../../../data/holiday-allowance";
import {UserItem, UserNameBuilder, UserSummary} from "../../../data/user";
import {getUnit, sumBalance} from "../../../utils/HolidayAllowance.util";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        header: {
            display: "flex",
            justifyContent: "space-between",
        },
        cell: {
            verticalAlign: "top",
        },
        bold: {
            fontWeight: 'bold'
        }
    })
);

function toTitle(allowance: HolidayAllowance): string {
    return `Earned holidays: ${allowance.earned.toFixed(1)}\nUsed holidays: ${allowance.used.toFixed(1)}\nBalance: ${allowance.balance.toFixed(1)}`
}

function getEmployment(user: UserItem | UserSummary): string {
    return `${user.employmentStartDate || 'Begin'} - ${user.employmentEndDate || 'Now'}`;
}

function getUser(allowances: HolidayAllowanceWithUserPerYear): UserSummary {
    return Object.values(allowances)[0].user;
}

function getAllowanceForYear(allowances: HolidayAllowanceWithUserPerYear, year: number): HolidayAllowanceWithUser {
    if (!Object.keys(allowances).includes(year.toFixed())) {
        return {
            id: "0",
            year,
            user: {} as UserSummary,
            earned: 0,
            used: 0,
            balance: 0,
            potentialBalance: 0,
            potentialEarnedDays: 0,
        }
    }

    return allowances[year];
}

export const HolidayAllowanceList = ({collection}: { collection: HolidayAllowanceWithUserCollection }) => {
    const classes = useStyles();

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.cell}>Name</TableCell>
                        <TableCell className={classes.cell}>Employment</TableCell>
                        {
                            Object.keys(collection).includes('years') ? collection.years.map((year) => (
                                <TableCell className={classes.cell}>{year}</TableCell>
                            )) : <></>
                        }
                        <TableCell className={classes.cell + classes.bold}>Cumulative</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        Object.keys(collection).includes('collection') ? Object.entries(collection.collection).map(([userId, allowancesPerYear]) => (
                            <TableRow key={userId} hover>
                                <TableCell className={classes.cell}>
                                    {UserNameBuilder.getFullName(getUser(allowancesPerYear))}
                                </TableCell>
                                <TableCell className={classes.cell}>
                                    {getEmployment(getUser(allowancesPerYear))}
                                </TableCell>
                                {
                                    collection.years.map(year => {
                                        const allowance = getAllowanceForYear(allowancesPerYear, year);
                                        return <Tooltip
                                            title={<span
                                                style={{whiteSpace: 'pre-line'}}>{toTitle(allowance)}</span>}
                                            arrow>
                                            <TableCell
                                                className={classes.cell}>
                                                {allowance.balance.toFixed(1)} {getUnit(allowance.balance)}
                                            </TableCell>
                                        </Tooltip>
                                    })
                                }
                                <TableCell className={`${classes.cell} ${classes.bold}`}>
                                    {sumBalance(allowancesPerYear).toFixed(1)} {getUnit(sumBalance(allowancesPerYear))}
                                </TableCell>
                            </TableRow>
                        )) : <></>
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
}
