import { AddressUpdate } from "./address";
import { getAxios } from "./axios";

export enum ProjectType {
  REGULAR = "REGULAR",
  HOLIDAY = "HOLIDAY",
  SICKLEAVE = "SICKLEAVE",
  VACATIONLEAVE = "VACATIONLEAVE",
}

export enum BillingCycle {
  UNSPECIFIED = "UNSPECIFIED",
  FOUR_WEEKLY = "FOUR_WEEKLY",
  MONTHLY = "MONTHLY",
}

export class ProjectTypes {
  static getProjectTypes(): string[] {
    return Object.keys(ProjectType);
  }

  static getNameFromEnum(type: ProjectType): string {
    switch (type) {
      case ProjectType.HOLIDAY:
        return "Holiday";
      case ProjectType.SICKLEAVE:
        return "Sick leave";
      case ProjectType.VACATIONLEAVE:
        return "Vacation leave";
      default:
        return "Regular";
    }
  }
}

export class BillingCycles {
  static getBillingCycles(): string[] {
    return Object.keys(BillingCycle);
  }

  static getNameFromEnum(type: BillingCycle): string {
    switch (type) {
      case BillingCycle.UNSPECIFIED:
        return "Unspecified";
      case BillingCycle.FOUR_WEEKLY:
        return "4-Weekly";
      case BillingCycle.MONTHLY:
        return "Monthly";
      default:
        return "null";
    }
  }

}

interface ProjectBase {
  name: string;
  startDate: string;
  endDate: string;
  projectEmailAddress?: string;
  projectType: ProjectType;
  billingCycle: BillingCycle;
}

export interface ProjectItem extends ProjectBase {
  projectId: string;
  customerId: string;
  customerName: string;
  employees: ProjectUser[];
  projectVisitingAddressId: string | undefined;
  projectVisitingAddress: string | undefined;
  projectStatus?: string;
}

export interface ProjectUser {
  projectId: string;
  userId: string;
  projectName: string;
  userName: string;
}

export interface ProjectEdit extends ProjectBase, AddressUpdate {}

export interface CreateProject extends ProjectEdit {
  customerId: string;
  employees: ProjectUser[];
}

export interface UpdateProject extends CreateProject {
  projectId: string;
}

export interface DisableProject {
  projectId: string;
}

export default class ProjectApi {
  static async getProjects(): Promise<ProjectItem[]> {
    let response = await getAxios().get<ProjectItem[]>(`/getProjects`);
    return response.data;
  }

  static async getActiveProjects(): Promise<ProjectItem[]> {
    let response = await getAxios().get<ProjectItem[]>(`/getActiveProjects`);
    return response.data;
  }

  static async getUserProjects(
    start: moment.Moment,
    end: moment.Moment
  ): Promise<ProjectItem[]> {
    let response = await getAxios().get<ProjectItem[]>(
      `/getUserProjects?start=${start.format("YYYY-MM-DD")}&end=${end.format(
        "YYYY-MM-DD"
      )}`
    );
    return response.data;
  }

  static async getProject(id: string): Promise<ProjectItem> {
    let response = await getAxios().get<ProjectItem>(`/project/${id}`);
    return response.data;
  }

  static async createProject(payload: CreateProject): Promise<string> {
    let response = await getAxios().post<string>(`/createProject`, payload);
    return response.data;
  }

  static async updateProject(payload: UpdateProject): Promise<string> {
    console.log("UPDATED PROJECT WITH: " + payload.billingCycle)
    let response = await getAxios().post<string>(`/updateProject`, payload);
    return response.data;
  }

  static async disableProject(payload: DisableProject): Promise<void> {
    let response = await getAxios().post<void>(`/disableProject`, payload);
    return response.data;
  }

  static async archiveProject(projectId: string): Promise<boolean> {
    let response = await getAxios().post<boolean>(
      `/archiveProject/${projectId}`
    );
    return response.data;
  }

  static async reactivateProject(projectId: string): Promise<boolean> {
    let response = await getAxios().post<boolean>(
      `/reactiveProject/${projectId}`
    );
    return response.data;
  }
}
