import { getAxios } from "./axios";

export interface CreateGoogleWorkspaceUser {
  userId: string;
  firstName: string;
  lastName: string;
  privateEmailAddress: string;
}

export interface GoogleWorkspaceUserId {
  id: String;
}

export default class GoogleWorkspaceApi {
  static async createGoogleWorkspaceUser(userId: string): Promise<string> {
    let response = await getAxios().post<string>(
      `/createGoogleWorkspaceUser/${userId}`
    );
    return response.data;
  }

  static async getGoogleWorkspaceUser(
    primaryEmailAddress: string
  ): Promise<GoogleWorkspaceUserId> {
    let response = await getAxios().get<GoogleWorkspaceUserId>(
      `/googleWorkspaceUser/${primaryEmailAddress}`
    );
    return response.data;
  }

  static async deleteGoogleWorkspaceUser(
    primaryEmailAddress: string
  ): Promise<Boolean> {
    let response = await getAxios().delete<Boolean>(
      `/googleWorkspaceUser/${primaryEmailAddress}`
    );
    return response.data;
  }

  static async sendEmails(userId: string): Promise<void> {
    let response = await getAxios().post<void>(`/sendEmails/${userId}`);
    return response.data;
  }
}
