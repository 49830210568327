import React, { ReactNode } from "react";
import { Button, CircularProgress } from "@material-ui/core";
import Box from "./Box";

export function LoadingButton({
  style,
  onClick,
  children,
  variant,
  loading,
  disabled,
  endIcon,
}: {
  style?: React.CSSProperties;
  onClick: () => any;
  endIcon: JSX.Element;
  loading: boolean;
  disabled?: boolean;
  loadingPosition: string;
  variant?: "text" | "outlined" | "contained";
  children: ReactNode;
}) {
  return (
    <Button
      disabled={disabled}
      style={{
        display: "flex",
        ...style,
      }}
      onClick={onClick}
      variant={variant}
    >
      {children}
      {!loading && (
        <Box row style={{ marginLeft: "10px" }}>
          {endIcon}
        </Box>
      )}
      {loading && (
        <CircularProgress size={"20px"} style={{ marginLeft: "10px" }} />
      )}
    </Button>
  );
}
