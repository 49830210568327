import debug from "debug";
import { throttle } from "./utils";
import chalk from "chalk";

const APP_PREFIX = "ata";

class Logger {
  public info: debug.Debugger;
  public throttle: () => void;
  public error: debug.Debugger;
  public warn: debug.Debugger;
  public event: debug.Debugger;
  public trace: debug.Debugger;
  public debug: debug.Debugger;

  constructor(moduleName: string) {
    this.debug = debug(`${APP_PREFIX}:${moduleName}`);
    this.trace = debug(`${APP_PREFIX}:TRACE:${moduleName}`);
    this.event = debug(`${APP_PREFIX}:EVENT:${moduleName}`);
    this.info = debug(`${APP_PREFIX}:INFO:${moduleName}`);
    this.warn = debug(`${APP_PREFIX}:WARN:${moduleName}`);
    this.error = debug(`${APP_PREFIX}:ERROR:${moduleName}`);

    this.trace.log = console.info.bind(console);
    this.event.log = console.info.bind(console);
    this.debug.log = console.info.bind(console);
    this.info.log = console.info.bind(console);
    this.warn.log = console.warn.bind(console);
    this.error.log = console.error.bind(console);
    this.throttle = throttle(this.trace, 500);
  }

  log(message: any, ...args: any[]) {
    for (const arg of args) {
      message = message.replace("%s", arg);
    }

    console.log(chalk.blue(message));
  }

  enable(namespaces: string) {
    debug.enable(namespaces);
  }

  enabled(namespaces: string) {
    debug.enabled(namespaces);
  }
}

export default Logger;
