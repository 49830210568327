import React, { useContext, useState, useEffect } from "react";
import {
  createStyles,
  makeStyles,
  Button,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  AttachFile,
  Delete,
  CloudUpload,
  LaunchOutlined,
} from "@material-ui/icons";
import UserDocumentApi, {
  CreateUserDocument,
  UserDocumentDataItem,
  UserDocumentType,
} from "../../data/userDocument";
import { SnackbarContext } from "../SnackbarProvider";
import { isSafari } from "react-device-detect";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    attachmentButton: {
      WebkitTransform: "rotate(45deg)",
      msTransform: "rotate(45deg)",
      transform: "rotate(45deg)",
      cursor: "pointer",
      marginTop: "20px",
      opacity: "50%",
      "&:hover": {
        opacity: "100%",
      },
    },
    linkDownloadButton: {
      cursor: "pointer",
      marginTop: "20px",
      marginRight: "5px",
      opacity: "50%",
      "&:hover": {
        opacity: "100%",
      },
    },
    uploadBlock: {
      border: "2px dashed #bbb",
      marginLeft: "0px",
      marginRight: "auto",
      width: "95%",
      height: "45px",
      marginTop: "20px",
      paddingTop: "10px",
      paddingBottom: "55px",
      textAlign: "center",
      "&:hover": { cursor: "pointer" },
    },
    uploadBlock2: {
      border: "2px dashed #bbb",
      marginLeft: "auto",
      marginRight: "auto",
      width: "100%",
      height: "45px",
      marginTop: "20px",
      paddingTop: "10px",
      paddingBottom: "55px",
      textAlign: "center",
      "&:hover": { cursor: "pointer" },
    },
    moveUpToOverlap: {
      position: "absolute",
      bottom: 15,
      right: 15,
      marginTop: "-35px",
    },
    moveUpToOverlap2: {
      position: "absolute",
      bottom: 15,
      right: 100,
      marginTop: "-35px",
    },
    trashButton: {
      float: "right",
      cursor: "pointer",
    },
    previewUploadOnMobile: {
      minWidth: window.innerWidth * 0.9,
      maxWidth: window.innerWidth * 0.9,
      paddingBottom: "10px",
    },
    previewUploadOnDesktop: {
      minWidth: window.innerWidth * 0.475,
      maxWidth: window.innerWidth * 0.475,
      paddingBottom: "10px",
    },
    pdfUnsupportedMessage: {
      height: "80px",
    },
  })
);

interface UploadDocumentsProps {
  userId: string;
  setCurrentFile: React.Dispatch<React.SetStateAction<File | undefined>>;
  setImageAsURL: React.Dispatch<React.SetStateAction<string>>;
  currentFile: File | undefined;
  imageAsURL: string;
  setUserDocument: React.Dispatch<React.SetStateAction<CreateUserDocument>>;
  userDocument: CreateUserDocument;
  uploadDocument: () => void;
  upload: boolean;
  setUndeletedUserDocument: React.Dispatch<
    React.SetStateAction<UserDocumentDataItem | undefined>
  >;
  undeletedUserDocument: UserDocumentDataItem | undefined;
  setUpload: React.Dispatch<React.SetStateAction<boolean>>;
}

const UploadDocuments = ({
  userId,
  setCurrentFile,
  setImageAsURL,
  currentFile,
  imageAsURL,
  setUserDocument,
  userDocument,
  uploadDocument,
  upload,
  undeletedUserDocument,
  setUndeletedUserDocument,
  setUpload,
}: UploadDocumentsProps) => {
  const classes = useStyles();
  const { showError } = useContext(SnackbarContext);
  const [documentExist, setDocumentExist] = useState<boolean>(false);

  useEffect(() => {
    UserDocumentApi.getUndeletedUserDocuments(userId).then((res) => {
      const output = res.filter((doc) =>
        Object.values(doc.parts).find(
          (part) =>
            part.userDocumentType ===
            UserDocumentType.SIGNEDLOONHEFFINGSVERKLARING
        )
      )[0];
      if (output !== undefined) {
        setDocumentExist(true);
        setUndeletedUserDocument(output);
      }
    }).catch(e => console.error(e.message));
  }, [userId, setUndeletedUserDocument, upload]);

  const maxWidthForMobileInPixels = 1224;

  let isMobileDevice: boolean = useMediaQuery(
    `(max-width:${maxWidthForMobileInPixels}px)`
  );

  var pdfPreview;
  if (!isSafari) {
    pdfPreview = (
      <object
        className={
          isMobileDevice
            ? classes.previewUploadOnMobile
            : classes.previewUploadOnDesktop
        }
        type="application/pdf"
        data={imageAsURL}
      >
        {" "}
        <div className={classes.pdfUnsupportedMessage}>
          {" "}
          This browser does not support previewing PDF files.
        </div>{" "}
      </object>
    );
  } else {
    pdfPreview = (
      <object
        className={
          isMobileDevice
            ? classes.previewUploadOnMobile
            : classes.previewUploadOnDesktop
        }
        data={imageAsURL}
      >
        {" "}
        <div className={classes.pdfUnsupportedMessage}>
          {" "}
          This browser does not support previewing PDF files.
        </div>{" "}
      </object>
    );
  }

  const uploadID = "UPLOAD";

  return (
    <>
      {currentFile ? (
        <div>
          {currentFile.name
            .substr(currentFile.name.lastIndexOf(".") + 1)
            .toLowerCase() === "pdf" ? (
            pdfPreview
          ) : (
            <div>
              <img
                style={{ zIndex: -1 }}
                className={
                  isMobileDevice
                    ? classes.previewUploadOnMobile
                    : classes.previewUploadOnDesktop
                }
                src={imageAsURL}
                alt="did not load"
              />
            </div>
          )}
          <Button
            variant="contained"
            className={`${classes.trashButton} ${classes.moveUpToOverlap2}`}
            onClick={uploadDocument}
          >
            <CloudUpload />
          </Button>
          <Button
            variant="contained"
            className={`${classes.trashButton} ${classes.moveUpToOverlap}`}
            onClick={() => {
              setCurrentFile(undefined);
              setUserDocument({ ...userDocument, base64EncodedFile: "" });
            }}
          >
            <Delete />
          </Button>
        </div>
      ) : (
        <div
          className={documentExist ? classes.uploadBlock : classes.uploadBlock2}
          onClick={(event) => document.getElementById(uploadID)?.click()}
        >
          <Typography variant="subtitle2">
            <u>Click to upload the signed loonheffingsverklaring document</u>
            <input
              type="file"
              id={uploadID}
              accept="image/*,.pdf"
              hidden
              onChange={(event) => {
                let file: File | undefined | null = event.target.files?.item(0);
                if (file) {
                  var reader: FileReader = new FileReader();

                  reader.onload = function () {
                    const csv: string = reader.result as string;
                    setUserDocument({
                      ...userDocument,
                      parts: userDocument.parts.map((part, index) => {
                        if (index === 0) {
                          part.userDocumentType =
                            UserDocumentType.SIGNEDLOONHEFFINGSVERKLARING;
                          part.personId = userId;
                        }
                        return part;
                      }),
                      base64EncodedFile: csv,
                    });
                    setCurrentFile(file!);
                    setUpload(false);
                    if (
                      file!.type.startsWith("image") ||
                      file!.type.endsWith("pdf")
                    ) {
                      if (file) {
                        setImageAsURL(URL.createObjectURL(file));
                      }
                    }
                  };
                  reader.readAsDataURL(file!);
                } else {
                  showError("You did not upload a file");
                }
              }}
            ></input>
          </Typography>
          <div>All image formats or PDF</div>
        </div>
      )}
      {!currentFile && (
        <Tooltip
          title={
            <>
              <Typography color="inherit">
                Click here to download the Tax form.
              </Typography>
            </>
          }
          placement="top"
          onClick={() =>
            window.open(
              "https://download.belastingdienst.nl/belastingdienst/docs/model_opgaaf_gegevens_loonheffingen_lh0082z9fol.pdf"
            )
          }
          arrow
        >
          <LaunchOutlined className={classes.linkDownloadButton} />
        </Tooltip>
      )}
      {documentExist && undeletedUserDocument !== undefined && !currentFile && (
        <>
          <Tooltip
            title={
              <>
                <Typography color="inherit">
                  Click here to download your current Loonheffingsverklaring
                  document.
                </Typography>
              </>
            }
            placement="top"
            arrow
          >
            <AttachFile
              className={classes.attachmentButton}
              onClick={() => {
                let newWindow = window.open("", "_blank");
                UserDocumentApi.getUserDocumentAttachmentDownloadUrl(
                  undeletedUserDocument.id
                ).then((url) => {
                  newWindow!.location.href = url;
                });
              }}
            ></AttachFile>
          </Tooltip>
        </>
      )}
    </>
  );
};

export default UploadDocuments;
