import {
  Box,
  Button,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import { GridColumns, GridSelectionModel } from "@material-ui/data-grid";
import { useEffect, useState, useContext } from "react";
import AdminDashboardAPI, {
  DashboardWeekReportItem,
} from "../../../data/admindashboard";
import moment from "moment";
import WeekReportApi from "../../../data/weekReport";
import { TotalsDataGrid } from "../../../component/Material/TotalsDataGrid";
import { SnackbarContext } from "../../../component/SnackbarProvider";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    "& > *": {
      marginBottom: "1.5em",
    },
  },
  headerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

const adminDashboardApi = new AdminDashboardAPI();

export const WeekReportTable = () => {
  const classes = useStyles();

  const [loadingWeekReports, setLoadingWeekReports] = useState<boolean>(true);
  const { showInfo, showError } = useContext(SnackbarContext);

  const [selectedWeek, setSelectedWeek] = useState<moment.Moment>(moment());

  const [gridColumns, setGridColumns] = useState<GridColumns>([]);
  const [gridRows, setGridRows] = useState<DashboardWeekReportItem[]>([]);
  const [selection, setSelection] = useState<GridSelectionModel>([]);
  const [showRevertButton, setShowRevertButton] = useState<boolean>(false);

  useEffect(() => {
    setLoadingWeekReports(true);

    console.log("starting to get weekreports");

    adminDashboardApi
      .getWeekReportsTable(
        selectedWeek.year(),
        selectedWeek.isoWeek()
      )
      .then((weekReport) => {
        console.log("done getting weekreports");
        setGridColumns(getGridColumns());
        setGridRows(weekReport.items);
      })
      .catch((err) => {
        console.log(err);
        setGridColumns([]);
        setGridRows([]);
      })
      .finally(() => {
        setLoadingWeekReports(false);
        setSelection([]);
      });
  }, [selectedWeek]);

  const moveWeekBackwards = () => {
    // We need to clone otherwise you're editing an existing value.
    // React won't see this as an update to the state and thus won't trigger a reload
    setSelectedWeek(selectedWeek.clone().subtract({ weeks: 1 }));
  };

  const moveWeekForwards = () => {
    // We need to clone otherwise you're editing an existing value.
    // React won't see this as an update to the state and thus won't trigger a reload
    setSelectedWeek(selectedWeek.clone().add({ weeks: 1 }));
  };

  const updateSelected = (selection: GridSelectionModel) => {
    setSelection(selection);
  };

  const approveSelected = () => {
    const weekReportIds: string[] = [];
    selection.forEach((val) => {
      const row = gridRows.find((f) => f.id === val);
      if (row && row.submitted && row.weekReportId !== null) {
        weekReportIds.push(row.weekReportId);
      }
    });
    WeekReportApi.approveWeekReport(weekReportIds)
      .then(() => {
        // Show success notification
        showInfo("Approved Successfully");
      })
      .catch(() => {
        // Show failure notification
        showError("Something went wrong")
      })
      .finally(() => {
        // Trigger a reload of the week reports table
        setSelectedWeek(selectedWeek.clone());
      });
  };

  const revertSelected = () => {
    const weekReportIds: string[] = [];
    selection.forEach((val) => {
      const row = gridRows.find((f) => f.id === val);
      if (row && row.submitted && row.weekReportId !== null) {
        weekReportIds.push(row.weekReportId);
      }
    });
    WeekReportApi.revertWeekReport(weekReportIds)
      .then(() => {
        // Show success notification
        showInfo("Reverted Successfully");
      })
      .catch(() => {
        // Show failure notification
        showError("Something went wrong")
      })
      .finally(() => {
        // Trigger a reload of the week reports table
        setSelectedWeek(selectedWeek.clone());
      });
  };

  const getGridColumns = (): GridColumns => {
    const cols: GridColumns = [];



    cols.push({
      field: "name",
      headerName: "Name",
      flex: 1,
      filterable: false,
    });

    cols.push({
      field: "status",
      headerName: "Status",
      flex: 1,
      sortable: true,
      type: 'singleSelect',
      valueOptions: ['Unsubmitted', 'Submitted', 'Approved']
    });

    cols.push({
      field: "workedHoursPerWeek",
      headerName: "Worked Hours",
      flex: 1,
      sortable: true,
      filterable: false,
    });

    cols.push({
      field: "travelExpenses",
      headerName: "Travel expenses",
      flex: 1,
      sortable: true,
      filterable: false,
      type: "number",
      valueFormatter: (params) => {
        const value = params.getValue(params.id, params.field);
        if (typeof value === "number") {
          return new Intl.NumberFormat("nl-NL", {
            style: "currency",
            currency: "EUR",
          }).format(value);
        }
        return null;
      },
    });



    return cols;
  };

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <Box>
          <Typography variant="h5" component="h1">
            {`STATEMENTS WEEK ${selectedWeek.isoWeek()} OF ${selectedWeek.year()}`}
          </Typography>
        </Box>
        <Box>
          <IconButton onClick={moveWeekBackwards} disabled={loadingWeekReports}>
            <ChevronLeft fontSize="large"></ChevronLeft>
          </IconButton>
          <IconButton onClick={moveWeekForwards} disabled={loadingWeekReports}>
            <ChevronRight fontSize="large"></ChevronRight>
          </IconButton>
        </Box>
      </div>
      <TotalsDataGrid
        autoHeight
        columns={gridColumns}
        rows={gridRows}
        checkboxSelection
        loading={loadingWeekReports}
        disableSelectionOnClick
        hideFooterPagination
        rowsPerPageOptions={[gridRows.length]}
        pageSize={gridRows.length}
        onSelectionModelChange={updateSelected}
        setShowRevertButton={setShowRevertButton}
      />
      <div>
        {showRevertButton !== true
          ?
          <Button
            variant="contained"
            color="primary"
            disabled={
              selection.length === 0
            }
            onClick={approveSelected}
          >
            Approve Selected
          </Button>
          :
          <Button
            variant="contained"
            color="primary"
            disabled={
              selection.length === 0
            }
            onClick={revertSelected}
          >
            Revert Selected
          </Button>
        }

      </div>
    </div>
  );
};
