import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Link,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import AdminDashboardAPI, {
  DashboardTimesheetsBlockData,
  LeaderboardStatistics,
} from "../../../data/admindashboard";
import {
  StatisticType,
  StatisticTypeNames,
} from "../../../model/enum/StatisticType";

const useStyles = makeStyles((theme: Theme) => ({
  statistics: {
    backgroundColor: "#f2f2f2",
  },
  mb: {
    marginBottom: "1em",
  },
  mr: {
    marginRight: "0.5em",
  },
}));

const adminDashboardApi = new AdminDashboardAPI();

type TimesheetsBlockDataProps = {
  data: DashboardTimesheetsBlockData | null;

  onGoToCustomers: () => void;
  onGoToProjects: () => void;
  onGoTousers: () => void;
};

const getStatisticsLeaderboardRows = (
  data: LeaderboardStatistics,
  mrclass: string
) => {
  const rows: JSX.Element[] = [];
  Object.keys(data).forEach((type) => {
    rows.push(
      <tr key={type}>
        <td>
          <Typography variant="body1" className={mrclass}>
            <b>{StatisticTypeNames.getDisplayName(type as StatisticType)}</b>
          </Typography>
        </td>
        <td>
          <Typography variant="body1">{data[type as StatisticType]}</Typography>
        </td>
      </tr>
    );
  });

  return rows;
};

const TimesheetsBlockData = ({
  data,
  onGoToCustomers,
  onGoToProjects,
  onGoTousers,
}: TimesheetsBlockDataProps) => {
  const classes = useStyles();

  if (data === null) {
    return (
      <div>
        <Typography variant="body1" className={classes.mb}>
          Unable to load data
        </Typography>
      </div>
    );
  }

  return (
    <div>
      <Typography variant="body1">
        <b>{data.customerCount}</b>{" "}
        <Link href="#" onClick={onGoToCustomers}>
          Customers
        </Link>
      </Typography>
      <Typography variant="body1">
        <b>{data.projectCount}</b>{" "}
        <Link href="#" onClick={onGoToProjects}>
          Projects
        </Link>
      </Typography>
      <Typography variant="body1" className={classes.mb}>
        <b>{data.userCount}</b>{" "}
        <Link href="#" onClick={onGoTousers}>
          Employees
        </Link>
      </Typography>

      <Typography variant="body1" className={classes.mb}>
        <b>
          {data.submittedReportCount}/{data.userCount}
        </b>{" "}
        submitted this week
      </Typography>

      <table className={classes.mb}>
        <tbody>
          {getStatisticsLeaderboardRows(data.statisticsLeaderboard, classes.mr)}
        </tbody>
      </table>
    </div>
  );
};

export const TimesheetsBlock = withRouter(
  ({ history }: RouteComponentProps) => {
    const classes = useStyles();

    const [loading, setLoading] = useState<boolean>(true);

    const [blockData, setBlockData] =
      useState<DashboardTimesheetsBlockData | null>(null);

    const week = moment().isoWeek();
    const year = moment().year();

    useEffect(() => {
      setLoading(true);

      adminDashboardApi
        .getTimesheetsBlockData(year, week)
        .then((data) => {
          setBlockData(data);
        })
        .catch((err) => {
          console.log(err);
          setBlockData(null);
          //dispatch(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }, [week, year]);

    const goToCustomers = () => history.push("/admin/customers");
    const goToProjects = () => history.push("/admin/projects");
    const goToUsers = () => history.push("/admin/users");
    const goToReportingHistory = () => history.push("/admin/weekreporthistory");
    const goToBillingTool = () => history.push("/admin/billingtool");

    return (
      <Grid item xs={12}>
        <Paper className={classes.statistics} elevation={3}>
          <Box p="2em">
            <Typography variant="h6" component="h2">
              Timesheets
            </Typography>
            <hr></hr>
            {loading ? (
              <Grid container justifyContent="center">
                <CircularProgress />
              </Grid>
            ) : (
              <TimesheetsBlockData
                data={blockData}
                onGoToCustomers={goToCustomers}
                onGoToProjects={goToProjects}
                onGoTousers={goToUsers}
              />
            )}

            {!loading ? (
              <div>
                {" "}
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={goToReportingHistory}
                >
                  Reporting history
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={goToBillingTool}
                >
                  Billing Tool
                </Button>{" "}
              </div>
            ) : (
              ``
            )}
          </Box>
        </Paper>
      </Grid>
    );
  }
);
