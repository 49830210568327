import { getAxios } from "./axios";

export interface VatTypes {
  [key: string]: string;
}

export interface DeclarationTypes {
  [key: string]: string;
}

export interface DeclarationComponent {
  amount: number;
  vatAmount: number;
  vatType: string;
  declarationType: string;
  paymentDate: string;
  yukiId: string;
}

export interface DeclarationDataItem {
  id: string;
  userId: string;
  reimburse: boolean;
  remark: string;
  parts: DeclarationComponent[];
  status: string;
  submissionDate: string;
  submissionTime?: string;
  processedDate: string;
}

export interface CreateDeclarationComponent {
  amount: string;
  vatType: string;
  declarationType: string;
}
export interface CreateDeclaration {
  reimburse: boolean;
  remark: string;
  parts: CreateDeclarationComponent[];
  base64EncodedFile: string;
}

export interface UpdateDeclarationInterface {
  declarationId: string;
  googleFileId: string;
  reimburse: boolean;
  remark: string;
  parts: CreateDeclarationComponent[];
  base64EncodedFile: string;
}

export interface ApproveDeclaration {
  declarationId: string;
}

export interface RejectDeclaration {
  declarationId: string;
}

export default class DeclarationApi {
  static async getVatTypes(): Promise<VatTypes> {
    let response = await getAxios().post<VatTypes>(`/VATTypes`);
    return response.data;
  }

  static async getDeclarationTypes(): Promise<DeclarationTypes> {
    let response = await getAxios().post<DeclarationTypes>(`/DeclarationTypes`);
    return response.data;
  }

  static async getUserDeclarations(): Promise<DeclarationDataItem[]> {
    let response = await getAxios().post<DeclarationDataItem[]>(
      `/getUserDeclarations`
    );
    return response.data;
  }

  static async getUnapprovedDeclarations(): Promise<DeclarationDataItem[]> {
    let response = await getAxios().post<DeclarationDataItem[]>(
      `/getUnapprovedDeclarations`
    );
    return response.data;
  }

  static async getProcessedDeclarations(): Promise<DeclarationDataItem[]> {
    let response = await getAxios().post<DeclarationDataItem[]>(
      `/getProcessedDeclarations`
    );
    return response.data;
  }

  static async getDeclarationById(declarationId: string): Promise<DeclarationDataItem> {
    let response = await getAxios().get<DeclarationDataItem>(
      `/declaration/${declarationId}`
    );
    return response.data;
  }

  static async getAttachmentDownloadUrl(
    declarationId: string
  ): Promise<string> {
    let response = await getAxios().get<string>(
      `/getAttachmentDownloadUrl/${declarationId}`
    );
    return response.data;
  }

  static async createDeclaration(payload: CreateDeclaration): Promise<string> {
    let response = await getAxios().post<string>(`/createDeclaration`, payload);
    return response.data;
  }

  static async updateDeclaration(
    payload: UpdateDeclarationInterface
  ): Promise<boolean> {
    let response = await getAxios().post<boolean>(
      `/updateDeclaration`,
      payload
    );
    return response.data;
  }

  static async updateStatusses(): Promise<string> {
    let response = await getAxios().post<string>(`/updateStatusses`);
    return response.data;
  }

  static async approveDeclaration(
    payload: ApproveDeclaration
  ): Promise<string> {
    let response = await getAxios().post<string>(
      `/approveDeclaration`,
      payload
    );
    return response.data;
  }

  static async rejectDeclaration(payload: RejectDeclaration): Promise<string> {
    let response = await getAxios().post<string>(`/rejectDeclaration`, payload);
    return response.data;
  }

  static async deleteDeclaration(declarationId: string): Promise<boolean> {
    let response = await getAxios().delete<boolean>(`/deleteDeclaration/${declarationId}`);
    return response.data;
  }

}
