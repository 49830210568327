import { getAxios } from "./axios";

export enum AddressType {
  HOME = "HOME",
  USER = "USER",
  PROJECT = "PROJECT",
  CUSTOMER = "CUSTOMER",
}

export interface AddressUpdate {
  googleAddressId: string | undefined;
  googleAddress: string | undefined;
  googleSessionToken: string;
}

export interface NamedAddress {
  id: string;
  name: string;
  googleAddressId: string;
  googleAddress: string;
  addressId: string;
  addressType: AddressType;
}

export interface CreateUserAddress extends AddressUpdate {
  name: string;
  addressType: AddressType;
}

export interface UpdateUserAddress extends CreateUserAddress {
  id: string;
}

export default class UserAddressApi {
  static async getUserAddresses(): Promise<NamedAddress[]> {
    let response = await getAxios().get<NamedAddress[]>(`/address/me`);
    return response.data;
  }

  static async getAllAddressesForUser(): Promise<NamedAddress[]> {
    let response = await getAxios().get<NamedAddress[]>(`/address/me/all`);
    return response.data;
  }

  static async addUserAddress(payload: CreateUserAddress): Promise<string> {
    let response = await getAxios().post<string>(`/address/me`, payload);
    return response.data;
  }

  static async updateUserAddress(payload: UpdateUserAddress): Promise<void> {
    let response = await getAxios().put<void>(`/address/me`, payload);
    return response.data;
  }

  static async removeUserAddress(id: string): Promise<boolean> {
    let response = await getAxios().delete<boolean>(`/address/me/${id}`);
    return response.data;
  }
}
