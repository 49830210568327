import React, { useEffect, useState } from "react";
import {
  createStyles,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Switch,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { Title } from "../../component/Title";
import HolidayApi, { Holiday } from "../../data/holiday";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      display: "flex",
      justifyContent: "space-between",
    },
    cell: {
      verticalAlign: "top",
    },
    spinner: {
      marginLeft: "50%",
    },
    spinnerContainer: {
      position: "relative",
    },
  })
);

interface holidayProps {
  holidays: Holiday[];
  handleToggle: (arg1: string, arg2: boolean) => void;
}

const Holidays = (props: holidayProps) => {
  const classes = useStyles();

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Enabled</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.holidays.map((holiday, index) => (
            <TableRow key={index} hover>
              <TableCell className={classes.cell}>{holiday.fullName}</TableCell>
              <TableCell className={classes.cell}>
                {holiday.dateString}
              </TableCell>
              <TableCell className={classes.cell}>
                <Switch
                  checked={holiday.vacation}
                  onChange={(state) => {
                    props.handleToggle(holiday.id, state.target.checked);
                  }}
                  color="primary"
                  name="checkedB"
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const HolidaysList = withRouter(({ history }: RouteComponentProps) => {
  const classes = useStyles();

  const [holidaysLoaded, setHolidaysLoaded] = useState<boolean>(true);

  const [holidays, setHolidays] = useState<Holiday[]>([]);

  const handleToggle = (id: string, state: boolean) => {
    HolidayApi.toggleHoliday(id, state);
  };

  const fetchHolidays = async (): Promise<Holiday[]> => {
    let response = (await HolidayApi.getHolidays()).holidays;
    return response;
  };

  useEffect(() => {
    fetchHolidays().then((data) => {
      setHolidays(data);
    });
  });

  return !holidaysLoaded ? (
    <div className={classes.spinnerContainer}>
      <CircularProgress disableShrink className={classes.spinner} />
    </div>
  ) : (
    <Grid container>
      {!holidaysLoaded ? (
        <div className={classes.spinnerContainer}>
          <CircularProgress disableShrink className={classes.spinner} />
        </div>
      ) : (
        <Grid container justifyContent="space-between">
          <Title>Holidays</Title>
          <Button
            onClick={() => {
              setHolidaysLoaded(false);
              HolidayApi.refreshHolidays().then(() => {
                setHolidaysLoaded(true);
              });
            }}
          >
            Refresh Holidays
          </Button>
        </Grid>
      )}
      <Holidays holidays={holidays} handleToggle={handleToggle} />
    </Grid>
  );
});
