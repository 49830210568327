import { getAxios } from "./axios";

interface LocationDto {
  placeId: string;
  name: string;
}

interface DistancesParam {
  originPlaceIds: string[];
  destinationPlaceIds: string[];
}

export interface Prediction {
  placeId: string;
  description: string;
}

export interface DistanceResultEntry {
  destinationId: string;
  distance: number;
}

export interface DistanceResultRow {
  originId: string;
  destinationDistances: DistanceResultEntry[];
}

export interface DistanceResult {
  distances: DistanceResultRow[];
}

export default class GoogleMapsApi {
  static async getAddressSuggestions(
    query: string,
    sessionToken: string
  ): Promise<Prediction[]> {
    let response = await getAxios().get<LocationDto[]>(`/getAddresses`, {
      params: {
        query: query,
        sessiontoken: sessionToken,
      },
    });
    return response.data.map<Prediction>((m) => {
      return {
        placeId: m.placeId,
        description: m.name,
      };
    });
  }

  static async getDistances(param: DistancesParam): Promise<DistanceResult> {
    let response = await getAxios().post<DistanceResult>(
      `/getDistances`,
      param
    );
    return response.data;
  }
}
