import {
  createStyles,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
} from "@material-ui/core";

import { WeekReportEntry } from "../../data/weekReport";
import { ProjectType } from "../../data/project";

const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      display: "flex",
      justifyContent: "space-between",
    },
    cell: {
      verticalAlign: "top",
    },
    importantCell: {
      fontWeight: "bold",
    },
    highlightContainerBlack: {
      fontSize: 14,
      background: "black",
      color: "#FFF",
      width: "16px",
      verticalAlign: "top",
      textAlign: "center",
      borderRadius: "3px",
    },
    highlightContainerWhite: {
      fontSize: 14,
      background: "white",
      color: "#000",
      width: "16px",
      verticalAlign: "top",
      textAlign: "center",
      borderRadius: "3px",
    },
  })
);

export const Timesheet = ({ entries }: { entries: WeekReportEntry[] }) => {
  const classes = useStyles();

  const totalHours = entries.reduce((sum, entry) => sum + entry.hours || 0, 0);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.importantCell}>Day</TableCell>
            <TableCell className={classes.importantCell}>Activity</TableCell>
            <TableCell className={classes.importantCell}>Hours</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {days.map((day, index) => {
            const dayEntries = entries.filter(
              (entry) => entry.weekDay === index + 1
            );
            return (
              <TableRow key={index}>
                <TableCell className={classes.cell}>{day}</TableCell>
                <TableCell className={classes.cell}>
                  {dayEntries.map((entry, index) => (
                    <div key={index}>{entry.project}</div>
                  ))}
                </TableCell>
                <TableCell>
                  {dayEntries.map((entry, index) => (
                    <div>
                      {entry.projectType !== ProjectType.REGULAR ? (
                        <div
                          key={index}
                          className={classes.highlightContainerBlack}
                        >
                          {entry.hours > 0 ? entry.hours : "-"}
                        </div>
                      ) : (
                        <div
                          key={index}
                          className={classes.highlightContainerWhite}
                        >
                          {entry.hours}
                        </div>
                      )}
                    </div>
                  ))}
                </TableCell>
              </TableRow>
            );
          })}
          <TableRow>
            <TableCell className={classes.importantCell}>Total hours</TableCell>
            <TableCell></TableCell>
            <TableCell>{totalHours}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
