import { WeekReport, Status, BusinessTrip } from "../../../data/weekReport";

export const initialState = (report: WeekReport) => ({
  report,
  autoSave: false,
});

interface State {
  report: WeekReport;
  autoSave: boolean;
}

export type Action =
  | { type: "entryUpdated"; projectId: string; weekDay: number; hours: number }
  | { type: "entriesCleared" }
  | { type: "tripAdded"; trip: BusinessTrip }
  | { type: "tripUpdated"; index: number; trip: BusinessTrip }
  | { type: "tripRemoved"; index: number }
  | { type: "submitted" }
  | { type: "withdrawn" };

const isDraft = (state: State): boolean => state.report.status === Status.Draft;

const isSubmitted = (state: State): boolean =>
  state.report.status === Status.Submitted;

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "entryUpdated":
      if (isDraft(state)) {
        const entries = (state.report.entries || [])
          .filter(
            (entry) =>
              entry.projectId !== action.projectId ||
              entry.weekDay !== action.weekDay
          )
          .concat(
            [
              {
                projectId: action.projectId,
                weekDay: action.weekDay,
                hours: action.hours,
              },
            ].filter((entry) => entry.hours !== 0)
          ); // Before appending the new entries, filter out the ones with zero hours
        return {
          report: { ...state.report, entries },
          autoSave: true,
        };
      } else {
        return state;
      }
    case "entriesCleared":
      if (isDraft(state)) {
        return {
          report: { ...state.report, entries: [] },
          autoSave: true,
        };
      }
      return state;
    case "tripAdded":
      if (isDraft(state)) {
        return {
          report: {
            ...state.report,
            trips: (state.report.trips || []).concat([action.trip]),
          },
          autoSave: true,
        };
      }
      return state;
    case "tripUpdated":
      if (isDraft(state)) {
        const trips = state.report.trips;
        return {
          report: {
            ...state.report,
            trips: (trips || [])
              .slice(0, action.index)
              .concat(action.trip)
              .concat((trips || []).slice(action.index + 1)),
          },
          autoSave: true,
        };
      }
      return state;
    case "tripRemoved":
      if (isDraft(state)) {
        const trips = state.report.trips;
        return {
          report: {
            ...state.report,
            trips: (trips || [])
              .slice(0, action.index)
              .concat((trips || []).slice(action.index + 1)),
          },
          autoSave: true,
        };
      }
      return state;
    case "submitted":
      if (isDraft(state)) {
        return {
          report: { ...state.report, status: Status.Submitted },
          autoSave: false,
        };
      }
      return state;
    case "withdrawn":
      if (isSubmitted(state)) {
        return {
          report: { ...state.report, status: Status.Draft },
          autoSave: true,
        };
      } else {
        return state;
      }
  }
};
