import {autorun, set, toJS} from 'mobx';

export const autoSave = (obj: { [key: string]: any }, key: string, nameEnding: string): void => {
    const name: string = "autoSave-" + nameEnding;

    const storedJson: string | null = localStorage.getItem(name);

    if (storedJson) {
        if (storedJson === "undefined") {
            set(obj, key, undefined);
        } else {
            set(obj, key, JSON.parse(storedJson));
        }
    }

    autorun(() => {
        const value = toJS(obj[key]);
        localStorage.setItem(name, JSON.stringify(value));
    })
}
