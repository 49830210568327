import React from "react";
import { KeyboardDatePicker } from "@material-ui/pickers";

interface DatePickerProps {
  label: string;
  value: Date | null;
  onChange: (date: Date | null) => void;
  onBlur?: (
    event?: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  error?: string;
}

export const DatePicker = (props: DatePickerProps) => (
  <KeyboardDatePicker
    fullWidth
    margin="normal"
    inputVariant="outlined"
    label={props.label}
    onBlur={props.onBlur}
    error={!!props.error}
    helperText={props.error}
    format="dd-MM-yyyy"
    value={props.value || null}
    placeholder="dd-mm-yyyy"
    onChange={(date) => props.onChange(date)}
    onAccept={(date) => props.onChange(date)}
  />
);
