import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";

interface CustomDialogProps {
  open: boolean;
  title: string;
  description: string;
  onClose: () => void;
  onAgree: () => void;
  onDisAgree: () => void;
}

export const CustomPopup = (props: CustomDialogProps) => {
  const { onClose, open, title, description, onAgree, onDisAgree } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      aria-describedby="simple-dialog-description"
    >
      <DialogTitle id="simple-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="simple-dialog-description">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose();
            onDisAgree();
          }}
        >
          Disagree
        </Button>
        <Button
          onClick={() => {
            onClose();
            onAgree();
          }}
          color="primary"
          autoFocus
        >
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
};
