import React, { useRef } from "react";
import { Box as MuiBox, BoxProps } from "@material-ui/core";

type CBoxProps = {
  expand?: boolean;
  overlay?: boolean;
  center?: boolean;
  row?: boolean;
  column?: boolean;
  horizontal?: boolean;
  width?: string | number;
  height?: string | number;
  shadow?: boolean;
  round?: boolean;
  forwardedRef?: React.Ref<any>;
  style?: React.CSSProperties;
} & BoxProps;

const Box: React.ForwardRefRenderFunction<HTMLDivElement, CBoxProps> = (
  props,
  ref
) => {
  let {
    expand,
    overlay,
    center,
    row,
    column,
    horizontal,
    width,
    height,
    shadow,
    round,
    forwardedRef,
    style = {},
    ...rest
  } = props;

  style = { ...style };

  if (row) {
    style["display"] = "flex";
  }

  if (column) {
    style["display"] = "flex";
    style["flexDirection"] = "column";
  }

  if (expand || overlay) {
    width = "100%";
    height = "100%";
  }

  if (overlay) {
    style["pointerEvents"] = "none";
    style["position"] = "fixed";
    style["zIndex"] = 1;
    style["top"] = 0;
    style["left"] = 0;
  }

  if (center) {
    style["display"] = "flex";
    style["flexDirection"] = horizontal || row ? "row" : "column";
    style["justifyContent"] = "center";
    style["alignItems"] = "center";
  }

  if (shadow) {
    style["boxShadow"] =
      "0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 3px 1px -2px rgba(0, 0, 0, 0.24), 0 1px 5px 0 rgba(0, 0, 0, 0.5)";
  }

  if (round) {
    style["borderRadius"] = "4px";
  }
  const myBoxRef = useRef<HTMLDivElement>(null);

  return (
    <MuiBox
      // @ts-ignore
      ref={ref || forwardedRef}
      // ref={myBoxRef}
      width={width}
      height={height}
      style={style}
      {...rest}
    />
  );
};

export default React.forwardRef(Box);
